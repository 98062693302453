.mr-small {
  margin-right: 20px;
}
.mb-small {
  margin-bottom: 20px;
}
.prodtech-results-list-item {
  padding: 20px;
  border: 1px solid #dddddd;
}
.prodtech-results-list-item__body {
    display: flex;
    flex-direction: column;
}
@media (min-width: 450px) {
.prodtech-results-list-item__body {
        flex-direction: row;
}
}
.prodtech-results-list-item__body--image {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
}
@media (min-width: 450px) {
.prodtech-results-list-item__body--image {
          margin-right: 20px;
}
}
.prodtech-results-list-item__body--details {
      flex: 1;
}
.prodtech-results-list-item__body--details table {
        font-size: 1.4rem;
        color: #555;
        margin-bottom: 20px;
}
@media (min-width: 650px) {
.prodtech-results-list-item__body--details table {
            margin-bottom: 0;
}
}
.prodtech-results-list-item__body--details tr {
        display: flex;
        flex-direction: column;
}
.prodtech-results-list-item__body--details tr td {
          padding-left: 20px;
}
@media (min-width: 650px) {
.prodtech-results-list-item__body--details tr {
            display: table-row;
}
.prodtech-results-list-item__body--details tr td {
              padding-left: 0;
}
}
.prodtech-results-list-item__body--details th {
        width: 155px;
        padding-right: 20px;
}
.prodtech-results-list-item__body--details th,
      .prodtech-results-list-item__body--details td {
        vertical-align: top;
}
.prodtech-results-list-item__mba--desktop, .prodtech-results-list-item__pbar--desktop {
    display: none;
}
@media (min-width: 992px) {
.prodtech-results-list-item__mba--desktop, .prodtech-results-list-item__pbar--desktop {
        display: block;
}
}
@media (min-width: 992px) {
.prodtech-results-list-item__mba--tablet, .prodtech-results-list-item__pbar--tablet {
      display: none !important;
}
}
