/*
* Template Name: Unify - Responsive Bootstrap Template
* Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
* Version: 1.9
* Author: @htmlstream
* Website: http://htmlstream.com
*/
/*[-----*** TABLE OF CONTENT ***-----]

1- Promo Blocks
2- Breadcrumbs
2.1- Breadcrumbs v1
2.2- Breadcrumbs v2
2.3- Breadcrumbs v3
3- Image Hover Effects
4- Block Grid
4.1-Block Grid v1
4.2-Block Grid v2
5- Services Box v1
6- Service Block
6.1- Service Block v1
6.2- Service Block v2
6.3- Service Block v3
6.4- Service Block v4
6.5- Service Block v5
6.6- Service Block v6
6.7- Service Block v7
6.8- Service Block v8
7- Process
8- Team
8.1- Team v1
8.2- Team v2
8.3- Team v3
8.4- Team v4
8.5- Team v5
8.6- Team v6
8.7- Team v7
9- Call To Action
9.1- Call To Action v1
9.2- Call To Action v2
10- Portfolio Box
10.1- Portfolio Box v1
11- Portfolio Feature v2
12- Thumbnail
13- Image Backgrounds
13.1- Image Backgrounds v1
13.2- Image Backgrounds v2
14- Parallax Quote
15- Parallax Team
16- Parallax Counter
16.1- Parallax Counter v1
16.2- Parallax Counter v2
16.3- Parallax Counter v3
16.4- Parallax Counter v4
17- Flat Background Block
18- Flat Testimonials
19- Testimonials
19.1- Testimonials v4
19.2- Testimonials v5
19.3- Testimonials v6
20- Featured Blog
21- Blog Comments
22- Quote
22.1- Quote v2
23- Team Social
24- Box Shadows
25- Interactive Slider
25.1- Interactive Slider v1
25.2- Interactive Slider v2
26- Blog Trending
27- Blog Latest Posts
28- Blog Photostream
29- Newsletter
30- Blog Post Quote
31- Blog Post Author
32- Blog Comments
33- News
33.1- News v1
33.2- News v2
33.3- News v3
34- Blog Grid
35- Blog Thumb
36- Shop-subscribe
-------------------------------------------------------------*/
/*--------------------------------------------------
[2. Breadcrumbs]
----------------------------------------------------*/
.breadcrumb {
  top: 10px;
  padding-right: 0;
  background: none;
  position: relative; }

.breadcrumb a {
  color: #777; }

.breadcrumb li.active,
.breadcrumb li a:hover {
  color: #72c02c;
  text-decoration: none; }

/*Breadcrumbs v3
------------------------------------*/
.breadcrumbs-v3 {
  z-index: 1;
  padding: 100px 0;
  position: relative; }

.breadcrumbs-v3:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.2); }

.breadcrumbs-v3 h1 {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase; }

.breadcrumbs-v3 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 0;
  text-transform: uppercase; }

@media (max-width: 992px) {
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 100px 0; } }

@media (max-width: 768px) {
  .breadcrumbs-v3,
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 50px 0; }
  .breadcrumbs-v3 h1 {
    font-size: 30px;
    line-height: 40px; }
  .breadcrumbs-v3 p {
    font-size: 18px; } }

/*--------------------------------------------------
[3. Image Hover Effects]
----------------------------------------------------*/
/*Image Hover v1*/
.img-hover-v1 span {
  display: block;
  overflow: hidden;
  position: relative; }

.img-hover-v1 span:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute; }

.img-hover-v1:hover span:after {
  background: rgba(0, 0, 0, 0.3); }

.img-hover-v1 img,
.img-hover-v2 img,
.img-hover-v1 span:after {
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out; }

.img-hover-v1:hover img {
  -transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1); }

/*Image Hover v2*/
.img-hover-v2 img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%); }

.img-hover-v2:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0); }

/*--------------------------------------------------
[4. Block Grid]
----------------------------------------------------*/
/*Block Grid v1
------------------------------------*/
.block-grid-v1 {
  background: #fff;
  padding: 15px 15px 5px;
  border-bottom: solid 2px #eee;
  transition: all 0.4s ease-in-out; }

.block-grid-v1:hover {
  border-color: #72c02c; }

.block-grid-v1 img {
  margin-bottom: 10px; }

.block-grid-v1 img.block-grid-v1-img {
  width: 35px;
  height: 35px;
  margin-top: 8px; }

.block-grid-v1.content-boxes-v1 img.pull-left {
  float: inherit !important; }

.block-grid-v1 h3 a {
  font-size: 22px;
  font-weight: 200;
  margin-right: 10px; }

.block-grid-v1.content-boxes-v1 h3 {
  margin-bottom: 0; }

.block-grid-v1.content-boxes-v1 h3 a {
  color: #555; }

/*Star Vote*/
.block-grid-v1 .star-vote {
  padding: 0;
  margin-bottom: 10px;
  vertical-align: middle; }

.block-grid-v1 .star-vote li {
  padding: 0;
  font-size: 11px;
  display: inline-block; }

/*Additional Info*/
.block-grid-v1-add-info {
  margin: 0;
  padding-top: 15px;
  border-top: solid 1px #eee; }

.block-grid-v1-add-info li {
  padding: 0;
  margin-right: 3px;
  margin-bottom: 10px; }

.block-grid-v1-add-info li a {
  font-size: 11px;
  padding: 4px 7px;
  text-decoration: none;
  border: solid 2px #eee;
  border-radius: 3px !important; }

/*Block Grid v2
------------------------------------*/
.block-grid-v2 {
  padding: 0; }

.block-grid-v2 li {
  list-style: none;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/ }

.block-grid-v2-info {
  padding: 15px;
  text-align: center;
  background: #f9f9f9;
  border-bottom: solid 3px #eee;
  transition: all 0.4s ease-in-out; }

.block-grid-v2 li:hover .block-grid-v2-info {
  border-color: #72c02c; }

.block-grid-v2-info h3 {
  font-size: 22px;
  font-weight: 200;
  margin-bottom: 10px; }

.block-grid-v2-info .star-vote li {
  padding: 2px;
  font-size: 16px; }

/*--------------------------------------------------
[5. Services Box]
----------------------------------------------------*/
/*Services Box v1
------------------------------------*/
.service-box-v1 .service-block {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 20px; }

.service-box-v1 p {
  position: relative;
  margin-bottom: 50px; }

.service-box-v1 p:after {
  content: " ";
  left: 50%;
  height: 1px;
  width: 50px;
  bottom: -15px;
  position: absolute;
  margin-left: -25px;
  background: #72c02c; }

.service-box-v1 ul {
  padding: 0 40px; }

.service-box-v1 li {
  margin: 8px 0;
  font-size: 15px;
  padding-top: 8px;
  border-top: dotted 1px #72c02c; }

.service-box-v1 li:hover {
  color: #72c02c; }

.service-box-v1 li:first-child {
  padding-top: 0;
  border-top: none; }

/*--------------------------------------------------
[6. Service Block ]
----------------------------------------------------*/
/*Service Block v1
------------------------------------*/
.service-block-v1 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  padding: 45px 20px 20px;
  border: 1px solid #f2f2f2; }

.service-block-v1 i {
  left: 50%;
  top: -30px;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 26px;
  line-height: 62px;
  margin-left: -30px;
  text-align: center;
  position: absolute;
  background: #72c02c;
  display: inline-block; }

.service-block-v1 h3,
.service-block-v1 p {
  margin-bottom: 20px; }

/*Service Block v2 (used in page_404_error2.html)
------------------------------------*/
.service-block-v2 .service-block-in {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.2); }

.service-block-v2 .service-block-in i {
  font-size: 40px; }

.service-block-v2 .service-block-in h4 {
  line-height: 25px; }

.service-block-v2 .service-block-in p {
  margin-bottom: 20px; }

.service-block-v2 .service-block-in i,
.service-block-v2 .service-block-in h4,
.service-block-v2 .service-block-in p {
  color: #fff; }

/*Service Block v3 (used in profile pages)
------------------------------------*/
.service-block-v3 {
  padding: 20px; }

.service-block-v3 i {
  color: #fff;
  float: left;
  font-size: 50px;
  margin: 0 20px 20px 0; }

.service-block-v3 .service-heading,
.service-block-v3 .service-in small {
  color: #fff;
  opacity: 0.8;
  line-height: 1; }

.service-block-v3 .service-in h4,
.service-block-v3 .counter {
  color: #fff; }

.service-block-v3 .service-heading {
  font-size: 16px;
  text-transform: uppercase; }

.service-block-v3 .counter {
  display: block;
  line-height: 1;
  font-size: 30px; }

.service-block-v3 .progress {
  margin-bottom: 7px; }

/*Service Innner*/
.service-block-v3 .service-in small {
  font-size: 16px;
  text-transform: uppercase; }

.service-block-v3 .service-in h4 {
  font-size: 16px;
  line-height: 0.8;
  margin-bottom: 0; }

/*Statistics*/
.service-block-v3 .statistics .heading-xs {
  color: #fff;
  opacity: 0.8; }

.service-block-v3 .statistics small {
  color: #fff; }

.service-block-v3 .statistics .progress {
  background: #bbb; }

.service-block-v3 .statistics .progress-bar-light {
  background: #fff; }

/*Service Block v4
------------------------------------*/
.service-block-v4 {
  position: relative;
  background: #f7f7f7; }

.service-block-v4:after {
  top: 0;
  width: 0;
  height: 0;
  left: 50%;
  content: '';
  margin-left: -20px;
  position: absolute;
  border-style: solid;
  border-width: 25px 20px 0 20px;
  border-color: #fff transparent transparent transparent; }

.service-block-v4 .service-desc {
  text-align: center; }

.service-block-v4 .service-desc i {
  color: #72c02c;
  display: block;
  font-size: 30px;
  margin-bottom: 20px; }

.service-block-v4 .service-desc h3 {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase; }

/*Service Block v5
------------------------------------*/
.service-block-v5 {
  text-align: center;
  background: #f7f7f7; }

.service-block-v5 .service-inner {
  padding: 80px 40px; }

.service-block-v5 .service-inner i {
  margin-bottom: 30px; }

.service-block-v5 .service-inner span {
  display: block;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 15px;
  text-transform: uppercase; }

.service-block-v5 .service-border {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee; }

@media (max-width: 992px) {
  .service-block-v5 .service-border {
    border-left: none;
    border-right: none;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee; } }

/*Service Block v6
------------------------------------*/
.service-block-v6 i {
  float: left;
  margin: 5px 15px 0 0; }

.service-block-v6 .service-desc {
  overflow: hidden; }

.service-block-v6 .service-desc h2 {
  font-size: 18px;
  margin: 0 0 10px;
  text-transform: uppercase; }

.service-block-v6 .service-desc a {
  text-transform: uppercase; }

/*Service Block v7
------------------------------------*/
.service-block-v7 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  padding: 45px 20px 20px; }

.service-block-v7 i {
  left: 50%;
  top: -25px;
  color: #fff;
  width: 45px;
  height: 45px;
  font-size: 22px;
  line-height: 46px;
  text-align: center;
  position: absolute;
  background: #72c02c;
  margin-left: -22.5px;
  display: inline-block; }

.service-block-v7 h3,
.service-block-v7 p {
  margin-bottom: 20px; }

/*Service Block v8
------------------------------------*/
.service-block-v8 {
  padding: 25px;
  background: #fff; }

.service-block-v8 i {
  color: #555;
  float: left;
  font-size: 22px;
  margin: 5px 25px 0 0; }

.service-block-v8 .service-block-desc {
  overflow: hidden; }

.service-block-v8 .service-block-desc h3 {
  font-size: 18px;
  margin: 0 0 30px;
  position: relative;
  text-transform: uppercase; }

.service-block-v8 .service-block-desc h3:after {
  left: 0;
  top: 40px;
  height: 1px;
  width: 30px;
  content: " ";
  position: absolute;
  background: #72c02c; }

/*--------------------------------------------------
[7. Process]
----------------------------------------------------*/
/*Process v1
------------------------------------*/
.process-v1 .process-in {
  text-align: center; }

.process-v1 .process-in > li i {
  z-index: 1;
  width: 30px;
  height: 30px;
  position: relative;
  text-align: center;
  background: #72c02c;
  margin-bottom: 15px;
  display: inline-block;
  border: solid 9px #fff; }

.process-v1 .process-in > li i:after {
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  content: " ";
  border-radius: 50%;
  position: absolute;
  border: 1px solid #bbb; }

/*Process Description*/
.process-v1 .process-in .process-desc {
  padding: 0 20px; }

.process-v1 .process-in > li h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
  text-transform: uppercase; }

/*Process Border*/
.process-v1 .process-border {
  position: relative; }

.process-v1 .process-border:before {
  top: 15px;
  width: 75%;
  left: 12%;
  content: " ";
  display: block;
  position: absolute;
  border-top: solid 2px #ddd; }

@media (max-width: 768px) {
  .process-v1 .process-border:before {
    border: none; } }

/*--------------------------------------------------
[8. Team]
----------------------------------------------------*/
/*Team v1
------------------------------------*/
.team-v1 ul {
  position: relative; }

/*Team Image*/
.team-v1 li > .team-img {
  position: relative; }

.team-v1 li > .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: transparent;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out; }

.team-v1 li:hover > .team-img:after {
  background: rgba(0, 0, 0, 0.5); }

/*Team Socials*/
.team-v1 li > .team-img ul {
  left: 0;
  top: 45%;
  z-index: 1;
  padding: 0;
  width: 100%;
  height: 36px;
  list-style: none;
  margin-top: -18px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

.team-v1 li:hover > .team-img ul {
  top: 50%;
  visibility: visible; }

.team-v1 li > .team-img ul li {
  margin: 0 5px;
  text-align: center;
  display: inline-block; }

.team-v1 li > .team-img li i {
  color: #eee;
  border-width: 2px;
  border-color: #eee;
  transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out; }

.team-v1 li > .team-img li i.fa-twitter:hover {
  color: #fff;
  background: #3498db;
  border-color: #3498db; }

.team-v1 li > .team-img li i.fa-facebook:hover {
  color: #fff;
  background: #4765a0;
  border-color: #4765a0; }

.team-v1 li > .team-img li i.fa-google-plus:hover {
  color: #fff;
  background: #e74c3c;
  border-color: #e74c3c; }

/*Team Discription*/
.team-v1 li > h3 {
  color: #000;
  font-size: 18px;
  line-height: 20px;
  margin: 15px 0 7px;
  text-transform: uppercase; }

.team-v1 li > h4 {
  color: #999;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase; }

.team-v1 li > p {
  margin: 20px 0;
  position: relative; }

.team-v1 li > p:before {
  left: 0;
  top: -10px;
  height: 1px;
  width: 40px;
  content: " ";
  position: absolute;
  background: #bbb;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out; }

.team-v1 li:hover > p:before {
  width: 100%;
  background: #72c02c; }

/*Team v2
------------------------------------*/
.team-v2 {
  position: relative;
  margin-bottom: 20px; }

.team-v2 img {
  width: 100%; }

.team-v2 .inner-team {
  padding: 20px;
  background: #fff;
  text-align: center; }

.team-v2 .inner-team h3 {
  margin: 0; }

.team-v2 .inner-team small {
  display: block;
  font-size: 12px;
  margin-bottom: 7px; }

.team-v2 .inner-team p {
  font-size: 13px; }

.team-v2 .inner-team hr {
  margin: 10px 0 15px; }

/*Team-Social (Temporary)*/
.team-social {
  margin-bottom: 0; }

.team-social li {
  padding: 0 2px; }

.team-social li a {
  font-size: 14px;
  padding: 6px 4px;
  text-align: center;
  border-radius: 50% !important; }

.team-social li i {
  min-width: 20px; }

.team-social li a.fb {
  color: #4862a3;
  border: 1px solid #4862a3; }

.team-social li a.tw {
  color: #159ceb;
  border: 1px solid #159ceb; }

.team-social li a.gp {
  color: #dc4a38;
  border: 1px solid #dc4a38; }

.team-social li a:hover {
  text-decoration: none; }

.team-social li a.fb:hover {
  color: #fff;
  background: #4862a3; }

.team-social li a.tw:hover {
  color: #fff;
  background: #159ceb; }

.team-social li a.gp:hover {
  color: #fff;
  background: #dc4a38; }

/*Team v3
------------------------------------*/
.team-v3 .team-img {
  position: relative; }

.team-v3 .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out; }

.team-v3 .team-img:hover:after {
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

.team-v3 .team-img > img {
  width: 100%; }

/*Team Hover Gradient*/
.team-v3 .team-hover {
  left: 0;
  top: 60%;
  z-index: 1;
  padding: 0;
  width: 100%;
  padding: 20px;
  list-style: none;
  margin-top: -90px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

.team-v3 .team-img:hover .team-hover {
  top: 50%;
  visibility: visible; }

.team-v3 .team-hover span,
.team-v3 .team-hover small {
  color: #fff;
  display: block; }

.team-v3 .team-hover span {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase; }

.team-v3 .team-hover small {
  font-size: 13px;
  font-weight: 200;
  margin-bottom: 10px; }

.team-v3 .team-hover p {
  color: #fff;
  font-weight: 200;
  margin-bottom: 20px; }

/*Team Social*/
.team-v3 .team-hover .team-social-v3 i {
  color: #fff;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 1px solid #fff; }

.team-v3 .team-hover .team-social-v3 i:hover {
  color: #666;
  background: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

/*Team v4
------------------------------------*/
.team-v4 {
  text-align: center; }

.team-v4 img {
  width: 100%;
  margin-bottom: 20px; }

.team-v4 span {
  color: #333;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase; }

.team-v4 small {
  color: #999;
  display: block;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 10px; }

.team-v4 .team-social-v4 a {
  color: #555;
  font-size: 16px; }

.team-v4 .team-social-v4 a:hover {
  color: #72c02c;
  text-decoration: none; }

/*Team v5
------------------------------------*/
.team-v5 {
  text-align: center; }

.team-v5 span {
  color: #555;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase; }

.team-v5 small {
  display: block;
  color: #72c02c;
  font-size: 13px;
  font-style: italic;
  position: relative;
  margin-bottom: 25px; }

.team-v5 small:after {
  left: 50%;
  top: 30px;
  height: 1px;
  width: 30px;
  content: " ";
  background: #777;
  margin-left: -15px;
  position: absolute; }

.team-v5 .team-img {
  position: relative; }

.team-v5 .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out; }

.team-v5 .team-img:hover:after {
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

.team-v5 .team-img > img {
  width: 100%;
  margin-bottom: 20px; }

/*Team Hover Gradient*/
.team-v5 .team-hover {
  left: 0;
  top: 60%;
  z-index: 1;
  padding: 0;
  width: 100%;
  list-style: none;
  margin-top: -16px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

.team-v5 .team-img:hover .team-hover {
  top: 50%;
  visibility: visible; }

/*Social Network-*/
.team-v5 .team-hover .team-social-v5 i {
  color: #fff;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 1px solid #fff; }

.team-v5 .team-hover .team-social-v5 i:hover {
  color: #666;
  background: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

/*Team v6
------------------------------------*/
.team-v6 {
  text-align: center; }

.team-v6 img {
  width: 100%;
  margin-bottom: 20px; }

.team-v6 span {
  color: #777;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase; }

.team-v6 small {
  display: block;
  color: #72c02c;
  font-size: 13px;
  margin-bottom: 15px; }

/*Team v7
------------------------------------*/
.team-v7 .team-v7-in {
  padding: 0 50px; }

.team-v7 .team-v7-name {
  color: #555;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase; }

.team-v7 .team-v7-position {
  display: block;
  color: #72c02c;
  font-style: normal;
  margin-bottom: 15px; }

.team-v7 p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px; }

/*Team Space*/
.team-v7 .team-v7-name {
  margin-top: 60px; }

.team-v7 .social-icons-v1 {
  margin-bottom: 60px; }

/*Team Image*/
.team-v7 .team-v7-img {
  position: relative; }

.team-v7 .team-v7-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.1); }

/*Team Info*/
.team-v7 .team-arrow-right,
.team-v7 .team-arrow-left {
  position: relative; }

.team-v7 .team-arrow-left:before,
.team-v7 .team-arrow-right:before {
  width: 0;
  height: 0;
  top: 20%;
  z-index: 2;
  content: " ";
  position: absolute;
  border-style: solid; }

.team-v7 .team-arrow-right:before {
  right: -25px;
  border-width: 20px 0 20px 25px;
  border-color: transparent transparent transparent #fff; }

.team-v7 .team-arrow-left:before {
  left: -25px;
  border-width: 20px 25px 20px 0;
  border-color: transparent #fff transparent transparent; }

/*Team Info Media Queries*/
@media (max-width: 991px) {
  /*Team Arrows*/
  .team-v7 .team-arrow-left:before,
  .team-v7 .team-arrow-right:before {
    top: auto;
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px;
    border-color: #fff transparent transparent transparent; }
  .team-v7 .team-arrow-left:before,
  .team-v7 .team-arrow-right:before {
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px; }
  /*Equal Height Columns*/
  .team-v7 .equal-height-column {
    height: auto !important; } }

/*--------------------------------------------------
[9. Call To Action]
----------------------------------------------------*/
/*Call To Action v1
------------------------------------*/
.call-action-v1 p {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 0; }

.call-action-v1.call-action-v1-boxed {
  padding: 0 25px; }

.call-action-v1 .call-action-v1-box {
  width: 100%;
  display: table;
  padding: 30px 0;
  vertical-align: middle; }

.call-action-v1 .call-action-v1-in {
  display: table-cell;
  vertical-align: middle; }

.call-action-v1 .call-action-v1-in.inner-btn {
  padding-top: 1px;
  min-width: 250px;
  text-align: right; }

@media (max-width: 992px) {
  .call-action-v1 .call-action-v1-in,
  .call-action-v1 .call-action-v1-in.inner-btn {
    display: block;
    text-align: center; }
  .call-action-v1 .call-action-v1-in p {
    margin-bottom: 20px; } }

/*--------------------------------------------------
[10. Portfolio Box]
----------------------------------------------------*/
/*Portfolio Box*/
@media (min-width: 991px) and (max-width: 1199px) {
  .portfolio-box {
    margin-bottom: 60px; } }

@media (min-width: 768px) and (max-width: 992px) {
  .portfolio-box {
    margin-bottom: 0; } }

@media (max-width: 768px) {
  .portfolio-box {
    margin-bottom: 60px; } }

.portfolio-box a.fancybox {
  background: #fff;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/ }

.portfolio-box a.fancybox:after {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute; }

.portfolio-box a.fancybox:hover:after {
  background: rgba(0, 0, 0, 0.5); }

.portfolio-box a.fancybox img {
  width: 100%;
  overflow: hidden;
  transition: all 500ms ease 0s; }

.portfolio-box a.fancybox:hover img {
  transform: scale(1.1) rotate(0.1deg); }

.portfolio-box .portfolio-box-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  min-height: 50px;
  margin-top: -25px;
  visibility: hidden;
  text-align: center;
  position: absolute; }

.portfolio-box a.fancybox:hover .portfolio-box-in {
  visibility: visible; }

.portfolio-box a.fancybox:after,
.portfolio-box a.fancybox:hover:after,
.portfolio-box a.fancybox:hover .portfolio-box-in i {
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.portfolio-box .portfolio-box-in i {
  color: #fff;
  width: 50px;
  height: 50px;
  padding: 16px;
  font-size: 20px;
  text-align: center;
  background: #72c02c;
  display: inline-block; }

/*Project Tag*/
.portfolio-box small.project-tag {
  display: block;
  font-size: 12px;
  margin-bottom: 5px; }

.portfolio-box small.project-tag,
.portfolio-box small.project-tag a {
  color: #999; }

.portfolio-box small.project-tag i {
  margin-right: 5px; }

.portfolio-box small.project-tag a:hover {
  color: #72c02c; }

/*Portfolio Box v1
------------------------------------*/
.portfolio-box-v1 {
  margin: 0; }

.portfolio-box-v1 [class^="col-"] {
  padding: 0;
  overflow: hidden; }

.portfolio-box-v1 li {
  background: #333;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/ }

.portfolio-box-v1 li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute; }

.portfolio-box-v1 li:hover:after {
  background: rgba(0, 0, 0, 0.7); }

.portfolio-box-v1 li:after,
.portfolio-box-v1 li:hover:after {
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.portfolio-box-v1 li img {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.portfolio-box-v1 li:hover img {
  -transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1); }

.portfolio-box-v1 .portfolio-box-v1-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  min-height: 150px;
  margin-top: -75px;
  text-align: center;
  position: absolute;
  font-family: "Open Sans", Arial, sans-serif; }

.portfolio-box-v1 li:hover .portfolio-box-v1-in {
  visibility: visible; }

.portfolio-box-v1 .portfolio-box-v1-in h3 {
  font-size: 22px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 20px;
  text-transform: uppercase; }

.portfolio-box-v1 .portfolio-box-v1-in h3:after {
  left: 50%;
  height: 1px;
  width: 40px;
  content: " ";
  bottom: -10px;
  background: #fff;
  margin-left: -20px;
  position: absolute; }

.portfolio-box-v1 .portfolio-box-v1-in p,
.portfolio-box-v1 .portfolio-box-v1-in h3 {
  color: #fff; }

.portfolio-box-v1 .portfolio-box-v1-in p {
  font-size: 16px;
  margin-bottom: 20px; }

.portfolio-box-v1 .portfolio-box-v1-in a.btn-u {
  font-weight: normal;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/ }

/*--------------------------------------------------
[11. Portfolio Feature v2]
----------------------------------------------------*/
.portfolio-box-v2 {
  margin: 0 0 60px; }

.portfolio-box-v2 [class^="col-"] {
  padding: 0;
  overflow: hidden; }

.portfolio-box-v2 li {
  background: #333;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/ }

.portfolio-box-v2 li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute; }

.portfolio-box-v2 li:hover:after {
  background: rgba(255, 255, 255, 0.6); }

.portfolio-box-v2 li:after,
.portfolio-box-v2 li:hover:after {
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.portfolio-box-v2 li img {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.portfolio-box-v2 li:hover img {
  -transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1); }

.portfolio-box-v2 .portfolio-box-v2-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  min-height: 60px;
  margin-top: -30px;
  text-align: center;
  position: absolute;
  font-family: "Open Sans", Arial, sans-serif; }

.portfolio-box-v2 li:hover .portfolio-box-v2-in {
  visibility: visible; }

.portfolio-box-v2 .portfolio-box-v2-in i {
  color: #fff;
  width: 60px;
  height: 60px;
  padding: 20px;
  font-size: 22px;
  cursor: pointer;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
  background: rgba(114, 192, 44, 0.8); }

.portfolio-box-v2 .portfolio-box-v2-in i:hover {
  background: #72c02c; }

/*--------------------------------------------------
[12. Thumbnail]
----------------------------------------------------*/
/*Thumbnail v1
------------------------------------*/
.thumbnails-v1 .thumbnail-img {
  margin-bottom: 12px; }

.thumbnails-v1 h3 {
  margin-bottom: 5px; }

.thumbnails-v1 h3 a {
  color: #555;
  font-size: 15px;
  text-transform: uppercase; }

.thumbnails-v1 .read-more {
  color: #72c02c;
  font-size: 12px;
  text-transform: uppercase; }

/*--------------------------------------------------
[22. Quote]
----------------------------------------------------*/
.quote-v1 {
  padding: 60px 0;
  text-align: center; }

.quote-v1 p {
  color: #fff;
  font-size: 22px;
  font-weight: 200;
  padding: 0 100px;
  position: relative;
  margin-bottom: 20px; }

.quote-v1 p:before {
  color: #72c02c;
  font-size: 60px;
  content: "\201C";
  position: absolute;
  margin: -30px 0 0 -40px;
  font-family: Georgia, serif; }

.quote-v1 span {
  color: #fff;
  font-size: 18px; }

@media (max-width: 768px) {
  .quote-v1 p {
    font-size: 18px;
    padding: 0 50px; } }

/*Quote v2
------------------------------------*/
.quote-v2 {
  text-align: center;
  padding: 85px 10% 40px; }

.quote-v2 p {
  color: #555;
  font-size: 16px;
  font-style: italic;
  position: relative;
  margin-bottom: 20px; }

.quote-v2 p:before {
  left: 50%;
  top: -75px;
  font-size: 80px;
  content: "\201C";
  position: absolute;
  margin-left: -25px;
  font-family: Georgia, serif; }

.quote-v2 span {
  color: #555;
  font-weight: 400; }

/*--------------------------------------------------
[24. Box Shadows]
----------------------------------------------------*/
.shadow-wrapper {
  z-index: 1;
  position: relative; }

/*Common Style*/
.box-shadow {
  background: #fff;
  position: relative; }

.box-shadow:after,
.box-shadow:before {
  top: 80%;
  left: 5px;
  width: 50%;
  z-index: -1;
  content: "";
  bottom: 15px;
  max-width: 300px;
  background: #999;
  position: absolute; }

/*Effect 1*/
.shadow-effect-1 {
  box-shadow: 0 10px 6px -6px #bbb;
  -moz-box-shadow: 0 10px 6px -6px #bbb;
  -webkit-box-shadow: 0 10px 6px -6px #bbb; }

/*Effect 2,3,4*/
.shadow-effect-2 {
  position: relative; }

.shadow-effect-2:after,
.shadow-effect-2:before,
.shadow-effect-3:before,
.shadow-effect-4:after {
  transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
  -moz-box-shadow: 0 15px 10px #999;
  -webkit-box-shadow: 0 15px 10px #999; }

.shadow-effect-2:after,
.shadow-effect-4:after {
  left: auto;
  right: 5px;
  transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -webkit-transform: rotate(3deg); }

/*Effect 5*/
.shadow-effect-5 {
  box-shadow: 0 0 2px #ccc; }

/*--------------------------------------------------
[26. Blog Trending]
----------------------------------------------------*/
.blog-trending li {
  margin-bottom: 20px; }

.blog-trending h3 {
  font-size: 14px;
  margin: 0 0 5px;
  line-height: 17px; }

.blog-trending small,
.blog-trending small a {
  color: #777; }

.blog-trending small a:hover {
  color: #72c02c; }

/*--------------------------------------------------
[27. Blog Latest Posts]
----------------------------------------------------*/
.blog-latest-posts li {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd; }

.blog-latest-posts li:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none; }

.blog-latest-posts h3 {
  font-size: 14px;
  margin: 0 0 3px;
  line-height: 20px; }

.blog-latest-posts small,
.blog-latest-posts small a {
  color: #777; }

.blog-latest-posts small {
  margin-bottom: 8px;
  display: inline-block; }

.blog-latest-posts p {
  font-size: 12px; }

/*--------------------------------------------------
[28. Blog Photostream]
----------------------------------------------------*/
.blog-photostream {
  margin-right: -5px; }

.blog-photostream li {
  padding: 0;
  margin: 3px 5px 5px;
  position: relative; }

.blog-photostream li img {
  width: 78px;
  height: auto; }

/*--------------------------------------------------
[29. Newsletter]
----------------------------------------------------*/
.blog-newsletter p {
  color: #555;
  margin-bottom: 15px; }

.blog-newsletter .form-control {
  border: none; }

.blog-newsletter .form-control:focus {
  box-shadow: none; }

/*--------------------------------------------------
[30. Blog Post Quote]
----------------------------------------------------*/
.blog-post-quote {
  padding: 35px 60px;
  background: #fff;
  text-align: center; }

.blog-post-quote p {
  color: #555;
  font-size: 32px;
  font-weight: 200;
  line-height: 45px;
  position: relative;
  margin-bottom: 20px;
  font-family: "Open Sans", Arial, sans-serif; }

@media (max-width: 768px) {
  .blog-post-quote {
    padding: 35px 25px; }
  .blog-post-quote p {
    font-size: 26px;
    line-height: 35px; } }

.blog-post-quote p:before,
.blog-post-quote p:after {
  top: -3px;
  font-size: 30px;
  position: relative; }

.blog-post-quote p:before {
  left: -10px;
  content: "\0022"; }

.blog-post-quote p:after {
  right: -10px;
  content: "\0022"; }

.blog-post-quote span {
  color: #555;
  font-size: 20px;
  font-weight: 200; }

/*--------------------------------------------------
[31. Blog Post Author]
----------------------------------------------------*/
.blog-author {
  padding: 20px;
  background: #fff; }

.blog-author img {
  float: left;
  width: 80px;
  height: auto;
  margin-right: 20px; }

.blog-author .blog-author-desc {
  overflow: hidden; }

.blog-author .blog-author-desc h4 {
  float: left;
  font-size: 18px;
  margin: 0 0 10px;
  text-transform: uppercase; }

.blog-author .blog-author-desc ul {
  float: right;
  margin-top: 6px; }

.blog-author .blog-author-desc p {
  color: #555; }

/*--------------------------------------------------
[32. Blog Comments]
----------------------------------------------------*/
.blog-comments img {
  width: 80px;
  height: auto; }

.blog-comments .comments-itself {
  background: #fff;
  padding: 20px 30px;
  position: relative; }

.blog-comments .comments-itself:before {
  width: 0;
  height: 0;
  top: 20px;
  left: -17px;
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 15px 17px 15px 0;
  border-color: transparent #fff transparent transparent; }

.blog-comments .comments-itself h3 {
  margin: 0 0 10px; }

.blog-comments .comments-itself span {
  color: #555;
  float: right;
  font-size: 13px; }

.blog-comments .comments-itself p {
  color: #555; }

/*Comments Reply*/
.blog-comments.blog-comments-reply {
  margin-left: 70px; }

/*Media Queries*/
@media (max-width: 768px) {
  .blog-comments .comments-itself:before {
    display: none; }
  .blog-comments .comments-itself:after {
    width: 0;
    height: 0;
    top: -17px;
    left: 20px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 0 15px 17px 15px;
    border-color: transparent transparent #fff transparent; } }

/*--------------------------------------------------
[33. News]
----------------------------------------------------*/
/*News v1
------------------------------------*/
.news-section {
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee; }

.news-v1 .news-v1-in {
  background: #fafafa; }

.news-v1 h3 {
  margin-bottom: 0;
  font-weight: 200;
  line-height: 28px;
  padding: 10px 15px 15px; }

.news-v1 h3 a {
  color: #555; }

.news-v1 h3 a:hover {
  color: #72c02c; }

.news-v1 p {
  color: #555;
  padding: 0 15px;
  margin-bottom: 20px; }

.news-v1 .news-v1-info {
  margin-left: 0;
  margin-top: 30px;
  overflow: hidden;
  padding: 8px 10px;
  border-top: solid 1px #eee; }

.news-v1 .news-v1-info li {
  font-size: 12px; }

.news-v1 .news-v1-info li a:hover {
  color: #72c02c;
  text-decoration: none; }

/*News v2
------------------------------------*/
/*News Badge*/
.news-v2 .news-v2-badge {
  overflow: hidden;
  position: relative; }

.news-v2 .news-v2-badge p {
  left: 0;
  top: 20px;
  background: #fff;
  padding: 5px 15px;
  text-align: center;
  position: absolute; }

.news-v2 .news-v2-badge span {
  color: #555;
  display: block;
  font-size: 16px;
  line-height: 16px; }

.news-v2 .news-v2-badge small {
  color: #555;
  display: block;
  font-size: 10px;
  text-transform: uppercase; }

/*News Description*/
.news-v2 .news-v2-desc {
  padding: 20px;
  background: #fff; }

.news-v2 .news-v2-desc h3 {
  margin: 0 0 3px;
  font-size: 16px; }

.news-v2 .news-v2-desc h3 a {
  color: #555; }

.news-v2 .news-v2-desc h3 a:hover {
  color: #72c02c; }

.news-v2 .news-v2-desc small {
  color: #555;
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase; }

.news-v2 .news-v2-desc p {
  color: #555; }

/*News v3
------------------------------------*/
/*News Posts*/
.news-v3 {
  /*background: #fff;*/ }

.news-v3 .news-v3-in {
  padding: 35px 30px; }

.news-v3 .news-v3-in-sm {
  padding: 20px; }

.news-v3 h2 {
  font-size: 32px;
  font-weight: 200;
  margin: 0 0 20px;
  line-height: 45px;
  text-transform: uppercase; }

.news-v3 .news-v3-in-sm h2 {
  font-size: 22px;
  margin: 0 0 15px;
  line-height: 30px;
  text-transform: inherit; }

@media (max-width: 768px) {
  .news-v3 h2 {
    font-size: 22px;
    line-height: 30px; } }

.news-v3 h2 a {
  color: #555; }

.news-v3 h2 a:hover {
  color: #72c02c;
  text-decoration: none; }

.news-v3 p {
  color: #555;
  margin-bottom: 20px; }

/*News Info*/
.news-v3 .posted-info li {
  color: #555;
  font-style: italic; }

.news-v3 .news-v3-in-sm .posted-info li {
  padding: 0;
  font-size: 12px;
  padding-left: 5px; }

.news-v3 .posted-info li:before,
.news-v3 .news-v3-in-sm .posted-info li:before {
  content: '/'; }

.news-v3 .posted-info li:before {
  font-size: 12px;
  line-height: 12px;
  margin-right: 13px; }

.news-v3 .news-v3-in-sm .posted-info li:before {
  font-size: 11px;
  line-height: 11px;
  margin-right: 9px; }

.news-v3 .posted-info li:first-child:before,
.news-v3 .news-v3-in-sm .posted-info li:first-child:before {
  content: " ";
  margin-right: 0; }

.news-v3 .posted-info li a {
  color: #555; }

.news-v3 .posted-info li a:hover {
  color: #72c02c; }

/*News Shares*/
.news-v3 .post-shares {
  padding-left: 0;
  list-style: none;
  margin: 40px 0 0 -5px; }

.news-v3 .news-v3-in-sm .post-shares {
  margin: 20px 0 0 -5px; }

.news-v3 .post-shares li {
  position: relative;
  padding: 0 10px 0 5px;
  display: inline-block; }

.news-v3 .post-shares li i {
  color: #555;
  width: 40px;
  height: 40px;
  padding: 13px;
  font-size: 16px;
  background: #eee;
  text-align: center;
  display: inline-block; }

.news-v3 .post-shares li i:hover {
  background: #ddd; }

.news-v3 .post-shares li span {
  top: -5px;
  right: 6px;
  color: #fff;
  width: 22px;
  height: 22px;
  padding: 4px;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  text-align: center;
  background: #72c02c;
  border: 2px solid #fff;
  border-radius: 50% !important; }

.news-v3 .post-shares-lg li span {
  top: -10px;
  right: 0px;
  width: 29px;
  height: 29px;
  padding: 4px;
  font-size: 10px;
  line-height: 16px; }

/*--------------------------------------------------
[34. Blog Grid]
----------------------------------------------------*/
.blog-grid img {
  margin-bottom: 15px; }

.blog-grid h3 {
  font-size: 22px;
  margin: 0 0 10px;
  line-height: 1.4; }

.blog-grid h2.blog-grid-title-lg {
  font-size: 28px;
  line-height: 1.4;
  margin: 0 0 10px; }

.blog-grid h3.blog-grid-title-sm {
  font-size: 15px;
  line-height: 1.4; }

.blog-grid h3 a {
  color: #333; }

.blog-grid p {
  margin-bottom: 15px; }

.blog-grid-inner {
  padding: 20px;
  background: #fff;
  margin: -70px 40px 0;
  position: relative; }

/*Blog Grid Gradient*/
.blog-grid .blog-grid-grad {
  position: relative; }

.blog-grid .blog-grid-grad i {
  top: 10px;
  left: 10px;
  z-index: 1;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  display: inline-block;
  background: rgba(0, 0, 0, 0.5); }

/*Blog Grid Info*/
.blog-grid .blog-grid-info {
  padding-left: 0;
  list-style: none; }

.blog-grid .blog-grid-info li {
  color: #888;
  padding: 0 2px;
  font-size: 12px;
  display: inline-block; }

.blog-grid .blog-grid-info li:before {
  content: '/';
  font-size: 12px;
  line-height: 1.4;
  margin-right: 9px; }

.blog-grid .blog-grid-info li:first-child:before {
  content: " ";
  margin-right: 0; }

.blog-grid .blog-grid-info li a {
  color: #888; }

.blog-grid .blog-grid-info li a:hover {
  text-decoration: none; }

/*Read More Link*/
.blog-grid a.r-more {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  display: inline-block; }

.blog-grid a.r-more:hover {
  text-decoration: none; }

/*--------------------------------------------------
[35. Blog Thumb]
----------------------------------------------------*/
.blog-thumb {
  overflow: hidden; }

/*Blog Grid Hover*/
.blog-thumb .blog-thumb-hover {
  float: left;
  position: relative;
  margin-right: 15px; }

.blog-thumb .blog-thumb-hover:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.35s, transform 0.35s; }

.blog-thumb .blog-thumb-hover img {
  width: 120px;
  height: auto; }

.blog-thumb .blog-thumb-hover img.video-play-btn {
  width: 30px;
  height: 30px; }

/*Icon Hover Gradient*/
.blog-thumb .blog-thumb-hover .hover-grad {
  left: 0;
  top: 50%;
  z-index: 1;
  opacity: 0;
  width: 100%;
  color: #fff;
  font-size: 20px;
  margin-top: -14px;
  text-align: center;
  position: absolute;
  display: inline-block;
  transition: all 0.65s, transform 0.65s; }

.blog-thumb .blog-thumb-hover:hover .hover-grad {
  opacity: 1;
  transition: opacity 0.55s, transform 0.55s; }

/*Blog Thumb Description*/
.blog-thumb .blog-thumb-desc {
  overflow: hidden; }

.blog-thumb .blog-thumb-desc h3 {
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 400;
  line-height: 1.4; }

.blog-thumb .blog-thumb-desc h3 a {
  color: #555; }

/*Blog Grid Info*/
.blog-thumb .blog-thumb-info {
  padding-left: 0;
  list-style: none; }

.blog-thumb .blog-thumb-info li {
  color: #888;
  padding: 0 2px;
  font-size: 12px;
  display: inline-block; }

.blog-thumb .blog-thumb-info li:before {
  content: '/';
  font-size: 12px;
  line-height: 1.4;
  margin-right: 9px; }

.blog-thumb .blog-thumb-info li:first-child:before {
  content: " ";
  margin-right: 0; }

.blog-thumb .blog-thumb-info li a {
  color: #888; }

.blog-thumb .blog-thumb-info li a:hover {
  color: #e74c3c; }

/*Blog Thumb Circle
------------------------------------*/
.blog-thumb.blog-thumb-circle .blog-thumb-hover:after {
  border-radius: 50% !important; }

.blog-thumb.blog-thumb-circle .blog-thumb-hover img {
  width: 50px;
  height: 50px; }

.blog-thumb.blog-thumb-circle .blog-thumb-hover .hover-grad {
  font-size: 15px;
  margin-top: -10px; }

/*Blog Thumb Colors
------------------------------------*/
.blog-thumb .blog-thumb-hover:hover:after {
  background: rgba(114, 192, 44, 0.9); }

.blog-grid .blog-grid-grad i:hover {
  background: #72c02c; }

/*--------------------------------------------------
[36. Shop-subscribe]
----------------------------------------------------*/
.shop-subscribe {
  padding: 25px 0; }

.shop-subscribe h2 {
  margin: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  text-transform: uppercase; }

/*Input Form Control*/
.shop-subscribe input {
  border-color: #fff;
  border-right: none;
  background: transparent; }

.shop-subscribe .form-control {
  color: #fff;
  font-size: 14px;
  font-weight: 200; }

.shop-subscribe .form-control:focus {
  box-shadow: none;
  border-color: #fff; }

.shop-subscribe .form-control::-moz-placeholder {
  color: #fff; }

.shop-subscribe .form-control:-ms-input-placeholder {
  color: #fff; }

.shop-subscribe .form-control::-webkit-input-placeholder {
  color: #fff; }

.shop-subscribe .input-group-btn {
  border-color: #fff;
  background: transparent; }

.shop-subscribe .input-group-btn .btn {
  border: 1px solid #fff;
  background: transparent; }

.shop-subscribe .input-group-btn i {
  color: #fff;
  font-size: 16px;
  font-weight: 200; }

/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.9
 * Author: @htmlstream
 * Website: http://htmlstream.com

[-----*** TABLE OF CONTENT ***-----]

1- Parallax Slider (Main)
2- Master Slider
3- Revolution Slider
4- Layer Slider
5- Sequence Slider (Home Page)
6- Clients Carousel
7- Pie Chart
8- Counter
9- Back To Top
10- Owl Carousel
	10.1- Recent Works (Owl Carousel)
	10.2- Parallax Twitter (Owl Carousel)
	10.3- Clients Section (Owl Carousel)
	10.4- Owl Video
-----------------------------------------------------------------------*/
/*--------------------------------------------------
    [1. Parallax Slider (Main)]
----------------------------------------------------*/
.slider-inner .da-slider {
  box-shadow: none;
  border-bottom: 2px solid #ddd; }

.slider-inner .da-dots {
  z-index: 10; }

@media (max-width: 450px) {
  .da-slide p {
    display: none; } }

@media (max-width: 900px) {
  /*Slider Parallax*/
  .da-slide .da-img {
    display: none; } }

/*--------------------------------------------------
    [2. Master Slider]
----------------------------------------------------*/
.ms-promo-info {
  font-size: 45px;
  font-weight: 300;
  line-height: 1.5;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif; }

.ms-promo-info-in {
  font-size: 60px;
  font-weight: 600; }

.ms-promo-sub {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  font-family: "Open Sans", Arial, sans-serif; }

.ms-promo-sub-in {
  font-size: 15px; }

.ms-img-bordered {
  background: #fff;
  padding: 7px; }

/*--------------------------------------------------
    [3. Revolution Slider]
----------------------------------------------------*/
.tp-dottedoverlay {
  z-index: 1 !important; }

.tp-bullets.preview4 .bullet {
  border-radius: 10px !important; }

.tp-banner-container ul li,
.fullscreenbanner-container ul li {
  list-style: none;
  visibility: hidden; }

.tp-banner-container a.btn-u,
.tp-banner-container a.btn-u:hover {
  color: #fff;
  border: solid 1px transparent; }

.tp-banner-container a.re-btn-brd {
  background: none;
  border: solid 1px #fff; }

.tp-banner-container a.re-btn-brd:hover {
  color: #555;
  background: #fff; }

/*Fullwidth*/
.tp-banner-container .btn-u.btn-brd {
  font-weight: 400; }

.tp-banner-container {
  position: relative; }

/*Fullscreen*/
.fullscreen-container {
  padding: 0;
  width: 100%;
  position: relative; }

/*Slider Contents v1*/
.revolution-mch-1:after {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  position: absolute;
  background: rgba(0, 0, 0, 0.3); }

.revolution-ch1 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif; }

.revolution-ch2 {
  color: #fff;
  font-size: 20px;
  max-width: 470px;
  line-height: 32px;
  position: relative;
  text-align: center;
  font-weight: normal;
  font-family: "Open Sans", Arial, sans-serif; }

.revolution-ch2:after {
  left: 50%;
  width: 70px;
  height: 2px;
  content: ' ';
  bottom: -20px;
  background: #fff;
  margin-left: -35px;
  position: absolute; }

/*Slider Contents v2*/
.re-title-v1 {
  color: #fff;
  font-size: 75px;
  font-weight: 600;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif; }

.re-title-v2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif; }

.re-text-v1 {
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-family: "Open Sans", Arial, sans-serif; }

.re-text-v2 {
  color: #fff;
  font-size: 24px;
  font-family: "Open Sans", Arial, sans-serif; }

/*Slides*/
.rs-caption-1 {
  color: #fff;
  font-size: 32px;
  font-weight: 200;
  padding: 6px 75px;
  position: relative;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff; }

.rs-caption-1:after,
.rs-caption-1:before {
  font-size: 16px;
  content: "\f005";
  position: absolute;
  font-family: FontAwesome; }

@media (max-width: 768px) {
  .rs-caption-1:after,
  .rs-caption-1:before {
    content: ""; } }

.rs-caption-1:after {
  right: 4%; }

.rs-caption-1:before {
  left: 4%; }

.rs-caption-2 {
  color: #fff;
  position: relative;
  font-size: 26px;
  font-weight: 200;
  line-height: 40px;
  padding: 10px 50px;
  text-align: center; }

.rs-caption-2:after {
  left: 50%;
  content: "";
  height: 1px;
  width: 100px;
  bottom: -10px;
  background: #fff;
  margin-left: -50px;
  position: absolute; }

.rs-caption-3 a {
  margin: 0 5px;
  padding: 7px 20px;
  text-transform: uppercase; }

@media (max-width: 768px) {
  .rs-caption-1,
  .rs-caption-2,
  .rs-caption-3 {
    font-weight: normal; } }

/*--------------------------------------------------
    [4. Layer Slider]
----------------------------------------------------*/
.layer_slider {
  overflow: hidden; }

.ls-ct-half, .ls-ct-center {
  border-radius: 100px !important; }

/*--------------------------------------------------
    [5. Sequence Slider (Home Page)]
----------------------------------------------------*/
@media (max-width: 900px) {
  #sequence-theme .info {
    top: 80px !important;
    width: 60% !important;
    margin-right: 50px; } }

@media (max-width: 450px) {
  #sequence-theme .info {
    top: 70px !important;
    width: 70% !important;
    margin-right: 25px; }
  #sequence-theme h2 {
    font-size: 30px !important; }
  #sequence-theme .info p {
    font-size: 18px !important; } }

/*--------------------------------------------------
    [6. Clients Carousel]
----------------------------------------------------*/
.clients {
  border: 0;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
  box-shadow: none !important;
  margin-bottom: 0 !important; }

.clients li {
  overflow: hidden;
  margin-left: 1px;
  position: relative;
  background: #fafafa; }

.clients li:hover {
  background: #f6f6f6; }

.clients li img.color-img {
  top: 0;
  left: 0;
  display: none;
  position: absolute; }

.clients li:hover img.color-img {
  display: block; }

.clients li img {
  filter: gray;
  /* IE6-9 */
  float: none !important;
  margin: 0 auto !important; }

.clients li img:hover {
  filter: none; }

/*--------------------------------------------------
    [7. Pie Chart]
----------------------------------------------------*/
.pie-progress-charts .inner-pchart {
  text-align: center; }

.pie-progress-charts .circle {
  color: #555;
  font-weight: 200;
  margin-bottom: 20px; }

.pie-progress-charts .circle-title {
  font-size: 22px; }

@media (max-width: 767px) {
  .pie-progress-charts .inner-pchart {
    margin-bottom: 40px; } }

/*--------------------------------------------------
    [8. Counter]
----------------------------------------------------*/
.counters {
  color: #555;
  text-align: center; }

@media (max-width: 767px) {
  .counters {
    margin-bottom: 30px; }
  .counters:last-child {
    margin-bottom: inherit; } }

.counters span {
  font-size: 35px; }

.counters span.counter-icon {
  display: block;
  margin-bottom: 10px; }

.counters span.counter-icon i {
  color: #fff;
  padding: 16px;
  min-width: 65px;
  font-size: 30px;
  position: relative;
  background: #72c02c; }

.counters span.counter-icon i:after {
  width: 0;
  height: 0;
  left: 38%;
  content: " ";
  bottom: -7px;
  display: block;
  position: absolute;
  border-top: 7px solid #72c02c;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/ }

.counters:hover span.counter-icon i {
  border-color: #000; }

/*--------------------------------------------------
    [9. Back To Top]
----------------------------------------------------*/
#topcontrol {
  color: #fff;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #222;
  position: relative;
  right: 14px !important;
  bottom: 11px !important;
  border-radius: 3px !important; }

#topcontrol:after {
  top: -2px;
  left: 8.5px;
  content: "\f106";
  position: absolute;
  text-align: center;
  font-family: FontAwesome; }

#topcontrol:hover {
  color: #fff;
  background: #72c02c;
  transition: all 0.3s ease-in-out; }

/*--------------------------------------------------
    [10. Owl Carousel]
----------------------------------------------------*/
.owl-btn {
  color: #777;
  cursor: pointer;
  font-size: 18px;
  padding: 2px 9px;
  text-align: center;
  background: #f5f5f5; }

.owl-btn:hover {
  color: #fff;
  background: #72c02c; }

/*Owl Carousel v1*/
.owl-carousel-v1 .owl-navigation {
  text-align: right;
  margin-bottom: 20px; }

.owl-carousel-v1 .item {
  margin: 0 5px; }

.owl-carousel-v1 .item img {
  text-align: center; }

.owl-carousel-v1 .owl-pagination {
  display: none; }

/*Owl Carousel v2*/
.owl-carousel-v2 .owl-slider-v2 {
  margin-bottom: 20px; }

.owl-carousel-v2 .item {
  margin: 0 15px; }

.owl-carousel-v2 .item img {
  text-align: center; }

.owl-carousel-v2 .owl-navigation {
  text-align: center; }

.owl-carousel-v2 .owl-pagination {
  display: none; }

/*Owl Carousel v3*/
.owl-carousel-v3 .item {
  margin: 0 15px; }

.owl-carousel-v3 .item img {
  text-align: center; }

.owl-carousel-v3 .owl-pagination {
  display: none; }

/*Owl Carousel v4*/
.owl-carousel-v4 .owl-wrapper-outer {
  margin-bottom: 20px; }

.owl-carousel-v4 .item {
  margin: 0 3px; }

.owl-carousel-v4 .item img {
  width: 100%; }

/*Owl Carousel v5*/
.owl-carousel-v5 .owl-wrapper-outer {
  margin-bottom: 20px; }

.owl-carousel-v5 .owl-controls .owl-page.active span,
.owl-carousel-v5 .owl-controls.clickable .owl-page:hover span {
  background: #72c02c; }

.owl-carousel-v5 .owl-controls .owl-page span {
  margin: 5px 3px !important; }

/*Owl Slider v6*/
.owl-carousel-v6 .owl-carousel-item {
  margin: 0 10px;
  padding: 7px 0;
  text-align: center;
  position: relative;
  border: 1px solid #e4e2e2; }

.owl-carousel-v6 .owl-carousel-item img {
  width: 150px;
  height: auto; }

.owl-carousel-v6 .owl-wrapper-outer {
  margin-bottom: 20px; }

.owl-carousel-v6 .owl-controls .owl-page span {
  margin: 5px 3px !important; }

/*Owl Pagination*/
.owl-pagination {
  text-align: center !important; }

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  opacity: 1;
  filter: Alpha(Opacity=100);
  /*IE7 fix*/ }

.owl-theme .owl-controls .owl-page {
  zoom: 1;
  display: inline-block;
  *display: inline;
  /*IE7 life-saver */ }

.owl-theme .owl-controls .owl-page span {
  width: 12px;
  height: 12px;
  opacity: 0.3;
  display: block;
  margin: 5px 7px;
  background: #bbb;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  border-radius: 50% !important; }

/*Owl Carousel Style v1*/
.owl-carousel-style-v1 img {
  width: 120px;
  height: auto; }

/*Owl Carousel Style v2*/
.owl-carousel-style-v2 .item {
  margin: 0 3px;
  background: #fafafa;
  text-align: center;
  border: solid 1px #eee; }

.owl-carousel-style-v2 .item img {
  padding: 0 3px;
  display: inline-block; }

.owl-carousel-style-v2 .item:hover {
  background: #f7f7f7;
  border-color: #e5e5e5;
  transition: all 0.4s ease-in-out; }

.owl-carousel-style-v2 .owl-pagination {
  display: none; }

/*Owl Clients v2
------------------------------------*/
.owl-clients-v1 img {
  width: 120px;
  height: auto; }

.owl-clients-v1 .owl-pagination {
  display: none; }

/*Owl Carousel - Testimonials v3
------------------------------------*/
/*Testimonials Section*/
.testimonials-v3 {
  padding: 100px 0;
  font-family: "Open Sans", Arial, sans-serif; }

.testimonials-v3 ul {
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative; }

.testimonials-v3 li img {
  width: 150px;
  height: 150px;
  margin-bottom: 10px; }

.testimonials-v3 li > p {
  font-size: 14px; }

.testimonials-v3 .testimonials-v3-title {
  font-size: 22px;
  font-weight: 200;
  margin-bottom: 20px; }

.testimonials-v3 .testimonials-v3-title p {
  color: #72c02c;
  margin-bottom: 0; }

.testimonials-v3 .testimonials-v3-title span {
  color: #777;
  display: block;
  font-size: 14px; }

/*Testimonials Owl Navigation*/
.testimonials-v3 .owl-pagination {
  display: none; }

.testimonials-v3 .owl-buttons .owl-prev,
.testimonials-v3 .owl-buttons .owl-next {
  padding: 13px;
  position: relative;
  margin: 15px 2px 0;
  background: #f5f5f5;
  display: inline-block; }

.testimonials-v3 .owl-buttons .owl-prev:after,
.testimonials-v3 .owl-buttons .owl-next:after {
  top: 0;
  color: #bbb;
  font-size: 16px;
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome; }

.testimonials-v3 .owl-buttons .owl-prev:after {
  left: 10px;
  content: "\f104"; }

.testimonials-v3 .owl-buttons .owl-next:after {
  right: 10px;
  content: "\f105"; }

.testimonials-v3 .owl-buttons .owl-prev:hover:after,
.testimonials-v3 .owl-buttons .owl-next:hover:after {
  color: #fff; }

.testimonials-v3 .owl-buttons .owl-prev:hover,
.testimonials-v3 .owl-buttons .owl-next:hover {
  background: #72c02c;
  transition: background 0.4s ease-in-out; }

/*Recent Works (Owl Carousel)
------------------------------------*/
.owl-work-v1 .owl-navigation {
  top: 3px;
  position: relative; }

.owl-work-v1 .owl-navigation {
  margin-bottom: 10px; }

.owl-work-v1 .item a {
  text-align: center; }

.owl-work-v1 .item a:hover {
  text-decoration: none; }

.owl-work-v1 .item a:hover strong {
  color: #555; }

.owl-work-v1 .item em.overflow-hidden {
  display: block; }

.owl-work-v1 .item a span {
  display: block;
  padding: 10px;
  border-bottom: solid 2px #eee; }

.owl-work-v1 .item a strong {
  color: #555;
  display: block; }

.owl-work-v1 .item a i {
  color: #777; }

.owl-work-v1 .item a img {
  transition: all 0.8s ease-in-out; }

.owl-work-v1 .item a:hover img {
  opacity: 0.8;
  transform: scale(1.2) rotate(3deg); }

.owl-work-v1 .item a:hover span {
  border-bottom: solid 2px #72c02c;
  transition: all 0.3s ease-in-out; }

/*Parallax Twitter (Owl Carousel)
------------------------------------*/
.parallax-twitter:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.5); }

.parallax-twitter-in {
  z-index: 1;
  position: relative; }

/*Twitter Block (owl-controls)*/
.parallax-twitter-in ul {
  margin: 0; }

.parallax-twitter-in li {
  color: #fff; }

.parallax-twitter-in li p {
  color: #fff;
  font-size: 18px;
  font-weight: 200; }

.parallax-twitter-in li a {
  color: #eee;
  text-decoration: underline; }

.parallax-twitter-in li a:hover {
  text-decoration: none; }

.parallax-twitter-in li span {
  color: #ddd;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px; }

.parallax-twitter-in .owl-page {
  width: 18px; }

/*Clients Section (Owl Carousel)
------------------------------------*/
.clients-section .title-v1 p,
.clients-section .title-v1 h2 {
  color: #fff; }

.clients-section ul {
  padding: 0;
  z-index: 1;
  list-style: none; }

.clients-section li {
  margin: 0 3px;
  background: #fff; }

.clients-section li a {
  padding: 10px;
  display: block;
  overflow: hidden;
  text-align: center;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.clients-section li a:hover {
  -transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2); }

.clients-section img {
  width: 100px;
  height: 100px; }

.clients-section .owl-pagination {
  display: none; }

/*Owl Video
------------------------------------*/
.owl-video img {
  position: relative;
  text-align: center;
  margin: 0 auto 20px; }

.owl-video img.video-play {
  top: 50%;
  left: 50%;
  margin-top: -52px;
  margin-left: -52px;
  position: absolute; }

.owl-video .owl-controls .owl-page span {
  margin: 5px 3px; }

/*
* Template Name: Unify - Responsive Bootstrap Template
* Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
* Version: 1.9.
* Author: @htmlstream
* Website: http://htmlstream.com
*/
/*Reset Styles
------------------------------------*/
* {
  border-radius: 0; }

a,
a:focus,
a:hover,
a:active,
button,
button:hover {
  outline: 0 !important; }

a:focus {
  text-decoration: none; }

hr {
  margin: 30px 0; }

hr.hr-xs {
  margin: 10px 0; }

hr.hr-md {
  margin: 20px 0; }

hr.hr-lg {
  margin: 40px 0; }

/*Headings*/
h1 {
  font-size: 28px;
  line-height: 35px; }

h2 {
  font-size: 24px;
  line-height: 33px; }

h3 {
  font-size: 20px;
  line-height: 27px; }

h4 {
  line-height: 25px; }

h5 {
  line-height: 20px; }

h6 {
  line-height: 18px; }

.font-open-sans {
  font-family: "Open Sans", Arial, sans-serif; }

h1, h2, h3, h4, h5, h6 {
  color: #555;
  margin-top: 5px;
  text-shadow: none;
  font-weight: normal;
  font-family: "Open Sans", Arial, sans-serif; }

h1 i, h2 i, h3 i, h4 i, h5 i, h6 i {
  margin-right: 5px; }

/*Block Headline*/
.headline {
  display: block;
  margin: 10px 0 25px 0;
  border-bottom: 1px dotted #e4e9f0; }

.headline h2 {
  font-size: 22px; }

.headline h2,
.headline h3,
.headline h4 {
  margin: 0 0 -2px 0;
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 2px solid #72c02c; }

.headline-md {
  margin-bottom: 15px; }

.headline-md h2 {
  font-size: 21px; }

/*Heading Options*/
.heading {
  text-align: center; }

.heading h2 {
  padding: 0 12px;
  position: relative;
  display: inline-block;
  line-height: 34px !important;
  /*For Tagline Boxes*/ }

.heading h2:before,
.heading h2:after {
  content: ' ';
  width: 70%;
  position: absolute;
  border-width: 1px;
  border-color: #bbb; }

.heading h2:before {
  right: 100%; }

.heading h2:after {
  left: 100%; }

@media (max-width: 768px) {
  .heading h2:before,
  .heading h2:after {
    width: 20%; } }

/*Headline v1*/
.heading-v1 h2:before,
.heading-v1 h2:after {
  top: 15px;
  height: 6px;
  border-top-style: solid;
  border-bottom-style: solid; }

/*Headline v2*/
.heading-v2 h2:before,
.heading-v2 h2:after {
  top: 15px;
  height: 6px;
  border-top-style: dashed;
  border-bottom-style: dashed; }

/*Headline v3*/
.heading-v3 h2:before,
.heading-v3 h2:after {
  top: 15px;
  height: 6px;
  border-top-style: dotted;
  border-bottom-style: dotted; }

/*Headline v4*/
.heading-v4 h2:before,
.heading-v4 h2:after {
  top: 17px;
  border-bottom-style: solid; }

/*Headline v5*/
.heading-v5 h2:before,
.heading-v5 h2:after {
  top: 17px;
  border-bottom-style: dashed; }

/*Headline v6*/
.heading-v6 h2:before,
.heading-v6 h2:after {
  top: 17px;
  border-bottom-style: dotted; }

/*Heading Titles v1*/
.title-v1 {
  z-index: 1;
  position: relative;
  text-align: center;
  margin-bottom: 60px; }

.title-v1 h1,
.title-v1 h2 {
  color: #444;
  font-size: 28px;
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 20px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif; }

.title-v1 h1:after,
.title-v1 h2:after {
  bottom: 0;
  left: 50%;
  height: 1px;
  width: 70px;
  content: " ";
  margin-left: -35px;
  position: absolute;
  background: #72c02c; }

.title-v1 p {
  font-size: 17px;
  font-weight: 200; }

/*Heading Titles v2*/
h2.title-v2 {
  color: #555;
  position: relative;
  margin-bottom: 30px; }

h2.title-v2:after {
  left: 0;
  width: 70px;
  height: 2px;
  content: " ";
  bottom: -10px;
  background: #555;
  position: absolute; }

h1.title-v2.title-center,
h2.title-v2.title-center,
h3.title-v2.title-center {
  text-align: center; }

h1.title-v2.title-center:after,
h2.title-v2.title-center:after,
h3.title-v2.title-center:after {
  left: 50%;
  width: 70px;
  margin-left: -35px; }

h1.title-light,
h2.title-light,
h3.title-light {
  color: #fff; }

h2.title-light:after {
  background: #fff; }

/*Heading Title v3*/
h1[class^="title-v3-"],
h2[class^="title-v3-"],
h3[class^="title-v3-"] {
  color: #555; }

h2.title-v3-xlg {
  font-size: 28px;
  line-height: 32px; }

h1.title-v3-lg,
h2.title-v3-lg {
  font-size: 24px;
  line-height: 28px; }

h1.title-v3-md,
h2.title-v3-md {
  font-size: 20px;
  line-height: 24px; }

h2.title-v3-sm,
h3.title-v3-md {
  font-size: 18px;
  line-height: 24px; }

h3.title-v3-md {
  line-height: 22px; }

h3.title-v3-sm {
  font-size: 16px;
  line-height: 20px; }

h2.title-v3-xs {
  font-size: 16px;
  line-height: 22px; }

h3.title-v3-xs {
  font-size: 14px;
  margin-bottom: 0; }

/*Title v4*/
.title-v4 {
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
  border-bottom: 3px solid #333; }

/*Headline Center*/
.headline-center {
  text-align: center;
  position: relative; }

.headline-center h2 {
  color: #555;
  font-size: 24px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 15px; }

.headline-center h2:after {
  left: 50%;
  z-index: 1;
  width: 30px;
  height: 2px;
  content: " ";
  bottom: -5px;
  margin-left: -15px;
  text-align: center;
  position: absolute;
  background: #72c02c; }

.headline-center p {
  /*color: #999;*/
  font-size: 14px;
  /*padding: 0 150px;*/ }

@media (max-width: 991px) {
  .headline-center p {
    padding: 0 50px; } }

.headline-center.headline-light h2 {
  color: #fff; }

.headline-center.headline-light p {
  color: #eee; }

/*Headline Center v2*/
.headline-center-v2 {
  z-index: 0;
  text-align: center;
  position: relative; }

.headline-center-v2 h2 {
  color: #555;
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase; }

.headline-center-v2 span.bordered-icon {
  color: #fff;
  padding: 0 10px;
  font-size: 15px;
  line-height: 18px;
  position: relative;
  margin-bottom: 25px;
  display: inline-block; }

.headline-center-v2 span.bordered-icon:before,
.headline-center-v2 span.bordered-icon:after {
  top: 8px;
  height: 1px;
  content: " ";
  width: 100px;
  background: #fff;
  position: absolute; }

.headline-center-v2 span.bordered-icon:before {
  left: 100%; }

.headline-center-v2 span.bordered-icon:after {
  right: 100%; }

.headline-center-v2 p {
  color: #555;
  font-size: 14px;
  padding: 0 70px; }

.headline-center-v2.headline-center-v2-dark p {
  color: #666; }

.headline-center-v2.headline-center-v2-dark span.bordered-icon {
  color: #666; }

.headline-center-v2.headline-center-v2-dark span.bordered-icon:before,
.headline-center-v2.headline-center-v2-dark span.bordered-icon:after {
  background: #666; }

/*Headline Left*/
.headline-left {
  position: relative; }

.headline-left .headline-brd {
  color: #555;
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 10px; }

.headline-left .headline-brd:after {
  left: 1px;
  z-index: 1;
  width: 30px;
  height: 2px;
  content: " ";
  bottom: -5px;
  position: absolute;
  background: #72c02c; }

/*Headline v2
------------------------------------*/
.headline-v2 {
  display: block;
  background: #fff;
  padding: 1px 10px;
  margin: 0 0 20px 0;
  border-left: 2px solid #000; }

.headline-v2 h2 {
  margin: 3px 0;
  font-size: 20px;
  font-weight: 200; }

/*Heading Sizes
------------------------------------*/
h2.heading-md {
  font-size: 20px;
  line-height: 24px; }

h2.heading-sm,
h3.heading-md {
  font-size: 18px;
  line-height: 24px; }

h3.heading-md {
  line-height: 22px; }

h3.heading-sm {
  font-size: 16px;
  line-height: 20px; }

h2.heading-xs {
  font-size: 16px;
  line-height: 22px; }

h3.heading-xs {
  font-size: 14px;
  margin-bottom: 0; }

/*Devider
------------------------------------*/
.devider.devider-dotted {
  border-top: 2px dotted #eee; }

.devider.devider-dashed {
  border-top: 2px dashed #eee; }

.devider.devider-db {
  height: 5px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }

.devider.devider-db-dashed {
  height: 5px;
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd; }

.devider.devider-db-dotted {
  height: 5px;
  border-top: 1px dotted #ddd;
  border-bottom: 1px dotted #ddd; }

/*Tables
------------------------------------*/
/*Basic Tables*/
.table thead > tr > th {
  border-bottom: none; }

@media (max-width: 768px) {
  .table th.hidden-sm,
  .table td.hidden-sm {
    display: none !important; } }

/*Forms
------------------------------------*/
.form-control {
  box-shadow: none;
  border-radius: 0; }

.input-group-addon {
  border-radius: 0; }

.form-control:focus {
  border-color: #bbb;
  box-shadow: 0 0 2px #c9c9c9; }

/*Form Spacing*/
.form-spacing .form-control {
  margin-bottom: 15px; }

/*Form Icons*/
.input-group-addon {
  color: #b3b3b3;
  font-size: 14px;
  background: #fff; }

/*Carousel v1
------------------------------------*/
.carousel-v1 .carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 7px 15px;
  background: rgba(0, 0, 0, 0.7); }

.carousel-v1 .carousel-caption p {
  color: #fff;
  margin-bottom: 0; }

.carousel-v1 .carousel-arrow a.carousel-control {
  opacity: 1;
  font-size: 30px;
  height: inherit;
  width: inherit;
  background: none;
  text-shadow: none;
  position: inherit; }

.carousel-v1 .carousel-arrow a i {
  top: 50%;
  opacity: 0.6;
  background: #000;
  margin-top: -18px;
  padding: 2px 12px;
  position: absolute; }

.carousel-v1 .carousel-arrow a i:hover {
  opacity: 0.8; }

.carousel-v1 .carousel-arrow a.left i {
  left: 0; }

.carousel-v1 .carousel-arrow a.right i {
  right: 0; }

/*Carousel v2
------------------------------------*/
.carousel-v2 .carousel-control,
.carousel-v2 .carousel-control:hover {
  opacity: 1;
  text-shadow: none; }

.carousel-v2 .carousel-control.left,
.carousel-v2 .carousel-control.right {
  top: 50%;
  z-index: 5;
  color: #eee;
  width: 45px;
  height: 45px;
  font-size: 30px;
  margin-top: -22px;
  position: absolute;
  text-align: center;
  display: inline-block;
  border: 2px solid #eee;
  background: rgba(0, 0, 0, 0.1); }

.carousel-v2 .carousel-control:hover {
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out; }

.carousel-v2 .carousel-control.left {
  left: 20px; }

.carousel-v2 .carousel-control.right {
  right: 20px; }

.carousel-v2 .carousel-control .arrow-prev,
.carousel-v2 .carousel-control .arrow-next {
  top: -5px;
  position: relative; }

.carousel-v2 .carousel-control .arrow-next {
  right: -2px; }

@media (min-width: 768px) {
  .carousel-indicators {
    bottom: 10px; } }

/*Tabs
------------------------------------*/
.nav-tabs > li > a,
.nav-pills > li > a,
.nav-tabs.nav-justified > li > a {
  border-radius: 0; }

/*Tabs v1
------------------------------------*/
.tab-v1 .nav-tabs {
  border: none;
  background: none;
  border-bottom: solid 2px #72c02c; }

.tab-v1 .nav-tabs a {
  font-size: 14px;
  padding: 5px 15px; }

.tab-v1 .nav-tabs > .active > a,
.tab-v1 .nav-tabs > .active > a:hover,
.tab-v1 .nav-tabs > .active > a:focus {
  color: #fff;
  border: none;
  background: #72c02c; }

.tab-v1 .nav-tabs > li > a {
  border: none; }

.tab-v1 .nav-tabs > li > a:hover {
  color: #fff;
  background: #72c02c; }

.tab-v1 .tab-content {
  padding: 10px 0; }

.tab-v1 .tab-content img {
  margin-top: 4px;
  margin-bottom: 15px; }

.tab-v1 .tab-content img.img-tab-space {
  margin-top: 7px; }

/*Tabs v2
------------------------------------*/
.tab-v2 .nav-tabs {
  border-bottom: none; }

.tab-v2 .nav-tabs li a {
  padding: 9px 16px;
  background: none;
  border: none; }

.tab-v2 .nav-tabs li.active a {
  background: #fff;
  padding: 7px 15px 9px;
  border: solid 1px #eee;
  border-top: solid 2px #72c02c;
  border-bottom: none !important; }

.tab-v2 .tab-content {
  padding: 10px 16px;
  border: solid 1px #eee; }

/*Tabs v3
------------------------------------*/
.tab-v3 .nav-pills li a {
  color: #777;
  font-size: 17px;
  padding: 4px 8px;
  margin-bottom: 3px;
  background: #fafafa;
  border: solid 1px #eee; }

.tab-v3 .nav-pills li a:hover,
.tab-v3 .nav-pills li.active a {
  color: #fff;
  background: #72c02c;
  border: solid 1px #68af28; }

.tab-v3 .nav-pills li i {
  width: 1.25em;
  margin-right: 5px;
  text-align: center;
  display: inline-block; }

.tab-v3 .tab-content {
  padding: 15px;
  background: #fafafa;
  border: solid 1px #eee; }

/*Tab v4
------------------------------------*/
.tab-v4 .tab-heading {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  border-bottom: 3px solid #333; }

.tab-v4 .tab-heading h2 {
  margin: 0;
  float: left;
  font-size: 18px;
  font-weight: bold; }

/*Nav Tabs*/
.tab-v4 .nav-tabs {
  top: 3px;
  border: none;
  float: right;
  overflow: hidden;
  position: relative; }

.tab-v4 .nav-tabs a {
  color: #555;
  font-size: 12px;
  padding: 6px 15px;
  text-transform: uppercase; }

.tab-v4 .nav-tabs > .active > a,
.tab-v4 .nav-tabs > .active > a:hover,
.tab-v4 .nav-tabs > .active > a:focus {
  border: none;
  background: inherit; }

.tab-v4 .nav-tabs > li > a {
  border: none; }

.tab-v4 .nav-tabs > li > a:hover {
  background: inherit; }

.tab-v4 .tab-content {
  padding: 20px 0 10px; }

/*Tab v5
------------------------------------*/
.tab-v5 .nav-tabs {
  border-bottom: none; }

.tab-v5 .nav-tabs li a {
  border: none;
  font-size: 14px;
  background: none;
  padding: 9px 16px; }

.tab-v5 .nav-tabs li.active a {
  background: #fff;
  padding: 7px 15px 9px;
  border: solid 1px #eee;
  border-top: solid 3px #222;
  border-bottom: none !important; }

.tab-v5 .tab-content {
  padding: 13px;
  border: solid 1px #eee; }

/*Accordions
------------------------------------*/
/*Accordion v1*/
.acc-v1 .panel-heading {
  padding: 0;
  box-shadow: none; }

.acc-v1 .panel-heading a {
  display: block;
  font-size: 14px;
  padding: 5px 15px;
  background: #fefefe; }

.acc-icon a.accordion-toggle i {
  color: #555;
  margin-right: 8px; }

.acc-icon a.accordion-toggle:hover i {
  color: #39414c; }

/*Navigation
------------------------------------*/
.dropdown-menu,
.navbar-toggle {
  border-radius: 0; }

/*Pegination*/
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span,
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0; }

.pagination li a {
  color: #777;
  padding: 5px 15px; }

.pagination li a:hover {
  color: #fff;
  background: #5fb611;
  border-color: #5fb611; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  border-color: #72c02c;
  background-color: #72c02c; }

/*Pagination Without Space*/
.pagination-no-space .pagination {
  margin: 0; }

/*Pager*/
.pager li > a,
.pager li > span {
  border-radius: 0; }

.pager li > a:hover,
.pager li > a:focus {
  color: #fff;
  background: #5fb611;
  border-color: #5fb611; }

/*Pager v2 and v3
------------------------------------*/
.pager.pager-v2 li > a {
  border: none; }

.pager.pager-v2 li > a,
.pager.pager-v3 li > a {
  transition: all 0.1s ease-in-out; }

.pager.pager-v2 li > a:hover,
.pager.pager-v2 li > a:focus,
.pager.pager-v3 li > a:hover,
.pager.pager-v3 li > a:focus {
  color: #fff;
  background: #72c02c; }

/*Pager Amount*/
.pager.pager-v2 li.page-amount,
.pager.pager-v3 li.page-amount {
  font-size: 16px;
  font-style: italic; }

.pager.pager-v2 li.page-amount,
.pager.pager-v2 li.page-amount:hover,
.pager.pager-v2 li.page-amount:focus,
.pager.pager-v3 li.page-amount,
.pager.pager-v3 li.page-amount:hover,
.pager.pager-v3 li.page-amount:focus {
  top: 7px;
  color: #777;
  position: relative; }

/*Pager Size*/
.pager.pager-v2.pager-md li a,
.pager.pager-v3.pager-md li a {
  font-size: 16px;
  padding: 8px 18px; }

/*Pager v4
------------------------------------*/
.pager.pager-v4 li > a {
  line-height: normal;
  transition: all 0.1s ease-in-out; }

.pager.pager-v4 li > a:hover,
.pager.pager-v4 li > a:focus {
  color: #fff;
  background: #222;
  border-color: #222; }

/*Pager Amount*/
.pager.pager-v4 li.page-amount {
  font-size: 14px;
  font-style: italic; }

.pager.pager-v4 li.page-amount,
.pager.pager-v4 li.page-amount:hover,
.pager.pager-v4 li.page-amount:focus {
  top: 7px;
  color: #777;
  position: relative; }

/*Sidebar Menu
------------------------------------*/
.list-group-item:first-child,
.list-group-item:last-child {
  border-radius: 0; }

/*Sidebar Menu v1*/
.sidebar-nav-v1 li {
  padding: 0; }

.sidebar-nav-v1 li a {
  display: block;
  padding: 8px 30px 8px 10px; }

.sidebar-nav-v1 li a:hover {
  text-decoration: none; }

.sidebar-nav-v1 > li.active,
.sidebar-nav-v1 > li.active:hover {
  background: #717984; }

.sidebar-nav-v1 > li.active,
.sidebar-nav-v1 > li.active:hover,
.sidebar-nav-v1 > li.active:focus {
  border-color: #ddd; }

.sidebar-nav-v1 > li.active > a {
  color: #fff; }

/*Sidebar Sub Navigation*/
.sidebar-nav-v1 li ul {
  padding: 0;
  list-style: none; }

.sidebar-nav-v1 li ul,
.sidebar-nav-v1 li.active ul a {
  background: #f8f8f8; }

.sidebar-nav-v1 li ul a {
  color: #555;
  font-size: 12px;
  border-top: solid 1px #ddd;
  padding: 6px 30px 6px 17px; }

.sidebar-nav-v1 ul li:hover a,
.sidebar-nav-v1 ul li.active a {
  color: #72c02c; }

/*Sidebar Badges*/
.list-group-item li > .badge {
  float: right; }

.sidebar-nav-v1 span.badge {
  margin-top: 8px;
  margin-right: 10px; }

.sidebar-nav-v1 .list-toggle > span.badge {
  margin-right: 25px; }

.sidebar-nav-v1 ul li span.badge {
  margin-top: 8px;
  font-size: 11px;
  padding: 3px 5px;
  margin-right: 10px; }

/*Sidebar List Toggle*/
.list-toggle:after {
  top: 7px;
  right: 10px;
  color: #777;
  font-size: 14px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome; }

.list-toggle.active:after {
  color: #fff;
  content: "\f107"; }

/*Button Styles
------------------------------------*/
.btn {
  box-shadow: none;
  border-radius: 0; }

.btn-u {
  border: 0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  padding: 6px 13px;
  position: relative;
  background: #72c02c;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none; }

.btn-u:hover {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out; }

.btn-u.btn-block {
  text-align: center; }

a.btn-u {
  /*padding: 4px 13px;*/
  /*vertical-align: middle;*/ }

.btn-u-sm,
a.btn-u-sm {
  padding: 3px 12px; }

.btn-u-md,
a.btn-u-md {
  padding: 10px 20px; }

.btn-u-lg,
a.btn-u-lg {
  font-size: 18px;
  padding: 10px 25px; }

.btn-u-xs,
a.btn-u-xs {
  font-size: 12px;
  padding: 2px 12px;
  line-height: 18px; }

/*Button Groups*/
.btn-group .dropdown-menu > li > a {
  padding: 3px 13px; }

.btn-group > .btn-u,
.btn-group-vertical > .btn-u {
  float: left;
  position: relative; }

.btn-group > .btn-u:first-child {
  margin-left: 0; }

/*For FF Only*/
@-moz-document url-prefix() {
  .footer-subsribe .btn-u {
    padding-bottom: 4px; } }

@media (max-width: 768px) {
  @-moz-document url-prefix() {
    .btn-u {
      padding-bottom: 6px; } } }

/*Buttons Color*/
.btn-u:hover,
.btn-u:focus,
.btn-u:active,
.btn-u.active,
.open .dropdown-toggle.btn-u {
  background: #5fb611; }

.btn-u-split.dropdown-toggle {
  border-left: solid 1px #5fb611; }

.btn-u.btn-u-blue {
  background: #3498db; }

.btn-u.btn-u-blue:hover,
.btn-u.btn-u-blue:focus,
.btn-u.btn-u-blue:active,
.btn-u.btn-u-blue.active,
.open .dropdown-toggle.btn-u.btn-u-blue {
  background: #2980b9; }

.btn-u.btn-u-split-blue.dropdown-toggle {
  border-left: solid 1px #2980b9; }

.btn-u.btn-u-red {
  background: #e74c3c; }

.btn-u.btn-u-red:hover,
.btn-u.btn-u-red:focus,
.btn-u.btn-u-red:active,
.btn-u.btn-u-red.active,
.open .dropdown-toggle.btn-u.btn-u-red {
  background: #c0392b; }

.btn-u.btn-u-split-red.dropdown-toggle {
  border-left: solid 1px #c0392b; }

.btn-u.btn-u-orange {
  background: #e67e22; }

.btn-u.btn-u-orange:hover,
.btn-u.btn-u-orange:focus,
.btn-u.btn-u-orange:active,
.btn-u.btn-u-orange.active,
.open .dropdown-toggle.btn-u.btn-u-orange {
  background: #d35400; }

.btn-u.btn-u-split-orange.dropdown-toggle {
  border-left: solid 1px #d35400; }

.btn-u.btn-u-sea {
  background: #1abc9c; }

.btn-u.btn-u-sea:hover,
.btn-u.btn-u-sea:focus,
.btn-u.btn-u-sea:active,
.btn-u.btn-u-sea.active,
.open .dropdown-toggle.btn-u.btn-u-sea {
  background: #16a085; }

.btn-u.btn-u-split-sea.dropdown-toggle {
  border-left: solid 1px #16a085; }

.btn-u.btn-u-green {
  background: #2ecc71; }

.btn-u.btn-u-green:hover,
.btn-u.btn-u-green:focus,
.btn-u.btn-u-green:active,
.btn-u.btn-u-green.active,
.open .dropdown-toggle.btn-u.btn-u-green {
  background: #27ae60; }

.btn-u.btn-u-split-green.dropdown-toggle {
  border-left: solid 1px #27ae60; }

.btn-u.btn-u-yellow {
  background: #f1c40f; }

.btn-u.btn-u-yellow:hover,
.btn-u.btn-u-yellow:focus,
.btn-u.btn-u-yellow:active,
.btn-u.btn-u-yellow.active,
.open .dropdown-toggle.btn-u.btn-u-yellow {
  background: #f39c12; }

.btn-u.btn-u-split-yellow.dropdown-toggle {
  border-left: solid 1px #f39c12; }

.btn-u.btn-u-default {
  background: #95a5a6; }

.btn-u.btn-u-default:hover,
.btn-u.btn-u-default:focus,
.btn-u.btn-u-default:active,
.btn-u.btn-u-default.active,
.open .dropdown-toggle.btn-u.btn-u-default {
  background: #7f8c8d; }

.btn-u.btn-u-split-default.dropdown-toggle {
  border-left: solid 1px #7f8c8d; }

.btn-u.btn-u-purple {
  background: #9b6bcc; }

.btn-u.btn-u-purple:hover,
.btn-u.btn-u-purple:focus,
.btn-u.btn-u-purple:active,
.btn-u.btn-u-purple.active,
.open .dropdown-toggle.btn-u.btn-u-purple {
  background: #814fb5; }

.btn-u.btn-u-split-purple.dropdown-toggle {
  border-left: solid 1px #814fb5; }

.btn-u.btn-u-aqua {
  background: #27d7e7; }

.btn-u.btn-u-aqua:hover,
.btn-u.btn-u-aqua:focus,
.btn-u.btn-u-aqua:active,
.btn-u.btn-u-aqua.active,
.open .dropdown-toggle.btn-u.btn-u-aqua {
  background: #26bac8; }

.btn-u.btn-u-split-aqua.dropdown-toggle {
  border-left: solid 1px #26bac8; }

.btn-u.btn-u-brown {
  background: #9c8061; }

.btn-u.btn-u-brown:hover,
.btn-u.btn-u-brown:focus,
.btn-u.btn-u-brown:active,
.btn-u.btn-u-brown.active,
.open .dropdown-toggle.btn-u.btn-u-brown {
  background: #81674b; }

.btn-u.btn-u-split-brown.dropdown-toggle {
  border-left: solid 1px #81674b; }

.btn-u.btn-u-dark-blue {
  background: #4765a0; }

.btn-u.btn-u-dark-blue:hover,
.btn-u.btn-u-dark-blue:focus,
.btn-u.btn-u-dark-blue:active,
.btn-u.btn-u-dark-blue.active,
.open .dropdown-toggle.btn-u.btn-u-dark-blue {
  background: #324c80; }

.btn-u.btn-u-split-dark.dropdown-toggle {
  border-left: solid 1px #324c80; }

.btn-u.btn-u-light-green {
  background: #79d5b3; }

.btn-u.btn-u-light-green:hover,
.btn-u.btn-u-light-green:focus,
.btn-u.btn-u-light-green:active,
.btn-u.btn-u-light-green.active,
.open .dropdown-toggle.btn-u.btn-u-light-green {
  background: #59b795; }

.btn-u.btn-u-split-light-green.dropdown-toggle {
  border-left: solid 1px #59b795; }

.btn-u.btn-u-dark {
  background: #555; }

.btn-u.btn-u-dark:hover,
.btn-u.btn-u-dark:focus,
.btn-u.btn-u-dark:active,
.btn-u.btn-u-dark.active,
.open .dropdown-toggle.btn-u.btn-u-dark {
  background: #333; }

.btn-u.btn-u-split-dark.dropdown-toggle {
  border-left: solid 1px #333; }

.btn-u.btn-u-light-grey {
  background: #585f69; }

.btn-u.btn-u-light-grey:hover,
.btn-u.btn-u-light-grey:focus,
.btn-u.btn-u-light-grey:active,
.btn-u.btn-u-light-grey.active,
.open .dropdown-toggle.btn-u.btn-u-light-grey {
  background: #484f58; }

.btn-u.btn-u-split-light-grey.dropdown-toggle {
  border-left: solid 1px #484f58; }

/*Bordered Buttons*/
.btn-u.btn-brd {
  color: #555;
  background: none;
  padding: 5px 13px;
  border: solid 1px transparent;
  transition: all 0.1s ease-in-out; }

.btn-u.btn-brd-width-2 {
  padding: 7px 18px;
  border-width: 2px; }

.btn-u.btn-brd:hover {
  background: none; }

.btn-u.btn-brd:focus {
  background: none; }

.btn-u.btn-brd.btn-brd-hover:hover {
  color: #fff !important; }

.btn-u.btn-brd {
  border-color: #72c02c; }

.btn-u.btn-brd:hover {
  color: #5fb611;
  border-color: #5fb611; }

.btn-u.btn-brd.btn-brd-hover:hover {
  background: #5fb611; }

.btn-u.btn-brd.btn-u-blue {
  border-color: #3498db; }

.btn-u.btn-brd.btn-u-blue:hover {
  color: #2980b9;
  border-color: #2980b9; }

.btn-u.btn-brd.btn-u-blue.btn-brd-hover:hover {
  background: #2980b9; }

.btn-u.btn-brd.btn-u-red {
  border-color: #e74c3c; }

.btn-u.btn-brd.btn-u-red:hover {
  color: #c0392b;
  border-color: #c0392b; }

.btn-u.btn-brd.btn-u-red.btn-brd-hover:hover {
  background: #c0392b; }

.btn-u.btn-brd.btn-u-orange {
  border-color: #e67e22; }

.btn-u.btn-brd.btn-u-orange:hover {
  color: #d35400;
  border-color: #d35400; }

.btn-u.btn-brd.btn-u-orange.btn-brd-hover:hover {
  background: #d35400; }

.btn-u.btn-brd.btn-u-sea {
  border-color: #1abc9c; }

.btn-u.btn-brd.btn-u-sea:hover {
  color: #16a085;
  border-color: #16a085; }

.btn-u.btn-brd.btn-u-sea.btn-brd-hover:hover {
  background: #16a085; }

.btn-u.btn-brd.btn-u-green {
  border-color: #2ecc71; }

.btn-u.btn-brd.btn-u-green:hover {
  color: #27ae60;
  border-color: #27ae60; }

.btn-u.btn-brd.btn-u-green.btn-brd-hover:hover {
  background: #27ae60; }

.btn-u.btn-brd.btn-u-yellow {
  border-color: #f1c40f; }

.btn-u.btn-brd.btn-u-yellow:hover {
  color: #f39c12;
  border-color: #f39c12; }

.btn-u.btn-brd.btn-u-yellow.btn-brd-hover:hover {
  background: #f39c12; }

.btn-u.btn-brd.btn-u-default {
  border-color: #95a5a6; }

.btn-u.btn-brd.btn-u-default:hover {
  color: #7f8c8d;
  border-color: #7f8c8d; }

.btn-u.btn-brd.btn-u-default.btn-brd-hover:hover {
  background: #7f8c8d; }

.btn-u.btn-brd.btn-u-dark {
  border-color: #555; }

.btn-u.btn-brd.btn-u-dark:hover {
  color: #333;
  border-color: #333; }

.btn-u.btn-brd.btn-u-dark.btn-brd-hover:hover {
  background: #333; }

.btn-u.btn-brd.btn-u-light-grey {
  border-color: #585f69; }

.btn-u.btn-brd.btn-u-light-grey:hover {
  color: #484f58;
  border-color: #484f58; }

.btn-u.btn-brd.btn-u-light-grey.btn-brd-hover:hover {
  background: #484f58; }

.btn-u.btn-brd.btn-u-purple {
  border-color: #9b6bcc; }

.btn-u.btn-brd.btn-u-purple:hover {
  color: #814fb5;
  border-color: #814fb5; }

.btn-u.btn-brd.btn-u-purple.btn-brd-hover:hover {
  background: #814fb5; }

.btn-u.btn-brd.btn-u-aqua {
  border-color: #27d7e7; }

.btn-u.btn-brd.btn-u-aqua:hover {
  color: #26bac8;
  border-color: #26bac8; }

.btn-u.btn-brd.btn-u-aqua.btn-brd-hover:hover {
  background: #26bac8; }

.btn-u.btn-brd.btn-u-brown {
  border-color: #9c8061; }

.btn-u.btn-brd.btn-u-brown:hover {
  color: #81674b;
  border-color: #81674b; }

.btn-u.btn-brd.btn-u-brown.btn-brd-hover:hover {
  background: #81674b; }

.btn-u.btn-brd.btn-u-dark-blue {
  border-color: #4765a0; }

.btn-u.btn-brd.btn-u-dark-blue:hover {
  color: #324c80;
  border-color: #324c80; }

.btn-u.btn-brd.btn-u-dark-blue.btn-brd-hover:hover {
  background: #324c80; }

.btn-u.btn-brd.btn-u-light-green {
  border-color: #79d5b3; }

.btn-u.btn-brd.btn-u-light-green:hover {
  color: #59b795;
  border-color: #59b795; }

.btn-u.btn-brd.btn-u-light-green.btn-brd-hover:hover {
  background: #59b795; }

.btn-u.btn-brd.btn-u-light {
  color: #fff;
  border-color: #fff; }

.btn-u.btn-brd.btn-u-light:hover {
  border-color: #fff; }

.btn-u.btn-brd.btn-u-light.btn-brd-hover:hover {
  background: #fff;
  color: #555 !important; }

/*Dropdown Buttons
------------------------------------*/
.dropdown-show {
  box-shadow: 0 0 4px #eee;
  display: inline-block;
  position: relative; }

/*Badges and Labels
------------------------------------*/
.badge,
.label {
  border-radius: 0; }

/*Labels*/
span.label {
  font-size: 11px;
  font-weight: 400;
  padding: 4px 7px; }

/*Badges*/
span.badge,
.list-group-item.active > .badge {
  color: #fff;
  font-weight: 400;
  padding: 4px 7px; }

span.label-u,
span.badge-u,
.list-group-item.active > .badge.badge-u {
  background: #72c02c; }

span.label-blue,
span.badge-blue,
.list-group-item.active > .badge {
  background: #3498db; }

span.label-red,
span.badge-red,
.list-group-item.active > .badge {
  background: #e74c3c; }

span.label-green,
span.badge-green,
.list-group-item.active > .badge.badge-green {
  background: #2ecc71; }

span.label-sea,
span.badge-sea,
.list-group-item.active > .badge.badge-sea {
  background: #1abc9c; }

span.label-orange,
span.badge-orange,
.list-group-item.active > .badge.badge-orange {
  background: #e67e22; }

span.label-yellow,
span.badge-yellow,
.list-group-item.active > .badge.badge-yellow {
  background: #f1c40f; }

span.label-purple,
span.badge-purple,
.list-group-item.active > .badge.badge-purple {
  background: #9b6bcc; }

span.label-aqua,
span.badge-aqua,
.list-group-item.active > .badge.badge-aqua {
  background: #27d7e7; }

span.label-brown,
span.badge-brown,
.list-group-item.active > .badge.badge-brown {
  background: #9c8061; }

span.label-dark-blue,
span.badge-dark-blue,
.list-group-item.active > .badge.badge-dark-blue {
  background: #4765a0; }

span.label-light-green,
span.badge-light-green,
.list-group-item.active > .badge.badge-light-green {
  background: #79d5b3; }

span.label-light,
span.badge-light,
.list-group-item.active > .badge.badge-light {
  color: #777;
  background: #ecf0f1; }

span.label-dark,
span.badge-dark,
.list-group-item.active > .badge.badge-dark {
  background: #555; }

/*Badge Lists*/
.badge-lists li {
  position: relative; }

.badge-lists span.badge {
  top: -10px;
  right: -6px;
  position: absolute; }

/*Badge Icons*/
.badge-lists.badge-icons span.badge {
  min-width: 12px;
  padding: 3px 6px; }

.badge-lists.badge-icons i {
  font-size: 18px;
  min-width: 25px; }

/*Badge Box v1*/
.badge-box-v1 a {
  color: #777;
  min-width: 40px;
  font-size: 18px;
  padding: 8px 9px;
  display: inline-block;
  border: solid 1px #eee; }

/*Badge Box v2*/
.badge-box-v2 a {
  color: #777;
  font-size: 12px;
  padding: 10px;
  min-width: 70px;
  text-align: center;
  display: inline-block;
  border: solid 1px #eee; }

.badge-box-v2 a i {
  font-size: 20px; }

/*General Badge Box*/
.badge-box-v1 a i,
.badge-box-v2 a i {
  display: block;
  margin: 1px auto 2px; }

.badge-box-v1 a:hover,
.badge-box-v2 a:hover {
  color: #555;
  border-color: #555;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }

/*Font Awesome Icon Styles*/
i.icon-custom {
  color: #555;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 5px;
  text-align: center;
  display: inline-block;
  border: solid 1px #555; }

i.icon-sm {
  width: 35px;
  height: 35px;
  font-size: 16px;
  line-height: 35px; }

i.icon-md {
  width: 55px;
  height: 55px;
  font-size: 22px;
  line-height: 55px; }

i.icon-lg {
  width: 60px;
  height: 60px;
  font-size: 31px;
  line-height: 60px;
  margin-bottom: 10px; }

i.icon-2x {
  font-size: 30px; }

i.icon-3x {
  font-size: 40px; }

i.icon-4x {
  font-size: 50px; }

/*Line Icons*/
i.icon-line {
  font-size: 17px; }

i.icon-sm.icon-line {
  font-size: 14px; }

i.icon-md.icon-line {
  font-size: 22px; }

i.icon-lg.icon-line {
  font-size: 28px; }

i.icon-2x.icon-line {
  font-size: 27px; }

i.icon-3x.icon-line {
  font-size: 36px; }

i.icon-4x.icon-line {
  font-size: 47px; }

/*Icon Styles For Links*/
.link-icon,
.link-bg-icon {
  color: #555; }

.link-icon:hover,
.link-bg-icon:hover {
  border: none;
  text-decoration: none; }

.link-icon:hover i {
  color: #72c02c;
  background: none;
  border: solid 1px #72c02c; }

.link-bg-icon:hover i {
  color: #72c02c;
  background: #72c02c;
  border-color: #72c02c;
  color: #fff !important; }

/*Icons Color*/
i.icon-color-u,
i.icon-color-red,
i.icon-color-sea,
i.icon-color-dark,
i.icon-color-grey,
i.icon-color-blue,
i.icon-color-green,
i.icon-color-yellow,
i.icon-color-orange,
i.icon-color-purple,
i.icon-color-aqua,
i.icon-color-brown,
i.icon-color-dark-blue,
i.icon-color-light-grey,
i.icon-color-light-green {
  background: none; }

i.icon-color-u {
  color: #72c02c;
  border: solid 1px #72c02c; }

i.icon-color-blue {
  color: #3498db;
  border: solid 1px #3498db; }

i.icon-color-red {
  color: #e74c3c;
  border: solid 1px #e74c3c; }

i.icon-color-sea {
  color: #1abc9c;
  border: solid 1px #1abc9c; }

i.icon-color-green {
  color: #2ecc71;
  border: solid 1px #2ecc71; }

i.icon-color-yellow {
  color: #f1c40f;
  border: solid 1px #f1c40f; }

i.icon-color-orange {
  color: #e67e22;
  border: solid 1px #e67e22; }

i.icon-color-grey {
  color: #95a5a6;
  border: solid 1px #95a5a6; }

i.icon-color-purple {
  color: #9b6bcc;
  border: solid 1px #9b6bcc; }

i.icon-color-aqua {
  color: #27d7e7;
  border: solid 1px #27d7e7; }

i.icon-color-brown {
  color: #9c8061;
  border: solid 1px #9c8061; }

i.icon-color-dark-blue {
  color: #4765a0;
  border: solid 1px #4765a0; }

i.icon-color-light-green {
  color: #79d5b3;
  border: solid 1px #79d5b3; }

i.icon-color-light {
  color: #fff;
  border: solid 1px #fff; }

i.icon-color-light-grey {
  color: #585f69;
  border: solid 1px #585f69; }

/*Icons Backgroun Color*/
i.icon-bg-u,
i.icon-bg-red,
i.icon-bg-sea,
i.icon-bg-dark,
i.icon-bg-darker,
i.icon-bg-grey,
i.icon-bg-blue,
i.icon-bg-green,
i.icon-bg-yellow,
i.icon-bg-orange,
i.icon-bg-purple,
i.icon-bg-aqua,
i.icon-bg-brown,
i.icon-bg-dark-blue,
i.icon-bg-light-grey,
i.icon-bg-light-green {
  color: #fff;
  border-color: transparent; }

i.icon-bg-u {
  background: #72c02c; }

i.icon-bg-blue {
  background: #3498db; }

i.icon-bg-red {
  background: #e74c3c; }

i.icon-bg-sea {
  background: #1abc9c; }

i.icon-bg-green {
  background: #2ecc71; }

i.icon-bg-yellow {
  background: #f1c40f; }

i.icon-bg-orange {
  background: #e67e22; }

i.icon-bg-grey {
  background: #95a5a6; }

i.icon-bg-dark {
  background: #555; }

i.icon-bg-darker {
  background: #333; }

i.icon-bg-purple {
  background: #9b6bcc; }

i.icon-bg-aqua {
  background: #27d7e7; }

i.icon-bg-brown {
  background: #9c8061; }

i.icon-bg-dark-blue {
  background: #4765a0; }

i.icon-bg-light-green {
  background: #79d5b3; }

i.icon-bg-light {
  background: #fff;
  border-color: transparent; }

i.icon-bg-light-grey {
  background: #585f69;
  border-color: transparent; }

/* Make Font Awesome icons fixed width */
.fa-fixed [class^="fa"],
.fa-fixed [class*=" fa"] {
  width: 1.25em;
  text-align: center;
  display: inline-block; }

.fa-fixed [class^="fa"].fa-lg,
.fa-fixed [class*=" fa"].fa-lg {
  /* increased font size for fa-lg */
  width: 1.5625em; }

/*Content Boxes
------------------------------------*/
/*Content Boxes v1*/
.content-boxes-v1 {
  text-align: center; }

.content-boxes-v1 span {
  display: block;
  margin-top: 5px; }

/*Content Boxes v2*/
.content-boxes-v2-o span {
  display: block;
  overflow: hidden; }

.content-boxes-v2-o small {
  display: block;
  line-height: 1.6; }

.content-boxes-v2-o .icon-md + span {
  margin-top: 9px; }

.content-boxes-v2-o .icon-lg + span {
  margin-top: 11px; }

.content-boxes-v2-o .icon-custom {
  float: left;
  margin-top: 3px;
  margin-right: 15px; }

@media (max-width: 992px) {
  .content-boxes-v2,
  .content-boxes-v2 .text-justify {
    text-align: center; }
  .content-boxes-v2 span {
    display: block;
    margin-top: 5px; } }

/*Content Boxes v3*/
.content-boxes-v3 i.icon-custom {
  top: 8px;
  float: left;
  position: relative; }

.content-boxes-v3 .content-boxes-in-v3 {
  padding: 0 20px;
  overflow: hidden; }

.content-boxes-v3 .content-boxes-in-v3 h3 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 3px;
  text-transform: capitalize; }

.content-boxes-v3 .content-boxes-in-v3 h3 a {
  color: #555; }

/*Content Boxes Right v3*/
.content-boxes-v3.content-boxes-v3-right {
  text-align: right; }

.content-boxes-v3.content-boxes-v3-right i.icon-custom {
  float: right; }

@media (max-width: 768px) {
  .content-boxes-v3.content-boxes-v3-right {
    text-align: inherit; }
  .content-boxes-v3.content-boxes-v3-right i.icon-custom {
    float: left;
    margin-left: 0; } }

/*Content Boxes v4*/
.content-boxes-v4 h2 {
  color: #555;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase; }

.content-boxes-v4 a {
  color: #777;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase; }

.content-boxes-v4 i {
  width: 25px;
  color: #72c02c;
  font-size: 35px;
  margin-top: 10px; }

.content-boxes-in-v4 {
  padding: 0 10px;
  overflow: hidden; }

.content-boxes-v4-sm i {
  font-size: 26px;
  margin-top: 10px;
  margin-right: 5px; }

/*Content Boxes v5*/
.content-boxes-v5 i {
  float: left;
  color: #999;
  width: 50px;
  height: 50px;
  padding: 11px;
  font-size: 22px;
  background: #eee;
  line-height: 28px;
  text-align: center;
  margin-right: 15px;
  display: inline-block; }

.content-boxes-v5:hover i {
  color: #fff;
  background: #72c02c; }

/*Content Boxes v6*/
.content-boxes-v6 {
  padding-top: 25px;
  text-align: center; }

.content-boxes-v6 i {
  color: #fff;
  width: 90px;
  height: 90px;
  padding: 30px;
  font-size: 30px;
  line-height: 30px;
  position: relative;
  text-align: center;
  background: #dedede;
  margin-bottom: 25px;
  display: inline-block; }

.content-boxes-v6 i:after {
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  content: " ";
  position: absolute;
  border: 1px solid #dedede;
  border-radius: 50% !important; }

.content-boxes-v6:hover i,
.content-boxes-v6:hover i:after {
  transition: all 0.3s ease-in-out; }

.content-boxes-v6:hover i {
  background: #72c02c; }

.content-boxes-v6:hover i:after {
  border-color: #72c02c; }

/*Colored Content Boxes
------------------------------------*/
.service-block {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 20px; }

.service-block p,
.service-block h2 {
  color: #fff; }

.service-block h2 a:hover {
  text-decoration: none; }

.service-block-light,
.service-block-default {
  background: #fafafa;
  border: solid 1px #eee; }

.service-block-default:hover {
  box-shadow: 0 0 8px #eee; }

.service-block-light p,
.service-block-light h2,
.service-block-default p,
.service-block-default h2 {
  color: #555; }

.service-block-u {
  background: #72c02c; }

.service-block-blue {
  background: #3498db; }

.service-block-red {
  background: #e74c3c; }

.service-block-sea {
  background: #1abc9c; }

.service-block-grey {
  background: #95a5a6; }

.service-block-yellow {
  background: #f1c40f; }

.service-block-orange {
  background: #e67e22; }

.service-block-green {
  background: #2ecc71; }

.service-block-purple {
  background: #9b6bcc; }

.service-block-aqua {
  background: #27d7e7; }

.service-block-brown {
  background: #9c8061; }

.service-block-dark-blue {
  background: #4765a0; }

.service-block-light-green {
  background: #79d5b3; }

.service-block-dark {
  background: #555; }

.service-block-light {
  background: #ecf0f1; }

/*Funny Boxes
------------------------------------*/
.funny-boxes {
  background: #f7f7f7;
  padding: 20px 20px 15px;
  transition: all 0.3s ease-in-out; }

.funny-boxes h2 {
  margin-top: 0;
  font-size: 18px;
  line-height: 20px; }

.funny-boxes h2 a {
  color: #555; }

.funny-boxes p a {
  color: #72c02c; }

.funny-boxes .funny-boxes-img li {
  font-size: 12px;
  margin-bottom: 2px; }

.funny-boxes .funny-boxes-img li i {
  color: #72c02c;
  font-size: 12px;
  margin-right: 5px; }

@media (max-width: 992px) {
  .funny-boxes .funny-boxes-img li {
    display: inline-block; } }

.funny-boxes .funny-boxes-img img {
  margin: 5px 10px 15px 0; }

.funny-boxes ul.funny-boxes-rating li {
  display: inline-block; }

.funny-boxes ul.funny-boxes-rating li i {
  color: #f8be2c;
  cursor: pointer;
  font-size: 14px; }

.funny-boxes ul.funny-boxes-rating li i:hover {
  color: #f8be2c; }

/*Funny Colored Boxes*/
.funny-boxes-colored p,
.funny-boxes-colored h2 a,
.funny-boxes-colored .funny-boxes-img li,
.funny-boxes-colored .funny-boxes-img li i {
  color: #fff; }

/*Red Funny Box*/
.funny-boxes-red {
  background: #e74c3c; }

/*Dark Red Funny Box*/
.funny-boxes-purple {
  background: #9b6bcc; }

/*Blue Funny Box*/
.funny-boxes-blue {
  background: #3498db; }

/*Grey Funny Box*/
.funny-boxes-grey {
  background: #95a5a6; }

/*Turquoise Funny Box*/
.funny-boxes-sea {
  background: #1abc9c; }

/*Turquoise Top Bordered Funny Box*/
.funny-boxes-top-sea {
  border-top: solid 2px #1abc9c; }

.funny-boxes-top-sea:hover {
  border-top-color: #16a085; }

/*Yellow Top Bordered Funny Box**/
.funny-boxes-top-yellow {
  border-top: solid 2px #f1c40f; }

.funny-boxes-top-yellow:hover {
  border-top-color: #f39c12; }

/*Red Top Bordered Funny Box**/
.funny-boxes-top-red {
  border-top: solid 2px #e74c3c; }

.funny-boxes-top-red:hover {
  border-top-color: #c0392b; }

/*Purple Top Bordered Funny Box**/
.funny-boxes-top-purple {
  border-top: solid 2px #9b6bcc; }

.funny-boxes-top-purple:hover {
  border-top-color: #814fb5; }

/*Orange Left Bordered Funny Box**/
.funny-boxes-left-orange {
  border-left: solid 2px #e67e22; }

.funny-boxes-left-orange:hover {
  border-left-color: #d35400; }

/*Green Left Bordered Funny Box**/
.funny-boxes-left-green {
  border-left: solid 2px #72c02c; }

.funny-boxes-left-green:hover {
  border-left-color: #5fb611; }

/*Blue Left Bordered Funny Box**/
.funny-boxes-left-blue {
  border-left: solid 2px #3498db; }

.funny-boxes-left-blue:hover {
  border-left-color: #2980b9; }

/*Dark Left Bordered Funny Box**/
.funny-boxes-left-dark {
  border-left: solid 2px #555; }

.funny-boxes-left-dark:hover {
  border-left-color: #333; }

/*Typography
------------------------------------*/
.text-justify p {
  text-align: justify; }

.text-transform-uppercase {
  text-transform: uppercase; }

.text-transform-normal {
  text-transform: inherit !important; }

.font-bold {
  font-weight: 600; }

.font-light {
  font-weight: 200; }

.font-normal {
  font-weight: 400 !important; }

/*Text Dropcap*/
.dropcap {
  float: left;
  color: #72c02c;
  padding: 5px 0;
  font-size: 45px;
  font-weight: 200;
  line-height: 30px;
  margin: 0px 5px 0 0; }

.dropcap-bg {
  float: left;
  color: #fff;
  padding: 7px 0;
  min-width: 50px;
  font-size: 35px;
  font-weight: 200;
  line-height: 35px;
  text-align: center;
  background: #72c02c;
  margin: 4px 10px 0 0; }

/*Text Highlights*/
.text-highlights {
  color: #fff;
  font-weight: 200;
  padding: 0px 5px;
  background: #555; }

.text-highlights-green {
  background: #72c02c; }

.text-highlights-blue {
  background: #3498db; }

.text-highlights-red {
  background: #e74c3c; }

.text-highlights-sea {
  background: #1abc9c; }

.text-highlights-orange {
  background: #e67e22; }

.text-highlights-yellow {
  background: #f1c40f; }

.text-highlights-purple {
  background: #9b6bcc; }

.text-highlights-aqua {
  background: #27d7e7; }

.text-highlights-brown {
  background: #9c8061; }

.text-highlights-dark-blue {
  background: #4765a0; }

.text-highlights-light-green {
  background: #79d5b3; }

/*Text Borders*/
.text-border {
  border-bottom: dashed 1px #555; }

.text-border-default {
  border-color: #95a5a6; }

.text-border-green {
  border-color: #72c02c; }

.text-border-blue {
  border-color: #3498db; }

.text-border-red {
  border-color: #e74c3c; }

.text-border-yellow {
  border-color: #f1c40f; }

.text-border-purple {
  border-color: #9b6bcc; }

/*List Styles*/
.list-styles li {
  margin-bottom: 8px; }

/*Contextual Backgrounds*/
.contex-bg p {
  opacity: 0.8;
  padding: 8px 10px; }

.contex-bg p:hover {
  opacity: 1; }

/*Blockquote*/
blockquote {
  padding: 5px 15px;
  border-left-width: 2px; }

blockquote p {
  font-size: 14px;
  font-weight: 400; }

blockquote h1,
blockquote h2,
blockquote span {
  font-size: 18px;
  margin: 0 0 8px;
  line-height: 24px; }

/*Blockquote Styles*/
blockquote.bq-text-lg p,
blockquote.bq-text-lg small {
  text-transform: uppercase; }

blockquote.bq-text-lg p {
  font-size: 22px;
  font-weight: 300;
  line-height: 32px; }

blockquote.text-right,
blockquote.hero.text-right {
  border-left: none;
  border-right: 2px solid #eee; }

blockquote.hero.text-right,
blockquote.hero.text-right:hover {
  border-color: #555; }

blockquote:hover,
blockquote.text-right:hover {
  border-color: #72c02c;
  transition: all 0.4s ease-in-out; }

blockquote.bq-dark,
blockquote.bq-dark:hover {
  border-color: #585f69; }

blockquote.bq-green {
  border-color: #72c02c; }

/*Blockquote Hero Styles*/
blockquote.hero {
  border: none;
  padding: 18px;
  font-size: 16px;
  background: #f3f3f3;
  border-left: solid 2px #666; }

blockquote.hero:hover {
  background: #eee;
  border-left-color: #666; }

blockquote.hero.hero-dark,
blockquote.hero.hero-default {
  border: none; }

blockquote.hero.hero-dark {
  background: #444; }

blockquote.hero.hero-dark:hover {
  background: #555; }

blockquote.hero.hero-default {
  background: #72c02c; }

blockquote.hero.hero-default:hover {
  background: #5fb611; }

blockquote.hero.hero-dark p,
blockquote.hero.hero-dark h2,
blockquote.hero.hero-dark small,
blockquote.hero.hero-default p,
blockquote.hero.hero-default h2,
blockquote.hero.hero-default small {
  color: #fff;
  font-weight: 200; }

/*Alerts
------------------------------------*/
.alert {
  border-radius: 0; }

/*Alerts
------------------------------------*/
.thumbnail {
  border-radius: 0; }

/*Tag Boxes
------------------------------------*/
.tag-box {
  padding: 20px;
  background: #fff;
  margin-bottom: 30px; }

.tag-box h2 {
  font-size: 20px;
  line-height: 25px; }

.tag-box p {
  margin-bottom: 0; }

.tag-box.tag-text-space p {
  margin-bottom: 10px; }

/*Tag Boxes v1*/
.tag-box-v1 {
  border: solid 1px #eee;
  border-top: solid 2px #72c02c; }

/*Tag Boxes v2*/
.tag-box-v2 {
  background: #fafafa;
  border: solid 1px #eee;
  border-left: solid 2px #72c02c; }

/*Tag Boxes v3*/
.tag-box-v3 {
  border: solid 2px #eee; }

/*Tag Boxes v4*/
.tag-box-v4 {
  border: dashed 1px #bbb; }

/*Tag Boxes v5*/
.tag-box-v5 {
  margin: 20px 0;
  text-align: center;
  border: dashed 1px #ccc; }

.tag-box-v5 span {
  color: #555;
  font-size: 28px;
  margin-bottom: 0; }

/*Tag Boxes v6*/
.tag-box-v6 {
  background: #fafafa;
  border: solid 1px #eee; }

/*Tag Boxes v7*/
.tag-box-v7 {
  border: solid 1px #eee;
  border-bottom: solid 2px #72c02c; }

/*Testimonials
------------------------------------*/
/*Testimonials*/
.testimonials {
  margin-bottom: 10px; }

.testimonials .testimonial-info {
  color: #72c02c;
  font-size: 16px;
  padding: 0 15px;
  margin-top: 18px; }

.testimonials .testimonial-info span {
  top: 3px;
  position: relative; }

.testimonials .testimonial-info em {
  color: #777;
  display: block;
  font-size: 13px; }

.testimonials .testimonial-info img {
  width: 60px;
  float: left;
  height: 60px;
  padding: 2px;
  margin-right: 15px;
  border: solid 1px #ccc; }

.testimonials .testimonial-author {
  overflow: hidden; }

.testimonials .carousel-arrow {
  top: -65px;
  position: relative; }

.testimonials .carousel-arrow i {
  color: #777;
  padding: 2px;
  min-width: 25px;
  font-size: 20px;
  text-align: center;
  background: #f5f5f5; }

.testimonials .carousel-arrow i:hover {
  color: #fff;
  background: #72c02c; }

.testimonials .carousel-control {
  opacity: 1;
  width: 100%;
  text-align: right;
  text-shadow: none;
  position: absolute;
  filter: Alpha(opacity=100);
  /*For IE*/ }

.testimonials .carousel-control.left {
  right: 27px;
  left: auto; }

.testimonials .carousel-control.right {
  right: 0px; }

/*Testimonials v1*/
.testimonials.testimonials-v1 .item p {
  position: relative; }

.testimonials.testimonials-v1 .item p:after,
.testimonials.testimonials-v1 .item p:before {
  left: 80px;
  bottom: -20px; }

.testimonials.testimonials-v1 .item p:after {
  border-top: 22px solid;
  border-left: 0 solid transparent;
  border-right: 22px solid transparent; }

/*Testimonials v2*/
.testimonials.testimonials-v2 .testimonial-info {
  padding: 0 20px; }

.testimonials.testimonials-v2 p {
  padding-bottom: 15px; }

.testimonials.testimonials-v2 .carousel-arrow {
  top: -55px; }

.testimonials.testimonials-v2 .item p:after,
.testimonials.testimonials-v2 .item p:before {
  left: 8%;
  bottom: 45px; }

.testimonials.testimonials-v2 .item p:after {
  border-top: 20px solid;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent; }

/*General Testimonials v1/v2*/
.testimonials.testimonials-v1 p,
.testimonials.testimonials-v2 p {
  padding: 15px;
  font-size: 14px;
  font-style: italic;
  background: #f5f5f5; }

.testimonials.testimonials-v1 .item p:after,
.testimonials.testimonials-v2 .item p:after {
  width: 0;
  height: 0;
  content: " ";
  display: block;
  position: absolute;
  border-top-color: #f5f5f5;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/ }

/*Testimonials Backgrounds*/
.testimonials-bg-dark .item p,
.testimonials-bg-default .item p {
  color: #fff;
  font-weight: 200; }

.testimonials-bg-dark .carousel-arrow i,
.testimonials-bg-default .carousel-arrow i {
  color: #fff; }

/*Testimonials Default*/
.testimonials-bg-default .item p {
  background: #72c02c; }

.testimonials.testimonials-bg-default .item p:after,
.testimonials.testimonials-bg-default .item p:after {
  border-top-color: #72c02c; }

.testimonials-bg-default .carousel-arrow i {
  background: #72c02c; }

.testimonials.testimonials-bg-default .carousel-arrow i:hover {
  background: #5fb611; }

/*Testimonials Dark*/
.testimonials-bg-dark .item p {
  background: #555; }

.testimonials.testimonials-bg-dark .item p:after,
.testimonials.testimonials-bg-dark .item p:after {
  border-top-color: #555; }

.testimonials-bg-dark .carousel-arrow i {
  color: #fff;
  background: #555; }

.testimonials.testimonials-bg-dark .carousel-arrow i:hover {
  background: #333; }

.testimonials.testimonials-bg-dark .testimonial-info {
  color: #555; }

/*Panels (Portlets)
------------------------------------*/
.panel,
.panel-heading,
.panel-group .panel {
  border-radius: 0; }

.panel-heading {
  color: #fff;
  padding: 5px 15px; }

/*Panel Table*/
.panel .table {
  margin-bottom: 0; }

/*Panel Unify*/
.panel-u {
  border-color: #72c02c; }

.panel-u > .panel-heading {
  background: #72c02c; }

/*Panel Blue*/
.panel-blue {
  border-color: #3498db; }

.panel-blue > .panel-heading {
  background: #3498db; }

/*Panel Red*/
.panel-red {
  border-color: #e74c3c; }

.panel-red > .panel-heading {
  background: #e74c3c; }

/*Panel Green*/
.panel-green {
  border-color: #2ecc71; }

.panel-green > .panel-heading {
  background: #2ecc71; }

/*Panel Sea*/
.panel-sea {
  border-color: #1abc9c; }

.panel-sea > .panel-heading {
  background: #1abc9c; }

/*Panel Orange*/
.panel-orange {
  border-color: #e67e22; }

.panel-orange > .panel-heading {
  background: #e67e22; }

/*Panel Yellow*/
.panel-yellow {
  border-color: #f1c40f; }

.panel-yellow > .panel-heading {
  background: #f1c40f; }

/*Panel Grey*/
.panel-grey {
  border-color: #95a5a6; }

.panel-grey > .panel-heading {
  background: #95a5a6; }

/*Panel Dark*/
.panel-dark {
  border-color: #555; }

.panel-dark > .panel-heading {
  background: #555; }

/*Panel Purple*/
.panel-purple {
  border-color: #9b6bcc; }

.panel-purple > .panel-heading {
  background: #9b6bcc; }

/*Panel Aqua*/
.panel-aqua {
  border-color: #27d7e7; }

.panel-aqua > .panel-heading {
  background: #27d7e7; }

/*Panel Brown*/
.panel-brown {
  border-color: #9c8061; }

.panel-brown > .panel-heading {
  background: #9c8061; }

/*Panel Dark Blue*/
.panel-dark-blue {
  border-color: #4765a0; }

.panel-dark-blue > .panel-heading {
  background: #4765a0; }

/*Panel Light Green*/
.panel-light-green {
  border-color: #79d5b3; }

.panel-light-green > .panel-heading {
  background: #79d5b3; }

/*Panel Default Dark*/
.panel-default-dark {
  border-color: #585f69; }

.panel-default-dark > .panel-heading {
  background: #585f69; }

/*Progress Bar
------------------------------------*/
.progress {
  border-radius: 0; }

.progress-u {
  box-shadow: none; }

.progress-u .progress-bar {
  box-shadow: none; }

/*progress-bar (sizes)*/
.progress-lg {
  height: 25px; }

.progress-lg p {
  padding-top: 3px; }

.progress-sm {
  height: 12px; }

.progress-xs {
  height: 7px; }

.progress-xxs {
  height: 3px; }

/*progress-bar (colors)*/
.progress {
  background: #e5e5e5; }

.progress-bar-u {
  background: #72c02c; }

.progress-bar-blue {
  background: #3498db; }

.progress-bar-orange {
  background: #e67e22; }

.progress-bar-red {
  background: #e74c3c; }

.progress-bar-purple {
  background: #9b6bcc; }

.progress-bar-aqua {
  background: #27d7e7; }

.progress-bar-brown {
  background: #9c8061; }

.progress-bar-dark-blue {
  background: #4765a0; }

.progress-bar-light-green {
  background: #79d5b3; }

.progress-bar-dark {
  background: #555; }

/*Progress Bar Animation
------------------------------------*/
.progress {
  position: relative; }

.progress .progress-bar {
  overflow: hidden;
  line-height: 20px;
  position: absolute; }

.progress-box .progress-bar {
  transition: all 3s ease-in;
  -o-transition: all 3s ease-in;
  -ms-transition: all 3s ease-in;
  -moz-transition: all 3s ease-in;
  -webkit-transition: all 3s ease-in; }

/*Vertical Progress Bar*/
.progress.vertical {
  float: left;
  width: 100%;
  height: 200px;
  margin-right: 20px; }

.progress.vertical.bottom {
  position: relative; }

.progress.vertical .progress-bar {
  height: 0;
  width: 100%;
  transition: height 3s ease;
  -o-transition: height 3s ease;
  -ms-transition: height 3s ease;
  -moz-transition: height 3s ease;
  -webkit-transition: height 3s ease; }

.progress.vertical.bottom .progress-bar {
  bottom: 0;
  position: absolute; }

/*Count Stars
------------------------------------*/
.stars-existing {
  color: #72c02c;
  cursor: pointer; }

.star-lg {
  font-size: 30px; }

.star-sm {
  font-size: 25px; }

.star-xs {
  font-size: 20px; }

.star-default {
  font-size: 16px; }

/*Media (Audio/Videos and Images)
------------------------------------*/
/*Images*/
img.img-bordered {
  padding: 3px !important;
  border: solid 1px #eee !important; }

img.img-circle {
  border-radius: 50% !important; }

img.image-sm {
  width: 50px;
  height: 50px; }

img.image-md {
  width: 100px;
  height: 100px; }

/*Responsive Video*/
.responsive-video {
  height: 0;
  padding-top: 1px;
  position: relative;
  padding-bottom: 56.25%;
  /*16:9*/ }

.responsive-video iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute; }

/*Tags v1
------------------------------------*/
.tags-v1 li {
  margin: 0;
  padding: 0; }

.tags-v1 li a {
  font-size: 13px;
  padding: 4px 8px;
  line-height: 32px;
  border: solid 2px #eee;
  border-radius: 20px !important;
  transition: all 0.2s ease-in-out; }

.tags-v1 li a:hover {
  text-decoration: none;
  border-color: #e0e0e0; }

/*Tags v2
------------------------------------*/
.tags-v2 li {
  padding: 7px 0 7px 4px; }

.tags-v2 li a {
  color: #555;
  font-size: 13px;
  padding: 5px 10px;
  border: solid 1px #bbb; }

.tags-v2 li a:hover {
  color: #fff;
  background: #555;
  border-color: #555;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }

/*Tags v3
------------------------------------*/
.tags-v3 li {
  padding: 7px 0 7px 4px; }

.tags-v3 li a {
  color: #555;
  font-size: 12px;
  padding: 4px 12px;
  border: solid 1px #eee; }

.tags-v3 li a:hover {
  color: #fff;
  background: #222;
  border-color: #222;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }

/*Tags v4
------------------------------------*/
.tags-v4 {
  padding-left: 0;
  list-style: none; }

.tags-v4 li {
  padding: 3px 2px;
  line-height: 28px;
  display: inline-block; }

.tags-v4 a {
  color: #bbb;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 10px;
  border: 1px solid #bbb; }

.tags-v4 a:hover {
  color: #fff;
  background: #e74c3c;
  border-color: #e74c3c;
  text-decoration: none;
  transition: all 0.25s, transform 0.25s; }

/*Lists
------------------------------------*/
.list-row {
  padding: 0;
  margin-bottom: 0;
  list-style: none; }

/*Lists v1*/
.lists-v1 li {
  margin-bottom: 10px; }

.lists-v1 i {
  color: #fff;
  width: 15px;
  height: 15px;
  padding: 1px;
  font-size: 13px;
  margin-right: 7px;
  text-align: center;
  background: #72c02c;
  display: inline-block;
  border-radius: 50% !important; }

/*Lists v2*/
.lists-v2 li {
  margin-bottom: 10px; }

.lists-v2 i {
  color: #72c02c;
  font-size: 13px;
  margin-right: 7px;
  display: inline-block; }

/*Column Sizes
------------------------------------*/
/*Remove the Gutter Padding from Columns*/
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

.no-gutter.no-gutter-boxed {
  padding-right: 15px;
  padding-left: 15px; }

/*Heights
------------------------------------*/
.height-100 {
  min-height: 100px; }

.height-150 {
  min-height: 150px; }

.height-200 {
  min-height: 200px; }

.height-250 {
  min-height: 250px; }

.height-300 {
  min-height: 300px; }

.height-350 {
  min-height: 350px; }

.height-400 {
  min-height: 400px; }

.height-450 {
  min-height: 450px; }

.height-500 {
  min-height: 500px !important; }

/*Spaces
------------------------------------*/
.no-padding {
  padding: 0 !important; }

.no-margin {
  margin: 0; }

.no-top-space {
  margin-top: 0 !important;
  padding-top: 0 !important; }

.no-bottom-space {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.content-xs {
  padding-top: 20px;
  padding-bottom: 20px; }

.content {
  padding-top: 40px;
  padding-bottom: 40px; }

.content-sm {
  padding-top: 60px;
  padding-bottom: 60px; }

.content-md {
  padding-top: 80px;
  padding-bottom: 80px; }

.content-lg {
  padding-top: 100px;
  padding-bottom: 100px; }

.content-xlg {
  padding-top: 150px;
  padding-bottom: 150px; }

.space-md-hor {
  padding-left: 40px;
  padding-right: 40px; }

.space-lg-hor {
  padding-left: 60px;
  padding-right: 60px; }

.space-xlg-hor {
  padding-left: 100px;
  padding-right: 100px; }

.margin-bottom-5,
.margin-bottom-10,
.margin-bottom-15,
.margin-bottom-20,
.margin-bottom-25,
.margin-bottom-30,
.margin-bottom-35,
.margin-bottom-40,
.margin-bottom-45,
.margin-bottom-50,
.margin-bottom-55,
.margin-bottom-60,
.margin-bottom-100 {
  clear: both; }

.margin-bottom-5 {
  margin-bottom: 5px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-15 {
  margin-bottom: 15px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-25 {
  margin-bottom: 25px; }

.margin-bottom-30 {
  margin-bottom: 30px; }

.margin-bottom-35 {
  margin-bottom: 35px; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.margin-bottom-45 {
  margin-bottom: 45px; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.margin-bottom-55 {
  margin-bottom: 55px; }

.margin-bottom-60 {
  margin-bottom: 60px; }

.margin-bottom-100 {
  margin-bottom: 100px; }

@media (max-width: 768px) {
  .sm-margin-bottom-10 {
    margin-bottom: 10px; }
  .sm-margin-bottom-20 {
    margin-bottom: 20px; }
  .sm-margin-bottom-30 {
    margin-bottom: 30px; }
  .sm-margin-bottom-40 {
    margin-bottom: 40px; }
  .sm-margin-bottom-50 {
    margin-bottom: 50px; }
  .sm-margin-bottom-60 {
    margin-bottom: 60px; } }

@media (max-width: 992px) {
  .md-margin-bottom-10 {
    margin-bottom: 10px; }
  .md-margin-bottom-20 {
    margin-bottom: 20px; }
  .md-margin-bottom-30 {
    margin-bottom: 30px; }
  .md-margin-bottom-40 {
    margin-bottom: 40px; }
  .md-margin-bottom-50 {
    margin-bottom: 50px; }
  .md-margin-bottom-60 {
    margin-bottom: 60px; } }

/*Other Spaces*/
.margin-top-20 {
  margin-top: 20px; }

.margin-left-5 {
  margin-left: 5px; }

.margin-left-10 {
  margin-left: 10px; }

.margin-right-5 {
  margin-right: 5px; }

.margin-right-10 {
  margin-right: 10px; }

.margin-right-20 {
  margin-right: 20px; }

.padding-sm {
  padding: 40px 40px; }

.padding-top-5 {
  padding-top: 5px; }

.padding-left-5 {
  padding-left: 5px; }

/*Text Colors
------------------------------------*/
.color-sea {
  color: #1abc9c; }

.color-red {
  color: #e74c3c; }

.color-aqua {
  color: #27d7e7; }

.color-blue {
  color: #3498db; }

.color-grey {
  color: #95a5a6; }

.color-dark {
  color: #555; }

/*.color-dark1 { color: #444;}*/
.color-darker {
  color: #444; }

.color-green {
  color: #72c02c; }

.color-brown {
  color: #9c8061; }

.color-light {
  color: #ffffff; }

.color-orange {
  color: #e67e22; }

.color-yellow {
  color: #f1c40f; }

.color-green1 {
  color: #2ecc71; }

.color-purple {
  color: #9b6bcc; }

.color-inherit {
  color: inherit; }

.color-dark-blue {
  color: #4765a0; }

.color-light-grey {
  color: #585f69; }

.color-light-green {
  color: #79d5b3; }

/*Background Colors
------------------------------------*/
.bg-color-dark,
.bg-color-darker,
.bg-color-sea,
.bg-color-red,
.bg-color-aqua,
.bg-color-blue,
.bg-color-grey,
.bg-color-light,
.bg-color-green,
.bg-color-brown,
.bg-color-orange,
.bg-color-green1,
.bg-color-purple,
.bg-color-dark-blue,
.bg-color-light-grey,
.bg-color-light-green,
.bg-color-primary {
  color: #fff; }

.bg-color-white {
  color: #555; }

.bg-color-primary {
  background-color: #72c02c !important; }

.bg-color-dark {
  background-color: #555 !important; }

.bg-color-darker {
  background-color: #222 !important; }

.bg-color-white {
  background-color: #fff !important; }

.bg-color-sea {
  background-color: #1abc9c !important; }

.bg-color-red {
  background-color: #e74c3c !important; }

.bg-color-aqua {
  background-color: #27d7e7 !important; }

.bg-color-blue {
  background-color: #3498db !important; }

.bg-color-grey {
  background-color: #95a5a6 !important; }

.bg-color-light {
  background-color: #f7f7f7 !important; }

.bg-color-green {
  background-color: #72c02c !important; }

.bg-color-brown {
  background-color: #9c8061 !important; }

.bg-color-orange {
  background-color: #e67e22 !important; }

.bg-color-green1 {
  background-color: #2ecc71 !important; }

.bg-color-purple {
  background-color: #9b6bcc !important; }

.bg-color-dark-blue {
  background-color: #4765a0 !important; }

.bg-color-light-grey {
  background-color: #585f69 !important; }

.bg-color-light-green {
  background-color: #79d5b3 !important; }

.rgba-red {
  background-color: rgba(231, 76, 60, 0.8); }

.rgba-blue {
  background-color: rgba(52, 152, 219, 0.8); }

.rgba-aqua {
  background-color: rgba(39, 215, 231, 0.8); }

.rgba-yellow {
  background-color: rgba(241, 196, 15, 0.8); }

.rgba-default {
  background-color: rgba(114, 192, 44, 0.8); }

.rgba-purple {
  background-color: rgba(155, 107, 204, 0.8); }

/*Grey Backroud*/
.bg-grey {
  background: #f7f7f7;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee; }

/*Rounded and Circle Classes
------------------------------------*/
.no-rounded {
  border-radius: 0 !important; }

.rounded {
  border-radius: 4px !important; }

.rounded-x {
  border-radius: 50% !important; }

.rounded-2x {
  border-radius: 10px !important; }

.rounded-3x {
  border-radius: 15px !important; }

.rounded-4x {
  border-radius: 20px !important; }

.rounded-sm {
  border-radius: 2px !important; }

.rounded-md {
  border-radius: 3px !important; }

.rounded-top {
  border-radius: 4px 4px 0 0 !important; }

.rounded-left {
  border-radius: 4px 0 0 4px !important; }

.rounded-right {
  border-radius: 0 4px 4px 0 !important; }

.rounded-bottom {
  border-radius: 0 0 4px 4px !important; }

/*Others
------------------------------------*/
.overflow-h {
  overflow: hidden; }

.overflow-a {
  overflow: auto; }

.overflow-hidden {
  overflow: hidden; }

.clear-both {
  clear: both; }

/*Display*/
.dp-none {
  display: none; }

.dp-block {
  display: block; }

.dp-table {
  display: table; }

.dp-inline-block {
  display: inline-block; }

.dp-table-cell {
  display: table-cell;
  vertical-align: middle; }

/*Font Size*/
.text-light {
  font-weight: 200; }

.text-normal {
  font-weight: 400; }

.text-bold {
  font-weight: 600 !important; }

/*Full Width*/
.full-width {
  width: 100%; }

/*Equal Height Columns*/
@media (max-width: 767px) {
  .equal-height-column {
    height: auto !important; } }

/*Image Classes*/
.img-width-60 {
  width: 60px; }

.img-width-200 {
  width: 200px; }

.lft-img-margin {
  margin: 0 20px 5px 0; }

.rgt-img-margin {
  margin: 0 0 5px 10px; }

img.img-center,
.img-center img {
  margin-left: auto;
  margin-right: auto; }

/*Background Light*/
.bg-light {
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #fcfcfc;
  border: solid 1px #e5e5e5; }

.bg-light:hover {
  border: solid 1px #bbb; }

/*CSS3 Hover Effects*/
.hover-effect {
  transition: all 0.4s ease-in-out; }

.hover-effect-kenburn {
  left: 10px;
  margin-left: -10px;
  position: relative;
  transition: all 0.8s ease-in-out; }

.hover-effect-kenburn:hover {
  transform: scale(2) rotate(5deg); }

/*Scroll Icon*/
.scroll-btn-icon,
.scroll-btn-icon:before {
  position: absolute;
  left: 50%; }

.scroll-btn-icon {
  width: 30px;
  height: 50px;
  margin-left: -25px;
  bottom: 30px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 25px;
  z-index: 2; }

.scroll-btn-icon-dark {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5); }

.scroll-btn-icon:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll; }

.scroll-btn-icon-dark:before {
  background: #333; }

@keyframes scroll {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateY(36px); } }

/* Testimonials v3, v4 */
.testimonials-section3 .owl-stage-outer {
  margin-right: 1px; }

.testimonials-section3 .testimonials-item,
.testimonials-section4 .testimonials-item {
  border-right: solid 1px #d8dadd;
  padding: 15px 15px 20px; }

.testimonials-section4 .testimonials-item:last-child {
  border-right: none; }

.testimonials-section3 img,
.testimonials-section4 img {
  width: 74px !important;
  height: 74px !important;
  border-radius: 50%;
  background: #fff;
  padding: 3px;
  border: solid 1px #E6E6E6;
  margin: 0 auto 5px;
  display: block; }

.testimonials-section3 blockquote,
.testimonials-section4 blockquote {
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.5;
  margin-bottom: 20px;
  border: none; }

.testimonials-section3 blockquote p,
.testimonials-section4 blockquote p {
  z-index: 9;
  position: relative;
  margin-bottom: 5px; }

.testimonials-section3 .testimonials-item strong,
.testimonials-section3 .testimonials-item em,
.testimonials-section4 .testimonials-item strong,
.testimonials-section4 .testimonials-item em {
  display: block;
  font-size: 14px;
  font-weight: 700; }

.testimonials-section3 .testimonials-item strong,
.testimonials-section4 .testimonials-item strong {
  padding-top: 10px; }

.testimonials-section3 .testimonials-item em,
.testimonials-section4 .testimonials-item em {
  font-style: normal;
  opacity: 0.7;
  font-weight: 400; }

/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.8
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Import Global Compulsory CSS Files*/
/*@import url(_one-demo.scss);*/
/*@import url(one-_style.scss);*/
/*Import CSS Plugins*/
/*Import sections
// @import 'style/promo';
 */
body {
  color: #333;
  font-size: 13px;
  line-height: 1.6; }

body.header-fixed-space {
  padding-top: 94px; }

body.header-fixed-space-v2 {
  padding-top: 135px; }

@media (max-width: 991px) {
  body.header-fixed-space,
  body.header-fixed-space-v2 {
    padding-top: 0; } }

p,
li,
li a,
label {
  color: #555; }

a {
  color: #72c02c;
  text-decoration: none; }

a,
a:focus,
a:hover,
a:active {
  outline: 0 !important; }

a:focus {
  text-decoration: none; }

a:hover {
  color: #72c02c;
  text-decoration: underline; }

/*Intro Page
------------------------------------*/
.intro-page-link {
  color: #555;
  font-weight: bold;
  text-transform: uppercase; }

.intro-page-link:hover {
  color: #222;
  text-decoration: none; }

/*Main Styles
------------------------------------*/
/*Purchase Block*/
/*Service*/
.service {
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px 15px 10px; }

.service:hover {
  background: #fcfcfc;
  box-shadow: 0 0 5px #ddd;
  transition: box-shadow 0.2s ease-in-out; }

.service:hover i {
  color: #656565; }

.service .desc {
  padding: 0 15px;
  overflow: hidden; }

.service .desc h4 {
  font-size: 22px;
  line-height: 25px; }

.service .service-icon {
  float: left;
  padding: 10px;
  color: #72c02c;
  font-size: 35px;
  text-align: center;
  transition: all 0.4s ease-in-out; }

.service .service-icon.icon-cogs {
  font-size: 37px; }

.service .service-icon.icon-plane {
  font-size: 43px; }

/* Recent Work  */
.recent-work a {
  text-align: center;
  background: #fcfcfc;
  display: inline-block; }

.recent-work a:hover {
  text-decoration: none; }

.recent-work a:hover strong {
  color: #555; }

.recent-work em.overflow-hidden {
  display: block; }

.recent-work a span {
  display: block;
  padding: 10px;
  border-bottom: solid 2px #eee; }

.recent-work a strong {
  color: #555;
  display: block; }

.recent-work a i {
  color: #777; }

.recent-work a img {
  transition: all 0.8s ease-in-out; }

.recent-work a:hover img {
  opacity: 0.8;
  transform: scale(1.2) rotate(3deg); }

.recent-work a:hover span {
  border-bottom: solid 2px #72c02c;
  transition: all 0.3s ease-in-out; }

/* Work */
.work {
  margin-top: 6px; }

.work .details {
  padding: 10px; }

.work .caption {
  display: block;
  padding-top: 5px;
  color: #585f69 !important;
  font-size: 18px !important; }

.work .caption:hover {
  cursor: pointer;
  color: #444 !important;
  text-decoration: underline; }

/*Other Footer Common Classes
------------------------------------*/
/*Thumb Headline*/
.thumb-headline h2 {
  color: #eee;
  font-size: 16px; }

/*Footer Logo*/
.footer-logo {
  margin: 17px 0 20px; }

/*Latest List*/
.latest-list li {
  padding: 8px 0;
  border-top: 1px solid #353535; }

.latest-list li:first-child {
  padding-top: 0;
  border-top: none; }

.latest-list li a {
  color: #eee; }

.latest-list small {
  color: #999;
  display: block; }

/*Link List*/
.link-list li {
  border-top: solid 1px #353535; }

.link-list li:first-child {
  border-top: none !important; }

.link-list a {
  color: #eee;
  font-size: 11px;
  padding: 6px 0px;
  display: inline-block;
  text-transform: uppercase; }

.link-list li i {
  color: #bbb;
  float: right;
  margin-top: 10px; }

/*Latest Tweets*/
.latest-tweets .latest-tweets-inner p {
  color: #555;
  font-size: 13px; }

.latest-tweets .latest-tweets-inner a {
  /*color: #3498db;*/
  text-decoration: none; }

.latest-tweets .latest-tweets-inner a:hover {
  text-decoration: underline; }

.latest-tweets .latest-tweets-inner i.fa {
  top: 2px;
  float: left;
  color: #bbb;
  color: #55acee;
  font-size: 18px;
  margin-right: 5px;
  position: relative; }

.latest-tweets .latest-tweets-inner small {
  color: #777;
  display: block; }

/*Social Icons*/
.social-icons {
  margin-top: 5px; }

.social-icons li {
  margin: 0; }

.social-icons li a {
  margin: 0 3px; }

/*Thumbnails
------------------------------------*/
a.thumbnail {
  padding: 0;
  border: none;
  margin-bottom: 15px; }

.thumbnail-style {
  padding: 7px;
  margin-bottom: 20px; }

.thumbnail-style:hover {
  box-shadow: 0 0 8px #ddd;
  transition: box-shadow 0.2s ease-in-out; }

.thumbnail h3,
.thumbnail-style h3 {
  margin: 6px 0 8px 0; }

.thumbnail h3 a,
.thumbnail-style h3 a {
  color: #585f69;
  font-size: 18px; }

.thumbnail h3 a:hover,
.thumbnail-style h3 a:hover {
  color: #72c02c;
  text-decoration: none; }

.thumbnail-style .thumbnail-img {
  position: relative;
  margin-bottom: 11px; }

.thumbnail-style a.btn-more {
  right: -10px;
  bottom: 10px;
  color: #fff;
  padding: 1px 6px;
  position: absolute;
  background: #72c02c;
  display: inline-block; }

.thumbnail-style a.btn-more:hover {
  text-decoration: none;
  box-shadow: 0 0 0 2px #5fb611; }

.thumbnail-style:hover a.btn-more {
  right: 10px; }

.thumbnail-kenburn img {
  left: 10px;
  margin-left: -10px;
  position: relative;
  transition: all 0.8s ease-in-out; }

.thumbnail-kenburn:hover img {
  transform: scale(1.2) rotate(2deg); }

/*Servive Blocks
------------------------------------*/
/*Service Alternative Block*/
.service-alternative .service:hover {
  background: #76ca2c;
  transition: all 0.4s ease-in-out; }

.service-alternative .service:hover i,
.service-alternative .service:hover p,
.service-alternative .service:hover h4 {
  color: #fff; }

/*Servive Block Versions*/
.service-v1 h2 {
  margin: 15px 0 10px;
  font-size: 20px;
  line-height: 28px; }

.service-v1 p {
  text-align: justify; }

/*Blog Posts
------------------------------------*/
.posts .dl-horizontal a {
  color: #555; }

.posts .dl-horizontal {
  margin-bottom: 15px;
  overflow: hidden; }

.posts .dl-horizontal dt {
  width: 60px;
  float: left; }

.posts .dl-horizontal dt img {
  width: 60px;
  height: 60px;
  padding: 2px;
  margin-top: 2px;
  border: solid 1px #ddd; }

.posts .dl-horizontal dd {
  margin-left: 70px; }

.posts .dl-horizontal dd p {
  margin: 0; }

.posts .dl-horizontal dd a {
  font-size: 14px;
  line-height: 16px !important; }

.posts .dl-horizontal dd a:hover {
  color: #72c02c;
  text-decoration: none; }

.posts .dl-horizontal:hover dt img,
.posts .dl-horizontal:hover dd a {
  color: #72c02c;
  border-color: #72c02c !important;
  transition: all 0.2s ease-in-out; }

/*About Page
------------------------------------*/
.team ul.team-socail {
  text-align: right; }

.team ul.team-socail li {
  margin: 0 !important;
  padding: 0 !important; }

.team ul.team-socail li a,
.team ul.team-socail li a:hover {
  text-decoration: none; }

.team ul.team-socail li i {
  color: #aaa;
  padding: 6px 7px;
  min-width: 30px;
  font-size: 18px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block; }

.team ul.team-socail li i:hover {
  color: #fff;
  background: #72c02c;
  transition: all 0.2s ease-in-out; }

.team .thumbnail-style {
  padding: 12px; }

.team .thumbnail-style img {
  margin-bottom: 7px; }

.team .thumbnail-style h3 {
  margin-bottom: 5px; }

.team .thumbnail-style:hover h3 a {
  color: #72c02c !important; }

.team .thumbnail-style small {
  display: block;
  margin-top: 5px;
  font-size: 12px; }

/*About Me Page
------------------------------------*/
.about-me {
  overflow: hidden; }

.about-me h2 {
  margin: 0;
  font-size: 20px;
  text-transform: uppercase; }

.about-me span {
  color: #999;
  display: block;
  font-size: 14px;
  margin-bottom: 10px; }

.about-me .social-icons {
  margin-top: 7px; }

/*About My Goals*/
.about-my-goals h2 {
  text-transform: uppercase; }

/*About Skills*/
/*Gallery Page
------------------------------------*/
.gallery-page .thumbnail,
.gallery-page .thumbnail:hover {
  padding: 0;
  border: none;
  box-shadow: none; }

.gallery-page .thumbnails {
  margin-bottom: 6px; }

/*Privacy Page
------------------------------------*/
.privacy a {
  text-decoration: underline; }

.privacy a:hover {
  color: #72c02c;
  text-decoration: none; }

/*Portfolio Item Page v1
------------------------------------*/
.portfolio-item h2 {
  color: #555;
  font-size: 24px;
  margin: 0 0 15px; }

.portfolio-item li {
  padding: 3px 0; }

.portfolio-item p a {
  text-decoration: underline; }

.portfolio-item p a:hover {
  text-decoration: none; }

/*Portfolio Item v1*/
.portfolio-item1 ul {
  margin: 10px 0 20px; }

.portfolio-item1 li {
  font-size: 16px;
  margin-bottom: 3px; }

.portfolio-item1 li i {
  font-size: 18px;
  margin-right: 4px; }

/*Sidebar Features
------------------------------------*/
/*Blog Tags*/
ul.blog-tags li {
  display: inline-block; }

ul.blog-tags a {
  font-size: 13px;
  padding: 2px 7px;
  background: #f7f7f7;
  margin: 0 3px 6px 0;
  display: inline-block; }

ul.blog-tags a i {
  color: #666; }

ul.blog-tags a:hover {
  background: #72c02c; }

ul.blog-tags a:hover,
ul.blog-tags a:hover i {
  color: #fff !important;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }

/*Blog Photo Stream*/
.blog-photos li {
  display: inline; }

.blog-photos li img {
  width: 58px;
  height: 58px;
  margin: 0 2px 8px; }

.blog-photos li img:hover {
  box-shadow: 0 0 0 2px #72c02c; }

/*Blog Latest Tweets*/
.blog-twitter .blog-twitter-inner {
  padding: 10px;
  position: relative;
  background: #f7f7f7;
  margin-bottom: 10px; }

.blog-twitter .blog-twitter-inner,
.blog-twitter .blog-twitter-inner:after,
.blog-twitter .blog-twitter-inner:before {
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.blog-twitter .blog-twitter-inner:after,
.blog-twitter .blog-twitter-inner:before {
  width: 0;
  height: 0;
  right: 0px;
  bottom: 0px;
  content: " ";
  display: block;
  position: absolute; }

.blog-twitter .blog-twitter-inner:after {
  border-top: 15px solid #eee;
  border-right: 15px solid transparent;
  border-left: 0px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/ }

.blog-twitter .blog-twitter-inner:before {
  border-bottom: 15px solid #fff;
  border-right: 0 solid transparent;
  border-left: 15px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-bottom-style: inset;
  /*FF fixes*/ }

.blog-twitter .blog-twitter-inner:hover {
  border-color: #72c02c;
  border-top-color: #72c02c; }

.blog-twitter .blog-twitter-inner:hover:after {
  border-top-color: #72c02c; }

.blog-twitter .blog-twitter-inner span.twitter-time {
  color: #777;
  display: block;
  font-size: 11px; }

.blog-twitter .blog-twitter-inner a {
  color: #72c02c;
  text-decoration: none; }

.blog-twitter .blog-twitter-inner a:hover {
  text-decoration: underline; }

.blog-twitter .blog-twitter-inner i.fa {
  top: 2px;
  color: #bbb;
  font-size: 18px;
  position: relative; }

/*Choose Block*/
.who li {
  margin-bottom: 6px; }

.who li i {
  color: #72c02c;
  font-size: 13px;
  min-width: 15px;
  margin-right: 8px;
  text-align: center;
  position: relative; }

.who li:hover i,
.who li:hover a {
  color: #777; }

.who li:hover a:hover {
  text-decoration: none; }

/*Info Blocks*/
.info-blocks {
  margin-bottom: 15px; }

.info-blocks:hover i.icon-info-blocks {
  color: #72c02c;
  transition: all 0.2s ease-in-out; }

.info-blocks i.icon-info-blocks {
  float: left;
  color: #777;
  font-size: 30px;
  min-width: 50px;
  margin-top: 10px;
  text-align: center; }

.info-blocks .info-blocks-in {
  padding: 0 10px;
  overflow: hidden; }

.info-blocks .info-blocks-in h3 {
  color: #555;
  font-size: 20px;
  line-height: 28px; }

.info-blocks .info-blocks-in p {
  font-size: 12px; }

/*Brand Page
------------------------------------*/
.brand-page .btn {
  margin-bottom: 10px; }

/*Form Pages
------------------------------------*/
.form-page input,
.form-page select {
  margin-bottom: 10px; }

/*Labels and Badges Page
------------------------------------*/
ul.labels-demo {
  margin: 5px 0; }

ul.labels-demo li {
  list-style: none;
  display: inline-block;
  margin: 0 5px 2px 0; }

.badge-sidebar {
  border-left: solid 1px #eee; }

@media (max-width: 991px) {
  .badge-sidebar {
    border-left: none; } }

/*Line Icon Page
------------------------------------*/
.line-icon-page .item-box {
  width: 25%;
  float: left;
  height: 90px;
  text-align: center;
  padding: 20px 10px;
  margin: 0 -1px -1px 0;
  word-wrap: break-word;
  border: 1px solid #ddd; }

.line-icon-page .item-box--higher {
  height: 120px; }

@media (max-width: 768px) {
  .line-icon-page .item-box {
    height: 115px; } }

@media (max-width: 450px) {
  .line-icon-page .item-box {
    height: 130px; } }

.line-icon-page .item-box:hover {
  background: #fafafa; }

.line-icon-page .item span {
  display: block;
  font-size: 28px;
  margin-bottom: 10px; }

.line-icon-page .item:hover {
  color: #72c02c; }

/*Line Icon Page
------------------------------------*/
.icon-page-fa {
  margin-bottom: 20px; }

.icon-page-fa .item-box {
  float: left;
  height: 100px;
  width: 16% !important; }

.icon-page-fa .item {
  font-size: 12px; }

.icon-page-fa .item i {
  color: #555;
  display: block;
  font-size: 24px;
  margin-bottom: 10px; }

@media (max-width: 767px) {
  .icon-page-fa .item-box {
    width: 20% !important; } }

@media (max-width: 600px) {
  .icon-page-fa .item-box {
    width: 30% !important; } }

@media (max-width: 400px) {
  .icon-page-fa .item-box {
    width: 50% !important; } }

/*GLYPHICONS Icons Page
------------------------------------*/
/*Special styles for displaying the icons and their classes*/
.bs-glyphicons {
  padding-left: 0;
  list-style: none;
  overflow: hidden;
  padding-bottom: 1px;
  margin-bottom: 20px; }

.bs-glyphicons li {
  width: 25%;
  float: left;
  height: 115px;
  padding: 10px;
  font-size: 11px;
  line-height: 1.4;
  text-align: center;
  margin: 0 -1px -1px 0;
  border: 1px solid #ddd; }

.bs-glyphicons .glyphicon {
  display: block;
  font-size: 18px;
  margin: 5px auto 10px; }

.bs-glyphicons li:hover {
  color: #72c02c;
  background-color: #fafafa; }

@media (min-width: 768px) {
  .bs-glyphicons li {
    width: 12.5%; } }

/*Glyphicons Pro*/
.demo-pro-glyphicons li {
  width: 170px;
  height: 100px;
  padding: 20px 10px 30px; }

.demo-pro-glyphicons span {
  height: 40px;
  display: block; }

.demo-pro-glyphicons span:before {
  color: #555;
  padding: 10px 62px; }

.demo-pro-glyphicons span.glyphicons:before {
  font-size: 22px; }

/*Glyphicons Pro Halflings*/
.demo-pro-glyphicons span.halflings {
  height: 30px; }

.demo-pro-glyphicons span.halflings:before {
  padding: 10px 70px; }

/*Contact Pages
------------------------------------*/
.map {
  width: 100%;
  height: 350px;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee; }

.map-box {
  height: 250px; }

.map-box-space {
  margin-top: 15px; }

.map-box-space1 {
  margin-top: 7px; }

/*Background Opacity
------------------------------------*/
.service-or {
  overflow: hidden;
  position: relative; }

.service-bg {
  width: 250px;
  padding: 60px;
  position: absolute;
  top: -10px;
  right: -100px;
  background-color: rgba(255, 255, 255, 0.1);
  transform: rotate(55deg);
  -o-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  -moz-transform: rotate(55deg);
  -webkit-transform: rotate(55deg); }

/*Easy Block (used for image badges)
------------------------------------*/
.easy-block-v1 {
  position: relative; }

.easy-block-v1 img {
  width: 100%; }

.easy-block-v1 .overflow-h h3 {
  width: 60%;
  float: left;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px; }

.easy-block-v1 .easy-block-v1-badge {
  left: 0px;
  top: 10px;
  z-index: 1;
  color: #fff;
  padding: 4px 10px;
  position: absolute; }

/*Easy Block v2 (used for image badges)
------------------------------------*/
.easy-block-v2 {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px; }

.easy-block-v2 img {
  width: 100%; }

.easy-block-v2 .easy-bg-v2 {
  top: 5px;
  color: #fff;
  width: 100px;
  padding: 5px;
  right: -32px;
  font-size: 13px;
  position: absolute;
  text-align: center;
  letter-spacing: 2px;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/*Easy Block v3 (used for image badges)
------------------------------------*/
.easy-block-v3 {
  text-align: left;
  background: #585f69;
  padding: 30px 20px 20px; }

.easy-block-v3.first {
  background: #696f77; }

.easy-block-v3.second {
  background: #707780; }

.easy-block-v3.third {
  background: #787f88; }

.easy-block-v3 i {
  color: #fff;
  float: left;
  font-size: 30px;
  min-width: 45px;
  padding-top: 10px; }

.easy-block-v3 .inner-faq-b {
  overflow: hidden; }

.easy-block-v3 .inner-faq-b h2,
.easy-block-v3 .inner-faq-b h3 {
  font-size: 21px;
  margin-bottom: 8px; }

.easy-block-v3 .inner-faq-b h4 {
  color: #fff;
  font-size: 17px;
  margin-bottom: 2px; }

.easy-block-v3 .inner-faq-b h2,
.easy-block-v3 .inner-faq-b h3,
.easy-block-v3 .inner-faq-b p {
  color: #fff; }

/*Full Width Block
------------------------------------*/
.full-w-block {
  padding: 30px 0;
  background: #282828; }

.full-w-block .info-blocks i,
.full-w-block .info-blocks-in h3 {
  color: #fff; }

.full-w-block .info-blocks-in p {
  color: #ccc; }

.full-w-block .info-blocks:hover i.icon-info-blocks {
  color: #fff; }

/*Simple Block
------------------------------------*/
.simple-block {
  margin-bottom: 20px; }

.simple-block img {
  width: 100%; }

.simple-block p {
  text-align: center; }

.simple-block .carousel-indicators {
  top: 10px;
  left: 65%;
  text-align: right; }

.simple-block .carousel-indicators .active {
  background: #555; }

.simple-block .carousel-indicators li {
  border-color: #555; }

.simple-block .carousel-inner > .item {
  margin: 0; }

.simple-block .responsive-video {
  margin-bottom: 3px; }

/*Two Blocks
------------------------------------*/
.two-blocks {
  text-align: center; }

.two-blocks-in {
  margin-bottom: 30px; }

.two-blocks-in i {
  color: #fff;
  padding: 11px;
  font-size: 30px;
  min-width: 50px;
  text-align: center;
  background: #585f69;
  margin-bottom: 10px;
  display: inline-block; }

/*Three Blocks
------------------------------------*/
.three-blocks {
  text-align: center; }

.three-blocks-in {
  margin-bottom: 30px; }

.three-blocks-in i {
  color: #777;
  padding: 9px 6px;
  font-size: 20px;
  min-width: 40px;
  margin-bottom: 10px;
  border: 1px solid #777; }

.three-blocks-in h3 {
  font-size: 20px;
  margin-bottom: 7px; }

/*Temperory Classes
------------------------------------*/
/*Banner-info*/
.banner-info {
  margin-bottom: 10px; }

.banner-info i {
  float: left;
  color: #fff;
  padding: 11px;
  /*min-width works with line-icon*/
  min-width: 40px;
  font-size: 22px;
  text-align: center;
  margin: 7px 20px 0 0; }

/*min-width works with FontAwesome*/
.banner-info i.fa {
  min-width: 46px; }

.banner-info.light i {
  border: 1px solid #fff; }

.banner-info.dark i {
  background: #555; }

.banner-info h3 {
  font-size: 21px;
  margin-bottom: 5px; }

.banner-info.light h3 {
  color: #fff; }

.banner-info.light p {
  color: #eee; }

/*Title-Box-V2*/
.title-box-v2 {
  text-align: center;
  margin-bottom: 40px; }

.title-box-v2 h2 {
  font-size: 25px;
  text-transform: uppercase; }

.title-box-v2 p {
  font-size: 13px; }

/*remove padding from "Col-"*/
.no-space {
  padding: 0; }

.no-space-left {
  padding-left: 0;
  padding-right: 15px !important; }

.no-space-right {
  padding-right: 0;
  padding-left: 15px !important; }

/*List Style v1*/
.list-style-v1 li {
  margin-bottom: 7px; }

.list-style-v1 i {
  margin-right: 5px; }

/*Social Contacts
------------------------------------*/
.social-contacts li {
  padding: 5px 0;
  border-top: 1px solid #ddd; }

.social-contacts li:first-child {
  border-top: none; }

.social-contacts li i {
  color: #777;
  font-size: 13px;
  min-width: 13px;
  margin-right: 12px;
  text-align: center; }

/*Status Bar
------------------------------------*/
.status-bar li {
  padding-left: 9px;
  border-left: 1px solid #eee; }

.status-bar li:first-child {
  border-left: none; }

.status-bar li i {
  color: #72c02c;
  margin-right: 5px; }

.status-bar li a:hover {
  text-decoration: none; }

.status-bar-in {
  padding: 10px;
  position: relative;
  background: #f0f0f0; }

.status-bar-in:after {
  width: 0;
  height: 0;
  top: -10px;
  left: 50px;
  content: " ";
  display: block;
  position: absolute;
  border-bottom: 11px solid #f0f0f0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/ }

/*Share List*/
.share-list {
  margin-bottom: 0; }

.share-list li {
  padding-left: 20px; }

.share-list li:first-child {
  padding-left: 0; }

.share-list li i {
  color: #72c02c;
  margin-right: 5px; }

/*People Say
------------------------------------*/
.people-say img {
  float: left;
  width: 40px;
  height: auto;
  margin: 6px 10px 0 0; }

.people-say .overflow-h span {
  font-weight: 700; }

.people-say .overflow-h p {
  font-style: italic;
  line-height: 1.3; }

.people-say .overflow-h small {
  text-align: right;
  font-style: italic; }

/*Inspire Page
------------------------------------*/
img.img-mouse {
  width: 150px;
  height: auto;
  margin: 0 auto 60px; }

/*Desire Page
------------------------------------*/
/*Service Info*/
.service-info .info-description {
  overflow: hidden; }

.service-info i.service-info-icon {
  color: #777;
  float: left;
  width: 50px;
  height: 50px;
  padding: 15px;
  font-size: 20px;
  line-height: 17px;
  margin-right: 20px;
  text-align: center;
  display: inline-block;
  border: 1px solid #777; }

.service-info .info-description h3 {
  margin: 0 0 10px; }

.service-info .info-description p {
  color: #777; }

/*Portfolio Single Item Page
------------------------------------*/
/*Project Details*/
.project-details li {
  color: #555;
  font-weight: 200;
  margin-bottom: 5px; }

.project-details strong {
  min-width: 100px;
  display: inline-block; }

.project-details a {
  text-decoration: underline; }

/*Bootstrap Testimonials
------------------------------------*/
.testimonials-bs .headline-center-v2 span.author {
  color: #777;
  font-size: 14px; }

/*Removes Bootstrap carousel background images*/
.testimonials-bs .item {
  margin-bottom: 30px; }

.testimonials-bs .carousel-arrow {
  text-align: center; }

.testimonials-bs .carousel-control-v2 i {
  color: #888;
  width: 30px;
  height: 30px;
  padding: 2px;
  font-size: 22px;
  display: inline-block;
  border: 2px solid #888; }

.testimonials-bs .carousel-control-v2 i:hover {
  color: #72c02c;
  border-color: #72c02c;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.testimonials-bs .carousel-control-v2.left,
.testimonials-bs .carousel-control-v2.right {
  text-decoration: none;
  background-image: none; }

/*Team v1*/
.team-v1 li > h3 {
  color: #555; }

/**/
/* font */
/**/
/**/
/* defaults */
/**/
.sky-form {
  margin: 0;
  outline: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  font: 13px/1.55 'Open Sans', Helvetica, Arial, sans-serif;
  color: #666; }

.sky-form header {
  display: block;
  padding: 20px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
  font-size: 25px;
  font-weight: 300;
  color: #232323; }

.sky-form fieldset {
  display: block;
  padding: 25px 30px 5px;
  border: none;
  background: rgba(255, 255, 255, 0.9); }

.sky-form fieldset + fieldset {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.sky-form section {
  margin-bottom: 20px; }

.sky-form footer {
  display: block;
  padding: 15px 30px 25px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9); }

.sky-form footer:after {
  content: '';
  display: table;
  clear: both; }

.sky-form a {
  color: #2da5da; }

.sky-form .label {
  display: block;
  margin-bottom: 6px;
  line-height: 19px;
  font-weight: 400; }

.sky-form .label.col {
  margin: 0;
  padding-top: 10px; }

.sky-form .note {
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  line-height: 15px;
  color: #999; }

.sky-form .input,
.sky-form .select,
.sky-form .textarea,
.sky-form .radio,
.sky-form .checkbox,
.sky-form .toggle,
.sky-form .button {
  position: relative;
  display: block; }

.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: 39px;
  padding: 6px 10px;
  outline: none;
  border-width: 2px;
  border-style: solid;
  border-radius: 0;
  font: 15px/23px 'Open Sans', Helvetica, Arial, sans-serif;
  color: #404040;
  appearance: normal;
  -moz-appearance: none;
  -webkit-appearance: none; }

.sky-form .progress {
  float: right;
  margin-top: 10px;
  line-height: 39px;
  color: #232323; }

.sky-form button::-moz-focus-inner {
  padding: 0;
  border: 0; }

/**/
/* captcha inputs */
/**/
.sky-form .input-captcha img {
  position: absolute;
  top: 2px;
  right: 2px;
  border-left: 1px solid #e5e5e5; }

/**/
/* file inputs */
/**/
.sky-form .input-file .button {
  position: absolute;
  top: 4px;
  right: 4px;
  float: none;
  height: 31px;
  margin: 0;
  padding: 0 20px;
  font-size: 13px;
  line-height: 31px; }

.sky-form .input-file .button:hover {
  box-shadow: none; }

.sky-form .input-file .button input {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  font-size: 30px;
  cursor: pointer;
  opacity: 0; }

/**/
/* selects */
/**/
.sky-form .select i {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 28px;
  height: 35px;
  background: #fff;
  pointer-events: none; }

.sky-form .select i:after,
.sky-form .select i:before {
  content: '';
  position: absolute;
  right: 10px;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.sky-form .select i:after {
  bottom: 12px;
  border-top: 4px solid #404040; }

.sky-form .select i:before {
  top: 12px;
  border-bottom: 4px solid #404040; }

.sky-form .select select {
  padding-right: 28px; }

.sky-form .select-multiple select {
  height: auto; }

/**/
/* textareas */
/**/
.sky-form .textarea textarea {
  height: auto;
  resize: none; }

.sky-form .textarea-resizable textarea {
  resize: vertical; }

.sky-form .textarea-expandable textarea {
  height: 39px; }

.sky-form .textarea-expandable textarea:focus {
  height: auto; }

/**/
/* radios and checkboxes */
/**/
.sky-form .radio,
.sky-form .checkbox {
  margin-bottom: 4px;
  padding-left: 27px;
  font-size: 15px;
  line-height: 27px;
  color: #404040;
  cursor: pointer; }

.sky-form .radio:last-child,
.sky-form .checkbox:last-child {
  margin-bottom: 0; }

.sky-form .radio input,
.sky-form .checkbox input {
  position: absolute;
  left: -9999px; }

.sky-form .radio i,
.sky-form .checkbox i {
  position: absolute;
  top: 5px;
  left: 0;
  display: block;
  width: 13px;
  height: 13px;
  outline: none;
  border-width: 2px;
  border-style: solid;
  background: #fff; }

.sky-form .radio i {
  border-radius: 50%; }

.sky-form .radio input + i:after,
.sky-form .checkbox input + i:after {
  position: absolute;
  opacity: 0;
  -ms-transition: opacity 0.1s;
  -moz-transition: opacity 0.1s;
  -webkit-transition: opacity 0.1s; }

.sky-form .radio input + i:after {
  content: '';
  top: 4px;
  left: 4px;
  width: 5px;
  height: 5px;
  border-radius: 50%; }

.sky-form .checkbox input + i:after {
  content: '\f00c';
  top: -1px;
  left: -1px;
  width: 15px;
  height: 15px;
  font: normal 12px/16px FontAwesome;
  text-align: center; }

.sky-form .radio input:checked + i:after,
.sky-form .checkbox input:checked + i:after {
  opacity: 1; }

.sky-form .inline-group {
  margin: 0 -30px -4px 0; }

.sky-form .inline-group:after {
  content: '';
  display: table;
  clear: both; }

.sky-form .inline-group .radio,
.sky-form .inline-group .checkbox {
  float: left;
  margin-right: 30px; }

.sky-form .inline-group .radio:last-child,
.sky-form .inline-group .checkbox:last-child {
  margin-bottom: 4px; }

/**/
/* toggles */
/**/
.sky-form .toggle {
  margin-bottom: 4px;
  padding-right: 61px;
  font-size: 15px;
  line-height: 27px;
  color: #404040;
  cursor: pointer; }

.sky-form .toggle:last-child {
  margin-bottom: 0; }

.sky-form .toggle input {
  position: absolute;
  left: -9999px; }

.sky-form .toggle i {
  content: '';
  position: absolute;
  top: 4px;
  right: 0;
  display: block;
  width: 49px;
  height: 17px;
  border-width: 2px;
  border-style: solid;
  border-radius: 12px;
  background: #fff; }

.sky-form .toggle i:after {
  content: 'OFF';
  position: absolute;
  top: 2px;
  right: 8px;
  left: 8px;
  font-style: normal;
  font-size: 9px;
  line-height: 13px;
  font-weight: 700;
  text-align: left;
  color: #5f5f5f; }

.sky-form .toggle i:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  opacity: 1;
  -ms-transition: right 0.2s;
  -moz-transition: right 0.2s;
  -webkit-transition: right 0.2s; }

.sky-form .toggle input:checked + i:after {
  content: 'ON';
  text-align: right; }

.sky-form .toggle input:checked + i:before {
  right: 36px; }

/**/
/* ratings */
/**/
.sky-form .rating {
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 27px;
  color: #404040; }

.sky-form .rating:last-child {
  margin-bottom: 0; }

.sky-form .rating input {
  position: absolute;
  left: -9999px; }

.sky-form .rating label {
  display: block;
  float: right;
  height: 17px;
  margin-top: 5px;
  padding: 0 2px;
  font-size: 17px;
  line-height: 17px;
  cursor: pointer; }

/**/
/* buttons */
/**/
.sky-form .button {
  float: right;
  height: 39px;
  overflow: hidden;
  margin: 10px 0 0 20px;
  padding: 0 25px;
  outline: none;
  border: 0;
  font: 300 15px/39px 'Open Sans', Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #fff;
  cursor: pointer; }

.sky-form .button-uploading {
  position: relative;
  color: transparent;
  cursor: default; }

.sky-form .button-uploading:after {
  content: 'Uploading...';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  -o-animation: blink 1s linear infinite;
  -ms-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  -webkit-animation: blink 1s linear infinite; }

/**/
/* icons */
/**/
.sky-form .icon-append,
.sky-form .icon-prepend {
  position: absolute;
  top: 5px;
  width: 29px;
  height: 29px;
  font-size: 15px;
  line-height: 29px;
  text-align: center; }

.sky-form .icon-append {
  right: 5px;
  padding-left: 3px;
  border-left-width: 1px;
  border-left-style: solid; }

.sky-form .icon-prepend {
  left: 5px;
  padding-right: 3px;
  border-right-width: 1px;
  border-right-style: solid; }

.sky-form .input .icon-prepend + input,
.sky-form .textarea .icon-prepend + textarea {
  padding-left: 46px; }

.sky-form .input .icon-append + input,
.sky-form .textarea .icon-append + textarea {
  padding-right: 46px; }

.sky-form .input .icon-prepend + .icon-append + input,
.sky-form .textarea .icon-prepend + .icon-append + textarea {
  padding-left: 46px; }

/**/
/* grid */
/**/
.sky-form .row {
  margin: 0 -15px; }

.sky-form .row:after {
  content: '';
  display: table;
  clear: both; }

.sky-form .col {
  float: left;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.sky-form .col-1 {
  width: 8.33%; }

.sky-form .col-2 {
  width: 16.66%; }

.sky-form .col-3 {
  width: 25%; }

.sky-form .col-4 {
  width: 33.33%; }

.sky-form .col-5 {
  width: 41.66%; }

.sky-form .col-6 {
  width: 50%; }

.sky-form .col-7 {
  width: 58.33%; }

.sky-form .col-8 {
  width: 66.67%; }

.sky-form .col-9 {
  width: 75%; }

.sky-form .col-10 {
  width: 83.33%; }

.sky-form .col-11 {
  width: 91.66%; }

@media screen and (max-width: 600px) {
  .sky-form .col {
    float: none;
    width: 100%; } }

/**/
/* tooltips */
/**/
.sky-form .tooltip {
  position: absolute;
  z-index: 1;
  left: -9999px;
  padding: 2px 8px 3px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  opacity: 0;
  -ms-transition: margin 0.3s, opacity 0.3s;
  -moz-transition: margin 0.3s, opacity 0.3s;
  -webkit-transition: margin 0.3s, opacity 0.3s; }

.sky-form .tooltip:after {
  content: '';
  position: absolute; }

.sky-form .input input:focus + .tooltip,
.sky-form .textarea textarea:focus + .tooltip {
  opacity: 1; }

.sky-form .tooltip-top-right {
  bottom: 100%;
  margin-bottom: 15px; }

.sky-form .tooltip-top-right:after {
  top: 100%;
  right: 16px;
  border-top: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.sky-form .input input:focus + .tooltip-top-right,
.sky-form .textarea textarea:focus + .tooltip-top-right {
  right: 0;
  left: auto;
  margin-bottom: 5px; }

.sky-form .tooltip-top-left {
  bottom: 100%;
  margin-bottom: 15px; }

.sky-form .tooltip-top-left:after {
  top: 100%;
  left: 16px;
  border-top: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.sky-form .input input:focus + .tooltip-top-left,
.sky-form .textarea textarea:focus + .tooltip-top-left {
  right: auto;
  left: 0;
  margin-bottom: 5px; }

.sky-form .tooltip-right {
  top: 9px;
  white-space: nowrap;
  margin-left: 15px; }

.sky-form .tooltip-right:after {
  top: 6px;
  right: 100%;
  border-top: 4px solid transparent;
  border-right: 4px solid rgba(0, 0, 0, 0.9);
  border-bottom: 4px solid transparent; }

.sky-form .input input:focus + .tooltip-right,
.sky-form .textarea textarea:focus + .tooltip-right {
  left: 100%;
  margin-left: 5px; }

.sky-form .tooltip-left {
  top: 9px;
  white-space: nowrap;
  margin-right: 15px; }

.sky-form .tooltip-left:after {
  top: 6px;
  left: 100%;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid rgba(0, 0, 0, 0.9); }

.sky-form .input input:focus + .tooltip-left,
.sky-form .textarea textarea:focus + .tooltip-left {
  right: 100%;
  left: auto;
  margin-right: 5px; }

.sky-form .tooltip-bottom-right {
  top: 100%;
  margin-top: 15px; }

.sky-form .tooltip-bottom-right:after {
  bottom: 100%;
  right: 16px;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(0, 0, 0, 0.9);
  border-left: 4px solid transparent; }

.sky-form .input input:focus + .tooltip-bottom-right,
.sky-form .textarea textarea:focus + .tooltip-bottom-right {
  right: 0;
  left: auto;
  margin-top: 5px; }

.sky-form .tooltip-bottom-left {
  top: 100%;
  margin-top: 15px; }

.sky-form .tooltip-bottom-left:after {
  bottom: 100%;
  left: 16px;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(0, 0, 0, 0.9);
  border-left: 4px solid transparent; }

.sky-form .input input:focus + .tooltip-bottom-left,
.sky-form .textarea textarea:focus + .tooltip-bottom-left {
  right: auto;
  left: 0;
  margin-top: 5px; }

/**/
/* normal state */
/**/
.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea,
.sky-form .radio i,
.sky-form .checkbox i,
.sky-form .toggle i,
.sky-form .icon-append,
.sky-form .icon-prepend {
  border-color: #e5e5e5;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s; }

.sky-form .toggle i:before {
  background-color: #2da5da; }

.sky-form .rating label {
  color: #ccc;
  -ms-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -webkit-transition: color 0.3s; }

.sky-form .button {
  background-color: #2da5da;
  opacity: 0.8;
  -ms-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s; }

.sky-form .button.button-secondary {
  background-color: #b3b3b3; }

.sky-form .icon-append,
.sky-form .icon-prepend {
  color: #ccc; }

/**/
/* hover state */
/**/
.sky-form .input:hover input,
.sky-form .select:hover select,
.sky-form .textarea:hover textarea,
.sky-form .radio:hover i,
.sky-form .checkbox:hover i,
.sky-form .toggle:hover i,
.sky-form .ui-slider-handle:hover {
  border-color: #8dc9e5; }

.sky-form .rating input + label:hover,
.sky-form .rating input + label:hover ~ label {
  color: #2da5da; }

.sky-form .button:hover {
  opacity: 1; }

/**/
/* focus state */
/**/
.sky-form .input input:focus,
.sky-form .select select:focus,
.sky-form .textarea textarea:focus,
.sky-form .radio input:focus + i,
.sky-form .checkbox input:focus + i,
.sky-form .toggle input:focus + i {
  border-color: #2da5da; }

/**/
/* checked state */
/**/
.sky-form .radio input + i:after {
  background-color: #2da5da; }

.sky-form .checkbox input + i:after {
  color: #2da5da; }

.sky-form .radio input:checked + i,
.sky-form .checkbox input:checked + i,
.sky-form .toggle input:checked + i {
  border-color: #2da5da; }

.sky-form .rating input:checked ~ label {
  color: #2da5da; }

/**/
/* error state */
/**/
.sky-form .state-error input,
.sky-form .state-error select,
.sky-form .state-error select + i,
.sky-form .state-error textarea,
.sky-form .radio.state-error i,
.sky-form .checkbox.state-error i,
.sky-form .toggle.state-error i,
.sky-form .toggle.state-error input:checked + i {
  background: #fff0f0; }

.sky-form .state-error + em {
  display: block;
  margin-top: 6px;
  padding: 0 1px;
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  color: #ee9393; }

.sky-form .rating.state-error + em {
  margin-top: -4px;
  margin-bottom: 4px; }

/**/
/* success state */
/**/
.sky-form .state-success input,
.sky-form .state-success select,
.sky-form .state-success select + i,
.sky-form .state-success textarea,
.sky-form .radio.state-success i,
.sky-form .checkbox.state-success i,
.sky-form .toggle.state-success i,
.sky-form .toggle.state-success input:checked + i {
  background: #f0fff0; }

.sky-form .state-success + em {
  display: block;
  margin-top: 6px;
  padding: 0 1px;
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  color: #ee9393; }

.sky-form .note-success {
  color: #6fb679; }

/**/
/* disabled state */
/**/
.sky-form .input.state-disabled input,
.sky-form .select.state-disabled,
.sky-form .textarea.state-disabled,
.sky-form .radio.state-disabled,
.sky-form .checkbox.state-disabled,
.sky-form .toggle.state-disabled,
.sky-form .button.state-disabled {
  cursor: default;
  opacity: 0.5; }

.sky-form .input.state-disabled:hover input,
.sky-form .select.state-disabled:hover select,
.sky-form .textarea.state-disabled:hover textarea,
.sky-form .radio.state-disabled:hover i,
.sky-form .checkbox.state-disabled:hover i,
.sky-form .toggle.state-disabled:hover i {
  border-color: #e5e5e5; }

/**/
/* submited state */
/**/
.sky-form .message {
  display: none;
  color: #6fb679; }

.sky-form .message i {
  display: block;
  margin: 0 auto 20px;
  width: 81px;
  height: 81px;
  border: 1px solid #6fb679;
  border-radius: 50%;
  font-size: 30px;
  line-height: 81px; }

.sky-form.submited fieldset,
.sky-form.submited footer {
  display: none; }

.sky-form.submited .message {
  display: block;
  padding: 25px 30px;
  background: rgba(255, 255, 255, 0.9);
  font: 300 18px/27px 'Open Sans', Helvetica, Arial, sans-serif;
  text-align: center; }

/**/
/* datepicker */
/**/
.ui-datepicker {
  display: none;
  padding: 10px 12px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  font: 13px/1.55 'Open Sans', Helvetica, Arial, sans-serif;
  text-align: center;
  color: #666; }

.ui-datepicker a {
  color: #404040; }

.ui-datepicker-header {
  position: relative;
  margin: -10px -12px 10px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  line-height: 27px; }

.ui-datepicker-prev,
.ui-datepicker-next {
  position: absolute;
  top: 0;
  display: block;
  width: 47px;
  height: 47px;
  font-size: 15px;
  line-height: 47px;
  text-decoration: none;
  cursor: pointer; }

.ui-datepicker-prev {
  left: 0; }

.ui-datepicker-next {
  right: 0; }

.ui-datepicker-calendar {
  border-collapse: collapse;
  font-size: 13px;
  line-height: 27px; }

.ui-datepicker-calendar th {
  color: #999; }

.ui-datepicker-calendar a,
.ui-datepicker-calendar span {
  display: block;
  width: 31px;
  margin: auto;
  text-decoration: none;
  color: #404040; }

.ui-datepicker-calendar a:hover {
  background: rgba(0, 0, 0, 0.05); }

.ui-datepicker-calendar span {
  color: #bfbfbf; }

.ui-datepicker-today a {
  font-weight: 700; }

.ui-datepicker-calendar .ui-state-active {
  background: rgba(0, 0, 0, 0.05);
  cursor: default; }

.ui-datepicker-inline {
  border: 2px solid #e5e5e5;
  background: #fff;
  box-shadow: none; }

.ui-datepicker-inline .ui-datepicker-header {
  line-height: 47px; }

.ui-datepicker-inline .ui-datepicker-calendar {
  width: 100%; }

/**/
/* slider */
/**/
.sky-form .ui-slider {
  position: relative;
  height: 3px;
  border: 2px solid #e5e5e5;
  background: #fff;
  margin: 12px 6px 26px; }

.sky-form .ui-slider-handle {
  position: absolute;
  width: 15px;
  height: 15px;
  margin: -8px 0 0 -8px;
  border: 2px solid #e5e5e5;
  outline: none;
  background: #fff;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s; }

/**/
/* modal */
/**/
.sky-form-modal {
  position: fixed;
  z-index: 1;
  display: none;
  width: 400px; }

.sky-form-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); }

/**/
/* bootstrap compatibility */
/**/
/*
.sky-form *,
.sky-form *:after,
.sky-form *:before {
	margin: 0;
	padding: 0;
	box-sizing: content-box;
	-moz-box-sizing: content-box;
}
*/
.sky-form .label {
  border-radius: 0;
  font-size: 100%;
  text-align: left;
  white-space: normal;
  color: inherit; }

.sky-form .radio,
.sky-form .checkbox {
  font-weight: 400; }

.sky-form .radio + .radio,
.sky-form .checkbox + .checkbox {
  margin-top: 0; }

/*Sky-Forms
------------------------------------*/
/*Contact Style*/
.sky-form.contact-style {
  border: none; }

/*Comment Style*/
.sky-form.comment-style,
.sky-form.comment-style fieldset {
  padding: 0;
  border: none;
  background: inherit; }

.sky-form.comment-style input,
.sky-form.comment-style textarea {
  border: none; }

.sky-form.comment-style input:focus,
.sky-form.comment-style textarea:focus {
  box-shadow: none; }

/*Comment Style v2*/
.sky-form.comment-style-v2,
.sky-form.comment-style-v2 fieldset {
  padding: 0;
  border: none;
  background: inherit; }

.sky-form.comment-style-v2 input,
.sky-form.comment-style-v2 textarea {
  border: none; }

.sky-form.comment-style-v2 input:focus,
.sky-form.comment-style-v2 textarea:focus {
  box-shadow: none; }

.sky-form.comment-style-v2 .form-control {
  padding: 20px 15px; }

/*Sky Space*/
.sky-form .sky-space-20 {
  margin-bottom: 20px; }

.sky-form .sky-space-30 {
  margin-bottom: 30px; }

/**/
/* defaults */
/**/
.sky-form {
  box-shadow: none;
  border: 1px solid #eee; }

.sky-form header {
  color: inherit;
  display: block;
  font-size: 20px;
  background: #fff;
  font-weight: 400;
  padding: 8px 30px; }

.sky-form fieldset.no-padding {
  padding: 0; }

.sky-form fieldset {
  background: #fff; }

.sky-form footer {
  background: #fff;
  padding: 15px 30px; }

.sky-form a {
  color: inherit; }

.sky-form a.btn-u {
  color: #fff; }

.sky-form .input input {
  height: 34px;
  padding: 6px 12px; }

.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea {
  border-width: 1px;
  font-size: 14px;
  color: #404040; }

.sky-form .select select {
  height: 33px;
  padding: 6px 10px; }

.sky-form .select-multiple select {
  height: auto; }

/**/
/* file inputs */
/**/
.sky-form .input-file .button {
  height: 32px;
  top: 1px;
  right: 1px; }

/**/
/* captcha inputs */
/**/
.sky-form .input-captcha img {
  position: absolute;
  top: 1px;
  right: 1px;
  border-left: 1px solid #e5e5e5; }

/**/
/* normal state */
/**/
.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea,
.sky-form .radio i,
.sky-form .checkbox i,
.sky-form .toggle i,
.sky-form .icon-append,
.sky-form .icon-prepend {
  border-color: #bbb; }

.sky-form .toggle i:before {
  background-color: #999; }

.sky-form .button {
  background: #72c02c; }

/**/
/* toggles */
/**/
.sky-form .toggle {
  font-weight: normal; }

.sky-form .toggle i {
  width: 54px;
  height: 21px;
  border-width: 1px; }

.sky-form .toggle i:after {
  top: 3px; }

.sky-form .toggle i:before {
  top: 5px;
  right: 6px; }

/*(remove Bootstrap 'radio, checkbox' margin-top)
--------------------------------------------------*/
.radio, .checkbox {
  margin-top: 0; }

/**/
/* radios and checkboxes */
/**/
.sky-form .radio i,
.sky-form .checkbox i {
  width: 17px;
  height: 17px;
  border-width: 1px; }

.sky-form .checkbox input + i:after {
  top: 2px;
  left: 0;
  font: normal 10px FontAwesome; }

/**/
/* checked state */
/**/
.sky-form .radio input + i:after {
  top: 5px;
  left: 5px;
  background-color: #999; }

.sky-form .checkbox input + i:after {
  color: #999; }

.sky-form .radio input:checked + i,
.sky-form .checkbox input:checked + i,
.sky-form .toggle input:checked + i {
  border-color: #999; }

.sky-form .rating input:checked ~ label {
  color: #72c02c; }

/**/
/* selects */
/**/
.sky-form .select i {
  top: 14px;
  width: 9px;
  right: 13px;
  height: 6px; }

.sky-form .select i:after,
.sky-form .select i:before {
  top: 4px; }

.sky-form .select i:before {
  top: -2px;
  border-bottom: 4px solid #404040; }

.label-rounded .ui-slider-handle {
  border-radius: 50% !important; }

/**/
/* icons */
/**/
.sky-form .icon-append,
.sky-form .icon-prepend {
  top: 1px;
  height: 32px;
  font-size: 14px;
  line-height: 33px;
  background: inherit; }

.sky-form .icon-append {
  right: 1px;
  padding: 0 3px;
  min-width: 34px; }

.sky-form .icon-prepend {
  left: 6px;
  padding-right: 5px; }

/**/
/* focus state */
/**/
.sky-form .input input:focus,
.sky-form .select select:focus,
.sky-form .textarea textarea:focus {
  border-color: #bbb;
  box-shadow: 0 0 2px #c9c9c9; }

.sky-form .radio input:focus + i,
.sky-form .checkbox input:focus + i,
.sky-form .toggle input:focus + i {
  border-color: #999;
  box-shadow: none; }

/**/
/* hover state */
/**/
.sky-form .input:hover input,
.sky-form .select:hover select,
.sky-form .textarea:hover textarea {
  border-color: #999; }

.sky-form .radio:hover i,
.sky-form .checkbox:hover i,
.sky-form .toggle:hover i,
.sky-form .ui-slider-handle:hover {
  border-color: #999; }

.sky-form .rating input + label:hover,
.sky-form .rating input + label:hover ~ label {
  color: #72c02c; }

.sky-form .icon-append,
.sky-form .icon-prepend {
  color: #b3b3b3; }

/**/
/* disabled state */
/**/
.sky-form .input.state-disabled:hover input,
.sky-form .select.state-disabled:hover select,
.sky-form .textarea.state-disabled:hover textarea,
.sky-form .radio.state-disabled:hover i,
.sky-form .checkbox.state-disabled:hover i,
.sky-form .toggle.state-disabled:hover i {
  border-color: #bbb; }

/**/
/* datepicker */
/**/
.ui-datepicker-header {
  padding: 10px 0;
  font-size: 16px; }

.ui-datepicker-inline {
  border-width: 1px; }

.ui-datepicker-inline .ui-datepicker-header {
  line-height: 27px; }

.ui-datepicker-prev,
.ui-datepicker-next {
  font-size: 18px;
  line-height: 47px; }

.ui-datepicker-calendar a {
  border: 1px solid transparent; }

.ui-datepicker-calendar .ui-state-active {
  background: inherit;
  border: 1px solid #bbb; }

.ui-datepicker-calendar a:hover {
  background: inherit;
  border: 1px solid #bbb; }

/**/
/* bootstrap compatibility */
/**/
.sky-form button *,
.sky-form button *:after,
.sky-form button *:before {
  margin: 0 auto;
  padding: 0 auto; }

.btn-u.button-uploading:after {
  top: 6px; }

.btn-u.btn-u-lg.button-uploading:after {
  top: 10px; }

.btn-u.btn-u-sm.button-uploading:after {
  top: 3px; }

.btn-u.btn-u-xs.button-uploading:after {
  top: 2px; }

.label {
  padding: 0.2em 0 0.3em; }

/*Fix for datepicker's responsive issue on resolution 1024x768*/
/*.ui-datepicker-calendar a,*/
.ui-datepicker-calendar span {
  width: 26px;
  /*color: #bfbfbf;*/ }

/* Global classes
---------------------------------------*/
.g-dp-table {
  display: table; }

.g-dp-table-cell {
  display: table-cell; }

.g-va-middle {
  vertical-align: middle; }

.g-va-bottom {
  vertical-align: bottom; }

@media (min-width: 600px) {
  .visible-2xs {
    display: none; } }

@media (max-width: 600px) {
  .hidden-2xs {
    display: none; }
  .col-2xs-12 {
    width: 100%; } }

@media (min-width: 500px) {
  .visible-3xs {
    display: none; } }

@media (max-width: 500px) {
  .hidden-3xs {
    display: none; }
  .col-3xs-12 {
    width: 100%; } }

.g-fs-10 {
  font-size: 10px; }

.g-fs-12 {
  font-size: 12px; }

.g-fs-14 {
  font-size: 14px; }

.g-fs-16 {
  font-size: 16px; }

.g-fs-18 {
  font-size: 18px; }

.g-padding-5 {
  padding: 5px; }

.g-padding-10 {
  padding: 10px; }

.g-padding-15 {
  padding: 15px; }

.g-padding-20 {
  padding: 20px; }

.g-padding-25 {
  padding: 25px; }

.g-padding-30 {
  padding: 30px; }

.g-padding-35 {
  padding: 35px; }

.g-padding-40 {
  padding: 40px; }

.g-padding-45 {
  padding: 45px; }

.g-padding-50 {
  padding: 50px; }

.g-pt-10 {
  padding-top: 10px; }

.g-pt-20 {
  padding-top: 20px; }

.g-pt-30 {
  padding-top: 30px; }

.g-pt-40 {
  padding-top: 40px; }

.g-pt-50 {
  padding-top: 50px; }

.g-pt-60 {
  padding-top: 60px; }

.g-pt-70 {
  padding-top: 70px; }

.g-pt-80 {
  padding-top: 80px; }

.g-pt-90 {
  padding-top: 90px; }

.g-pt-100 {
  padding-top: 100px; }

.g-pt-110 {
  padding-top: 110px; }

.g-pt-120 {
  padding-top: 120px; }

.g-pt-130 {
  padding-top: 130px; }

.g-pt-140 {
  padding-top: 140px; }

.g-pt-150 {
  padding-top: 150px; }

.g-pt-160 {
  padding-top: 160px; }

.g-pt-170 {
  padding-top: 170px; }

.g-pb-10 {
  padding-bottom: 10px; }

.g-pb-20 {
  padding-bottom: 20px; }

.g-pb-30 {
  padding-bottom: 30px; }

.g-pb-40 {
  padding-bottom: 40px; }

.g-pb-50 {
  padding-bottom: 50px; }

.g-pb-60 {
  padding-bottom: 60px; }

.g-pb-70 {
  padding-bottom: 70px; }

.g-pb-80 {
  padding-bottom: 80px; }

.g-pb-90 {
  padding-bottom: 90px; }

.g-pb-100 {
  padding-bottom: 100px; }

.g-pl-10 {
  padding-left: 10px; }

.g-pl-20 {
  padding-left: 20px; }

.g-pl-30 {
  padding-left: 30px; }

.g-pl-40 {
  padding-left: 40px; }

.g-pl-50 {
  padding-left: 50px; }

.g-pl-60 {
  padding-left: 60px; }

.g-pl-70 {
  padding-left: 70px; }

.g-pl-80 {
  padding-left: 80px; }

.g-pl-90 {
  padding-left: 90px; }

.g-pl-100 {
  padding-left: 100px; }

@media (max-width: 767px) {
  .g-pl-10--xs {
    padding-left: 10px; }
  .g-pl-15--xs {
    padding-left: 15px; }
  .g-pl-20--xs {
    padding-left: 20px; }
  .g-pl-30--xs {
    padding-left: 30px; }
  .g-pl-40--xs {
    padding-left: 40px; }
  .g-pl-50--xs {
    padding-left: 50px; }
  .g-pl-60--xs {
    padding-left: 60px; }
  .g-pl-70--xs {
    padding-left: 70px; }
  .g-pl-80--xs {
    padding-left: 80px; }
  .g-pl-90--xs {
    padding-left: 90px; }
  .g-pl-100--xs {
    padding-left: 100px; } }

.g-pr-10 {
  padding-right: 10px; }

.g-pr-20 {
  padding-right: 20px; }

.g-pr-30 {
  padding-right: 30px; }

.g-pr-40 {
  padding-right: 40px; }

.g-pr-50 {
  padding-right: 50px; }

.g-pr-60 {
  padding-right: 60px; }

.g-pr-70 {
  padding-right: 70px; }

.g-pr-80 {
  padding-right: 80px; }

.g-pr-90 {
  padding-right: 90px; }

.g-pr-100 {
  padding-right: 100px; }

@media (max-width: 767px) {
  .g-pr-10--xs {
    padding-right: 10px; }
  .g-pr-15--xs {
    padding-right: 15px; }
  .g-pr-20--xs {
    padding-right: 20px; }
  .g-pr-30--xs {
    padding-right: 30px; }
  .g-pr-40--xs {
    padding-right: 40px; }
  .g-pr-50--xs {
    padding-right: 50px; }
  .g-pr-60--xs {
    padding-right: 60px; }
  .g-pr-70--xs {
    padding-right: 70px; }
  .g-pr-80--xs {
    padding-right: 80px; }
  .g-pr-90--xs {
    padding-right: 90px; }
  .g-pr-100--xs {
    padding-right: 100px; } }

.g-mt-10 {
  margin-top: 10px; }

.g-mt-20 {
  margin-top: 20px; }

.g-mt-30 {
  margin-top: 30px; }

.g-mt-40 {
  margin-top: 40px; }

.g-mt-50 {
  margin-top: 50px; }

.g-mt-60 {
  margin-top: 60px; }

.g-mt-70 {
  margin-top: 70px; }

.g-mt-80 {
  margin-top: 80px; }

.g-mt-90 {
  margin-top: 90px; }

.g-mt-100 {
  margin-top: 100px; }

.g-mb-5 {
  margin-bottom: 5px; }

.g-mb-10 {
  margin-bottom: 10px; }

.g-mb-15 {
  margin-bottom: 15px; }

.g-mb-20 {
  margin-bottom: 20px; }

.g-mb-25 {
  margin-bottom: 25px; }

.g-mb-30 {
  margin-bottom: 30px; }

.g-mb-35 {
  margin-bottom: 35px; }

.g-mb-40 {
  margin-bottom: 40px; }

.g-mb-45 {
  margin-bottom: 45px; }

.g-mb-50 {
  margin-bottom: 50px; }

.g-mb-55 {
  margin-bottom: 55px; }

.g-mb-60 {
  margin-bottom: 60px; }

.g-mb-65 {
  margin-bottom: 65px; }

.g-mb-70 {
  margin-bottom: 70px; }

.g-mb-75 {
  margin-bottom: 75px; }

.g-mb-80 {
  margin-bottom: 80px; }

.g-mb-85 {
  margin-bottom: 85px; }

.g-mb-90 {
  margin-bottom: 90px; }

.g-mb-95 {
  margin-bottom: 95px; }

.g-mb-100 {
  margin-bottom: 100px; }

.g-ml-10 {
  margin-left: 10px; }

.g-ml-20 {
  margin-left: 20px; }

.g-ml-30 {
  margin-left: 30px; }

.g-ml-40 {
  margin-left: 40px; }

.g-ml-50 {
  margin-left: 50px; }

.g-ml-60 {
  margin-left: 60px; }

.g-ml-70 {
  margin-left: 70px; }

.g-ml-80 {
  margin-left: 80px; }

.g-ml-90 {
  margin-left: 90px; }

.g-ml-100 {
  margin-left: 100px; }

.g-mr-10 {
  margin-right: 10px; }

.g-mr-15 {
  margin-right: 15px; }

.g-mr-20 {
  margin-right: 20px; }

.g-mr-30 {
  margin-right: 30px; }

.g-mr-40 {
  margin-right: 40px; }

.g-mr-50 {
  margin-right: 50px; }

.g-mr-60 {
  margin-right: 60px; }

.g-mr-70 {
  margin-right: 70px; }

.g-mr-80 {
  margin-right: 80px; }

.g-mr-90 {
  margin-right: 90px; }

.g-mr-100 {
  margin-right: 100px; }

.no-column-space [class^="col-"], .no-column-space [class*=" col-"] {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden; }

.standart-column-space [class^="col-"], .standart-column-space [class*=" col-"] {
  padding-left: 15px;
  padding-right: 15px; }

@media (max-width: 768px) {
  .equal-height-column {
    width: 100%;
    height: auto !important; } }

/* Lawyer global classes
---------------------------------------------------*/
.btn-u-upper {
  text-transform: uppercase; }

.btn-u-lg.btn-u-upper, a.btn-u-lg.btn-u-upper {
  font-size: 12px;
  font-weight: bold; }

/* Logo */
/* Header
-------------------------------------------------------*/
/* End Header
--------------------------------------------*/
/* About section
--------------------------------------------*/
/* What we do section
--------------------------------------------*/
.content-boxes-v7 {
  margin-bottom: 40px; }

.content-boxes-v7 i.fa {
  font-size: 47px;
  margin-top: 24px;
  margin-right: 38px; }

.content-boxes-in-v7 {
  overflow: hidden; }

/* Why we section
--------------------------------------------*/
/* Our cases section
--------------------------------------------*/
/* Blog section
--------------------------------------------*/
/* Team section
--------------------------------------------*/
/* Testimonials section
--------------------------------------------*/
/* Sponsors section
--------------------------------------------*/
/* Contact section
--------------------------------------------*/
/* Footer section
--------------------------------------------*/
/*----------------------------------------------------------------------

[-----*** TABLE OF CONTENT ***-----]

1. Header - Topbar
2. Header - Navbar
3. Header - Responsive Navbar Style
4. Header - Submenu
5. Header - Search
6. Header - Mega Menu
7. Header - Dark Option
8. Header - Fixed
9. Header - Fixed Boxed
-----------------------------------------------------------------------*/
body.header-fixed-space-default {
  padding-top: 81px; }

@media (max-width: 991px) {
  body.header-fixed-space-default {
    padding-top: 0; } }

/*--------------------------------------------------
    [1. Header - Topbar]
----------------------------------------------------*/
/*Top Bar (login, search etc.)
------------------------------------*/
.header .topbar {
  z-index: 12;
  padding: 8px 0;
  position: relative; }

@media (max-width: 991px) {
  .header .topbar {
    margin-bottom: 20px; } }

.header .topbar ul.loginbar {
  margin: 0; }

.header .topbar ul.loginbar > li {
  display: inline;
  list-style: none;
  position: relative;
  padding-bottom: 15px; }

.header .topbar ul.loginbar > li > a,
.header .topbar ul.loginbar > li > a:hover {
  color: #7c8082;
  font-size: 11px;
  text-transform: uppercase; }

.header .topbar ul.loginbar li i.fa {
  color: #bbb; }

.header .topbar ul.loginbar li.topbar-devider {
  top: -1px;
  padding: 0;
  font-size: 8px;
  position: relative;
  margin: 0 9px 0 5px;
  font-family: Tahoma;
  border-right: solid 1px #bbb; }

/*languages*/
.header .topbar ul.languages {
  top: 25px;
  left: -5px;
  display: none;
  padding: 4px 0;
  padding-left: 0;
  list-style: none;
  min-width: 100px;
  position: absolute;
  background: #f0f0f0; }

.header .topbar li:hover ul.languages {
  display: block; }

.header .topbar ul.languages:after {
  top: -4px;
  width: 0;
  height: 0;
  left: 8px;
  content: " ";
  display: block;
  position: absolute;
  border-bottom: 6px solid #f0f0f0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/ }

.header .topbar ul.languages li a {
  color: #555;
  display: block;
  font-size: 10px;
  padding: 2px 12px;
  margin-bottom: 1px;
  text-transform: uppercase; }

.header .topbar ul.languages li.active a i {
  color: #999;
  float: right;
  margin-top: 2px; }

.header .topbar ul.languages li a:hover,
.header .topbar ul.languages li.active a {
  background: #fafafa; }

.header .topbar ul.languages li a:hover {
  text-decoration: none; }

/*No Topbar*/
.header.no-topbar .navbar-toggle {
  margin-top: 25px; }

/*--------------------------------------------------
    [2. Header - Navbar]
----------------------------------------------------*/
/*Navbar*/
.header {
  z-index: 99;
  background: #fff;
  position: relative;
  border-bottom: solid 2px #eee; }

/*Header Container*/
.header > .container {
  display: table;
  margin-bottom: -40px; }

@media (max-width: 768px) {
  .header > .container {
    width: 100%; } }

@media (max-width: 991px) {
  .header > .container {
    margin-bottom: 0; } }

/*Logo*/
.header .logo {
  height: 100%;
  min-height: 40px;
  min-width: 100px;
  display: table-cell;
  vertical-align: middle; }

.header .logo img {
  z-index: 1;
  margin: 20px 0;
  position: relative; }

/*Big Logo Style*/
@media (max-width: 1200px) {
  .header img.big-logo {
    width: 120px;
    height: auto; } }

/*Navbar Toggle*/
.header .navbar-toggle {
  border-color: #5fb611; }

@media (max-width: 991px) {
  .header .navbar-toggle {
    margin-right: 0; } }

.header .navbar-toggle .fa {
  color: #fff;
  font-size: 19px; }

.header .navbar-toggle,
.header .navbar-toggle:hover,
.header .navbar-toggle:focus {
  background: #72c02c;
  padding: 6px 10px 2px; }

.header .navbar-toggle:hover {
  background: #5fb611 !important; }

/*Navbar Collapse*/
.header .navbar-collapse {
  position: relative; }

/*Navbar Collapse*/
@media (max-width: 991px) {
  .header .navbar-collapse,
  .header .navbar-collapse .container {
    padding-left: 0;
    padding-right: 0; } }

/*Navbar Menu*/
.header .navbar-nav > li > a {
  color: #687074;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase; }

.header .navbar-nav > .active > a {
  color: #72c02c; }

@media (min-width: 992px) {
  .header .navbar-nav {
    float: right; } }

/*--------------------------------------------------
    [3. Header - Responsive Navbar Style]
----------------------------------------------------*/
/*Responsive Navbar*/
@media (max-width: 991px) {
  /*Responsive code for max-width: 991px*/
  .header .navbar-header {
    float: none; }
  .header .navbar-toggle {
    display: block; }
  .header .navbar-collapse.collapse {
    display: none !important; }
  .header .navbar-collapse.collapse.in {
    display: block !important;
    overflow-y: auto !important; }
  .header .navbar-nav {
    margin: 0 0 5px;
    float: none !important; }
  .header .navbar-nav > li {
    float: none; }
  .header .navbar-nav > li > a {
    padding-top: 30px;
    padding-bottom: 40px; }
  /*Pull Right*/
  .header .dropdown-menu.pull-right {
    float: none !important; }
  /*Dropdown Menu Slide Down Effect*/
  .header .navbar-nav .open .dropdown-menu {
    border: 0;
    float: none;
    width: auto;
    margin-top: 0;
    position: static;
    box-shadow: none;
    background-color: transparent; }
  .header .navbar-nav .open > a,
  .header .navbar-nav .open > a:hover,
  .header .navbar-nav .open > a:focus {
    border-bottom-color: #eee; }
  .header .navbar-nav .open .dropdown-menu > li > a,
  .header .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px; }
  .header .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px; }
  .header .navbar-nav .open .dropdown-menu > li > a:hover,
  .header .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none; }
  .header .navbar-nav .open .dropdown-menu > li > a {
    color: #777; }
  .header .navbar-nav .open .dropdown-menu > li > a:hover,
  .header .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
    background-color: transparent; }
  .header .navbar-nav .open .dropdown-menu > .active > a,
  .header .navbar-nav .open .dropdown-menu > .active > a:hover,
  .header .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #e7e7e7; }
  .header .navbar-nav .open .dropdown-menu > .disabled > a,
  .header .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .header .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: transparent; }
  .header .dropdown-menu.no-bottom-space {
    padding-bottom: 0; }
  /*Design for max-width: 991px*/
  .header .navbar-nav > li > a {
    font-size: 14px;
    padding: 9px 10px; }
  .header .navbar-nav > li a {
    border-bottom: solid 1px #eee; }
  .header .navbar-nav > li > a:focus {
    background: none; }
  .header .navbar-nav > li > a:hover {
    color: #72c02c; }
  .header .navbar-nav > .active > a,
  .header .navbar-nav > .active > a:hover,
  .header .navbar-nav > .active > a:focus {
    background: #72c02c;
    color: #fff !important; }
  .header .dropdown .dropdown-submenu > a {
    font-size: 13px;
    color: #555 !important;
    text-transform: uppercase; } }

@media (min-width: 992px) {
  /*Navbar Collapse*/
  .header .navbar-collapse {
    padding: 0; }
  /*Navbar*/
  .header .container > .navbar-header,
  .header .container-fluid > .navbar-header,
  .header .container > .navbar-collapse,
  .header .container-fluid > .navbar-collapse {
    margin-bottom: -10px; }
  .header .navbar-nav {
    position: relative; }
  .header .navbar-nav > li > a {
    bottom: -2px;
    position: relative;
    padding: 9px 30px 9px 20px; }
  .header .navbar-nav > li > a,
  .header .navbar-nav > li > a:focus {
    border-bottom: solid 2px transparent; }
  .header .navbar-nav > li > a:hover,
  .header .navbar-nav > .active > a {
    bottom: -2px;
    position: relative;
    border-bottom: solid 2px #72c02c; }
  .header .navbar-nav > li > a,
  .header .navbar-nav > li > a:hover,
  .header .navbar-nav > li > a:focus,
  .header .navbar-nav > .active > a,
  .header .navbar-nav > .active > a:hover,
  .header .navbar-nav > .active > a:focus {
    background: none; }
  .header .navbar-nav > .open > a,
  .header .navbar-nav > .open > a:hover,
  .header .navbar-nav > .open > a:focus {
    color: #72c02c; }
  .header .navbar-nav > li:hover > a {
    color: #72c02c; }
  /*Dropdown Menu*/
  .header .dropdown-menu {
    padding: 0;
    border: none;
    min-width: 200px;
    border-radius: 0;
    z-index: 9999 !important;
    border-top: solid 2px #72c02c;
    border-bottom: solid 2px #687074; }
  .header .dropdown-menu li a {
    color: #687074;
    font-size: 13px;
    font-weight: 400;
    padding: 6px 15px;
    border-bottom: solid 1px #eee; }
  .header .dropdown-menu .active > a,
  .header .dropdown-menu li > a:hover {
    color: #687074;
    filter: none !important;
    background: #eee !important;
    transition: all 0.1s ease-in-out; }
  .header .dropdown-menu li > a:focus {
    background: none;
    filter: none !important; }
  .header .navbar-nav > li.dropdown:hover > .dropdown-menu {
    display: block; }
  .header .open > .dropdown-menu {
    display: none; }
  /*Search*/
  .header .navbar-nav .search-open {
    width: 330px; } }

/*--------------------------------------------------
    [4. Header - Submenu]
----------------------------------------------------*/
/*Dropdown Submenu for BS3
------------------------------------*/
.header .dropdown-submenu {
  position: relative; }

.header .dropdown > a:after,
.header .dropdown-submenu > a:after {
  top: 8px;
  right: 9px;
  font-size: 11px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome; }

@media (max-width: 991px) {
  .header .dropdown-submenu > a:after {
    content: " "; } }

.header .dropdown > a:after {
  top: 9px;
  right: 15px;
  content: "\f107"; }

.header .dropdown-submenu > .dropdown-menu {
  top: 3px;
  left: 100%;
  margin-top: -5px;
  margin-left: 0px; }

/*Submenu comes from LEFT side*/
.header .dropdown-submenu > .dropdown-menu.submenu-left {
  left: -100% !important; }

.header .dropdown-submenu:hover > .dropdown-menu {
  display: block; }

@media (max-width: 991px) {
  .header .dropdown-submenu > .dropdown-menu {
    display: block;
    margin-left: 15px; } }

.header .dropdown-submenu.pull-left {
  float: none; }

.header .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px; }

/*Dropdow Mmenu Icons (Font Awesome)*/
.header .dropdown-menu li [class^="fa-"],
.header .dropdown-menu li [class*=" fa-"] {
  left: -3px;
  width: 1.25em;
  margin-right: 1px;
  position: relative;
  text-align: center;
  display: inline-block; }

.header .dropdown-menu li [class^="fa-"].fa-lg,
.header .dropdown-menu li [class*=" fa-"].fa-lg {
  /* increased font size for fa-lg */
  width: 1.5625em; }

/*--------------------------------------------------
    [5. Header - Search]
----------------------------------------------------*/
/*Search Box
------------------------------------*/
.header .nav > li > .search {
  color: #aaa;
  cursor: pointer;
  min-width: 35px;
  font-size: 15px;
  text-align: center;
  background: #f7f7f7;
  padding: 11px 0 12px;
  display: inline-block;
  border-bottom: solid 2px #aaa; }

@media (min-width: 992px) {
  .header .nav > li > .search {
    bottom: -2px;
    position: relative; } }

.header .nav > li > .search:hover {
  color: #72c02c;
  background: #f7f7f7;
  border-bottom-color: #72c02c; }

.header .nav .search-open {
  right: 0;
  top: 40px;
  display: none;
  padding: 14px;
  position: absolute;
  background: #fcfcfc;
  border-top: solid 2px #eee;
  box-shadow: 0 1px 3px #ddd; }

.header .nav .search-open form {
  margin: 0; }

@media (min-width: 767px) and (max-width: 991px) {
  .header .navbar-toggle {
    margin-right: 0; }
  .header .nav .search-open {
    width: 93%; } }

@media (max-width: 991px) {
  .header .nav > li > .search {
    color: #999;
    width: 35px;
    height: 34px;
    text-align: left;
    background: #eee;
    line-height: 12px;
    text-align: center;
    margin: 5px 10px 0;
    border-bottom: none; }
  .header .nav > li > .search:hover {
    background: #5fb611;
    color: #fff !important; }
  .header .nav .search-open {
    top: 5px;
    padding: 0;
    left: 48px;
    border-top: none;
    box-shadow: none;
    background: none;
    margin-right: 10px; } }

/*--------------------------------------------------
    [6. Header - Mega Menu]
----------------------------------------------------*/
/*Mega Menu
------------------------------------*/
.header .mega-menu .nav,
.header .mega-menu .dropup,
.header .mega-menu .dropdown,
.header .mega-menu .collapse {
  position: static; }

.header .mega-menu .navbar-inner,
.header .mega-menu .container {
  position: relative; }

.header .mega-menu .dropdown-menu {
  left: auto; }

.header .mega-menu .dropdown-menu > li {
  display: block; }

.header .mega-menu .dropdown-submenu .dropdown-menu {
  left: 100%; }

.header .mega-menu .nav.pull-right .dropdown-menu {
  right: 0; }

.header .mega-menu .mega-menu-content {
  *zoom: 1;
  padding: 4px 15px; }

.header .mega-menu .mega-menu-content:before,
.header .mega-menu .mega-menu-content:after {
  content: "";
  display: table;
  line-height: 0; }

.header .mega-menu .mega-menu-content:after {
  clear: both; }

.header .mega-menu .nav > li > .dropdown-menu:after,
.header .mega-menu .nav > li > .dropdown-menu:before {
  display: none; }

.header .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
  left: 0;
  right: 0;
  overflow: hidden; }

@media (min-width: 992px) {
  /*Mega Menu for Two Columns*/
  .header .mega-menu .mega-menu-content.content-two-col {
    min-width: 596px; }
  /*Mega Menu for Three Columns*/
  .header .mega-menu .mega-menu-content.content-three-col {
    min-width: 864px; } }

/*Mega Menu Dropdown OPENS from LEFT Side*/
.header .mega-menu .dropdown.mega-menu-left .dropdown-menu {
  left: auto;
  overflow: hidden; }

/*Mega Menu Dropdown OPENS from RIGHT Side*/
.header .mega-menu .dropdown.mega-menu-right .dropdown-menu,
.header .mega-menu .dropdown.mega-menu-two-col-right .dropdown-menu {
  right: 0;
  overflow: hidden; }

/*Equal Height Lists*/
@media (min-width: 992px) {
  .header .mega-menu .equal-height {
    display: flex; }
  .header .mega-menu .equal-height-in {
    display: flex; } }

/*Mega Menu Style Effect*/
.header .mega-menu .equal-height-list h3 {
  font-size: 15px;
  font-weight: 400;
  padding: 0 10px 0 13px;
  text-transform: uppercase; }

/*Space for only Big Resolution*/
@media (min-width: 992px) {
  .header .mega-menu .big-screen-space {
    margin-bottom: 20px; } }

/*Mega Menu Content*/
@media (min-width: 992px) {
  .header .mega-menu .equal-height-in {
    padding: 20px 0;
    border-left: 1px solid #eee; }
  .header .mega-menu .equal-height-in:first-child {
    border-left: none;
    margin-left: -1px; }
  .header .mega-menu .equal-height-list {
    width: 100%; }
  .header .mega-menu .equal-height-list li a {
    display: block;
    margin-bottom: 1px;
    position: relative;
    border-bottom: none;
    padding: 5px 10px 5px 15px; }
  .header .mega-menu .equal-height-list a:hover {
    text-decoration: none; }
  /*Dropdown Arrow Icons*/
  .header .mega-menu .mega-menu-content li a {
    padding: 5px 10px 5px 30px; }
  .header .mega-menu .mega-menu-content li a:after {
    top: 7px;
    left: 15px;
    font-size: 11px;
    content: "\f105";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome; }
  /*Dropdown Arrow Icons Disable class*/
  .header .mega-menu .disable-icons li a {
    padding: 5px 10px 5px 15px; }
  .header .mega-menu .disable-icons li a:after {
    display: none; } }

/*Equal Hight List Style*/
@media (max-width: 991px) {
  /*Equal Height List Style*/
  .header .mega-menu .equal-height-list h3 {
    color: #555;
    margin: 0 0 5px;
    font-size: 13px;
    font-weight: 400;
    padding: 6px 25px 5px;
    border-bottom: solid 1px #eee; }
  .header .mega-menu .equal-height-list li a {
    color: #687074;
    display: block;
    font-size: 13px;
    font-weight: 400;
    margin-left: 15px;
    padding: 6px 25px;
    border-bottom: solid 1px #eee; }
  .header .mega-menu .equal-height-list > h3.active,
  .header .mega-menu .equal-height-list > .active > a,
  .header .mega-menu .equal-height-list > .active > a:hover,
  .header .mega-menu .equal-height-list > .active > a:focus {
    color: #555;
    background-color: #e7e7e7; }
  .header .mega-menu .equal-height-list li a:hover {
    color: #333;
    text-decoration: none; } }

/*--------------------------------------------------
    [7. Header - Dark Option]
----------------------------------------------------*/
/*Header*/
.header.header-dark {
  background: #2d2d2d;
  border-bottom-color: #555; }

/*Topbar*/
.header.header-dark .topbar ul.languages {
  background: #444; }

.header.header-dark .topbar ul.loginbar li.topbar-devider {
  border-right-color: #666; }

.header.header-dark .topbar ul.languages li a {
  color: #bbb; }

.header.header-dark .topbar ul.languages:after {
  border-bottom-color: #444; }

.header.header-dark .topbar ul.languages li a:hover,
.header.header-dark .topbar ul.languages li.active a {
  color: #bbb;
  background: #555; }

/*Navbar Nav*/
.header.header-dark .navbar-nav > li > a {
  color: #ddd; }

.header.header-dark .navbar-nav > .open > a,
.header.header-dark .navbar-nav > .open > a:hover,
.header.header-dark .navbar-nav > .open > a:focus {
  background: inherit; }

@media (max-width: 991px) {
  .header.header-dark .navbar-nav > li a {
    border-bottom-color: #666; }
  .header.header-dark .navbar-nav .dropdown > a:hover {
    color: #72c02c;
    background: inherit; }
  .header.header-dark .navbar-nav .open > a,
  .header.header-dark .navbar-nav .open > a:hover,
  .header.header-dark .navbar-nav .open > a:focus {
    border-bottom-color: #666; }
  .header.header-dark .navbar-nav .open .dropdown-menu > li > a {
    color: #bbb; }
  .header.header-dark .navbar-nav .open .dropdown-menu > li > a:hover,
  .header.header-dark .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff !important; }
  .header.header-dark .navbar-nav .open .dropdown-menu > .active > a,
  .header.header-dark .navbar-nav .open .dropdown-menu > .active > a:hover,
  .header.header-dark .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #bbb;
    background: #444; }
  .header.header-dark .dropdown .dropdown-submenu > a {
    color: #bbb !important; } }

@media (min-width: 992px) {
  .header.header-dark .navbar-nav > li > a:hover,
  .header.header-dark .navbar-nav > .active > a {
    color: #72c02c !important; }
  .header.header-dark .dropdown-menu {
    background: #2d2d2d; }
  .header.header-dark .dropdown-menu li > a {
    color: #bbb;
    border-bottom-color: #666; }
  .header.header-dark .dropdown-menu .active > a,
  .header.header-dark .dropdown-menu li > a:hover {
    background: #202020 !important; } }

/*Search*/
.header.header-dark .nav > li > .search {
  background: #444; }

.header.header-dark .search-open {
  background: #2d2d2d;
  border-top-color: #555;
  box-shadow: 0 1px 3px #888; }

.header.header-dark .input-group .form-control {
  color: #bbb;
  background: #555;
  border-color: #999;
  box-shadow: 0 0 2px #555; }

/*Mega Menu*/
.header.header-dark .mega-menu .equal-height-list h3 {
  color: #eee; }

@media (max-width: 991px) {
  .header.header-dark .mega-menu .equal-height-list li a {
    color: #bbb;
    border-bottom-color: #666; }
  .header.header-dark .mega-menu .equal-height-list li a:focus,
  .header.header-dark .mega-menu .equal-height-list li a:hover {
    color: #fff; }
  .header.header-dark .mega-menu .equal-height-list h3 {
    border-bottom-color: #666; } }

@media (min-width: 992px) {
  .header.header-dark .mega-menu .equal-height-in {
    border-left-color: #666; } }

/*--------------------------------------------------
    [8. Header - Fixed]
----------------------------------------------------*/
/*Header*/
.header-fixed .header {
  height: auto; }

.header-fixed .header.header-sticky {
  margin-top: 0;
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }

/*z-index fixed in v1.9 for layer slider arrows*/
.header-fixed .header.header-fixed-shrink {
  z-index: 10100;
  border-bottom: none;
  box-shadow: 0 0 3px #bbb;
  background: rgba(255, 255, 255, 0.96);
  transition: all 0.3s ease-in-out; }

@media (max-width: 991px) {
  /*Header*/
  .header-fixed .header.header-sticky,
  .header-fixed .header.header-fixed-shrink {
    position: relative; } }

@media (min-width: 992px) {
  .header-fixed .header.header-fixed-shrink {
    margin-top: -35px; }
  .header-fixed .header.header-fixed-shrink > .container {
    margin-bottom: -46px; }
  /*Logo*/
  .header-fixed .header.header-fixed-shrink .logo img {
    max-width: 105px;
    margin: 45px 0 -4px; }
  /*Navbar Nav*/
  .header-fixed .header.header-fixed-shrink .navbar-nav > li > a {
    padding-top: 19px;
    padding-bottom: 19px; }
  .header-fixed .header.header-fixed-shrink .navbar-nav > li > a:hover,
  .header-fixed .header.header-fixed-shrink .navbar-nav > .active > a {
    border-bottom: none; }
  /*Dropdown*/
  .header-fixed .header.header-fixed-shrink .dropdown > a:after {
    top: 20px; }
  /*Search*/
  .header-fixed .header.header-fixed-shrink .nav > li > .search {
    color: #777;
    padding: 20px 0 22px;
    background: inherit;
    border-bottom: none; }
  .header-fixed .header.header-fixed-shrink .nav .search-open {
    top: 60px; } }

/*--------------------------------------------------
    [9. Header - Fixed Boxed]
----------------------------------------------------*/
.boxed-layout.header-fixed .header.header-sticky {
  left: inherit;
  width: inherit; }

/*----------------------------------------------------------------------

[-----*** TABLE OF CONTENT ***-----]

1. Header - Navbar
2. Header - Menu Inner Right Style
3. Header - Responsive Navbar Style
4. Header - Submenu
5. Header - Mega Menu
6. Header - Fixed
6.1. Header - Fixed Boxed
7. Header - Border Bottom Style Option
8. Header - White Transparent Background Option
9. Header - Dark Transparent Background Option
10. Header - Dark On Scroll Option
11. Header - Dark Search Option
12. Header - Dark Dropdown Background Option
13. Header - Dark Responsive Navbar Option
14. Header - Classic White Option
15. Header - Classic Dark Option
16. Header - Hamburger

[--- HEADER STYLE CLASSES --]

1. header-v6
2. header-border-bottom
3. header-white-transparent
4. header-dark-transparent
5. header-dark-scroll
6. header-dark-search
7. header-dark-dropdown
8. header-dark-res-nav
9. header-dark-classic-white
10. header-dark-classic-dark
-----------------------------------------------------------------------*/
/*--------------------------------------------------
	[1. Header - Navbar]
----------------------------------------------------*/
/*Header v6*/
.header-v6 {
  z-index: 99;
  position: relative; }

@media (max-width: 991px) {
  .header-v6 {
    position: static; } }

/*Containers
------------------------------------*/
@media (min-width: 768px) and (max-width: 991px) {
  .header-v6 .menu-container {
    width: 750px; } }

@media (max-width: 991px) {
  .header-v6 .menu-container:before,
  .header-v6 .menu-container:after {
    content: " ";
    display: table; }
  .header-v6 .menu-container:after {
    clear: both; }
  .header-v6 .menu-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px; }
  .header-v6 > .navbar > .container {
    width: inherit;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; }
  .header-v6 > .navbar > .container > .navbar-header,
  .header-v6 > .navbar > .container > .navbar-collapse {
    margin-left: 0;
    margin-right: 0; } }

/*Navbar*/
.header-v6 .navbar {
  border: none;
  margin-bottom: 0; }

/*Navbar Brand*/
.header-v6 .navbar-brand {
  height: 93px;
  max-height: 93px;
  line-height: 65px; }

.header-v6 .navbar-brand img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle; }

.header-v6 .navbar-brand img.shrink-logo {
  display: none; }

.header-v6 .navbar-brand img.default-logo {
  display: inherit; }

/*Media Queries*/
@media (max-width: 991px) {
  .header-v6 .navbar-brand {
    padding-top: 20px; }
  .header-v6 .navbar-brand img.shrink-logo {
    display: inherit; }
  .header-v6 .navbar-brand img.default-logo {
    display: none; } }

/*Navbar Toggle*/
.header-v6 .navbar-toggle {
  border: none;
  margin: 31px 0 31px 15px; }

.header-v6 .navbar-toggle .icon-bar {
  height: 1px;
  width: 20px;
  font-size: 20px;
  background: #555;
  margin-bottom: 5px; }

.header-v6 .navbar-toggle .icon-bar:last-child {
  margin-bottom: 0; }

.header-v6 .navbar-toggle,
.header-v6 .navbar-toggle:hover,
.header-v6 .navbar-toggle:focus {
  background: inherit; }

/*Navbar Collapse*/
.header-v6 .navbar-collapse {
  position: relative; }

/*Navbar Menu*/
.header-v6 .navbar-nav > li > a {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase; }

.header-v6 .nav > li > a:hover,
.header-v6 .nav > li > a:focus {
  background: inherit; }

@media (min-width: 992px) {
  .navbar {
    border-radius: 0; }
  .header-v6 .navbar-nav {
    float: right; } }

/*--------------------------------------------------
	[2. Header - Menu Inner Right Style]
----------------------------------------------------*/
/*Menu Inner Right
------------------------------------*/
.header-v6 .header-inner-right {
  float: right;
  z-index: 999;
  margin-left: -20px;
  position: relative; }

@media (max-width: 350px) {
  .header-v6 .header-inner-right {
    display: none; } }

.header-v6 .menu-icons-list {
  margin: 0;
  list-style: none; }

.header-v6 li.menu-icons {
  position: relative;
  display: inline-block; }

.header-v6 li.menu-icons:first-child {
  padding-right: 15px; }

.header-v6 li.menu-icons i.menu-icons-style {
  color: #fff;
  width: 25px;
  cursor: pointer;
  font-size: 16px;
  line-height: 94px;
  text-align: center;
  display: inline-block; }

.header-v6 li.menu-icons span.badge {
  top: 28px;
  left: 12px;
  color: #fff;
  width: 18px;
  height: 18px;
  padding: 4px;
  display: block;
  font-size: 11px;
  text-align: center;
  position: absolute;
  background: #72c02c;
  display: inline-block;
  border-radius: 50% !important; }

/*Shopping Cart*/
.header-v6 .shopping-cart .shopping-cart-open {
  right: 0;
  top: 94px;
  padding: 15px;
  display: none;
  min-width: 250px;
  background: #fff;
  position: absolute;
  border: 1px solid #e7e7e7;
  border-top: 2px solid #72c02c; }

.header-v6 .shopping-cart:hover .shopping-cart-open {
  display: block; }

.header-v6 .shopping-cart-open span.shc-title {
  color: #444;
  display: block;
  font-size: 12px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  text-transform: uppercase;
  border-bottom: 1px solid #e7e7e7; }

.header-v6 .shopping-cart-open span.shc-total {
  color: #444;
  float: right;
  font-size: 12px;
  margin-top: 6px;
  text-transform: uppercase; }

.header-v6 .shopping-cart-open span.shc-total strong {
  padding-left: 3px; }

.header-v6 .shopping-cart-open .btn-u {
  padding: 4px 12px; }

/*Search Box
------------------------------------*/
.header-v6 .search-open {
  top: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  height: auto;
  display: none;
  padding: 10px;
  position: fixed;
  background: #fff; }

.header-v6 .search-open .form-control {
  color: #555;
  border: none;
  height: 75px;
  font-size: 33px;
  font-weight: 200;
  box-shadow: none;
  background: #fff;
  padding: 0 14% 0 13%;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif; }

@media (max-width: 768px) {
  .header-v6 .search-open .form-control {
    font-size: 20px; } }

@media (max-width: 991px) {
  .header-v6 .search-open .form-control {
    padding: 0 28% 0 5%; } }

.header-v6 .search-open .form-control::-moz-placeholder {
  color: #555; }

.header-v6 .search-open .form-control:-ms-input-placeholder {
  color: #555; }

.header-v6 .search-open .form-control::-webkit-input-placeholder {
  color: #555; }

.header-v6 .search-open .form-control:hover,
.header-v6 .search-open .form-control:active,
.header-v6 .search-open .form-control:focus {
  border: none;
  box-shadow: none; }

.header-v6 .search-close {
  z-index: 999;
  position: relative; }

.header-v6 li.menu-icons i.search-btn {
  color: #fff; }

.header-v6 li.menu-icons i.fa-times {
  color: #444; }

/*--------------------------------------------------
	[3. Header - Responsive Navbar Style]
----------------------------------------------------*/
/*Responsive Navbar*/
@media (max-width: 991px) {
  /*Responsive code for max-width: 991px*/
  .header-v6 .navbar-header {
    float: none; }
  .header-v6 .navbar-toggle {
    display: block; }
  .header-v6 .navbar-collapse {
    border-color: #eee;
    border-bottom: 1px solid #eee; }
  .header-v6 .navbar-collapse.collapse {
    display: none !important; }
  .header-v6 .navbar-collapse.collapse.in {
    display: block !important;
    overflow-y: auto !important; }
  .header-v6 .navbar-nav {
    margin: 0;
    float: none !important; }
  .header-v6 .navbar-nav > li {
    float: none; }
  .header-v6 .navbar-nav > li > a {
    padding-top: 30px;
    padding-bottom: 40px; }
  /*Navbar Nav*/
  .header-v6 .navbar-nav > li > a {
    color: #444; }
  /*Pull Right*/
  .header-v6 .dropdown-menu.pull-right {
    float: none !important; }
  /*Dropdown Menu Slide Down Effect*/
  .header-v6 .navbar-nav .open .dropdown-menu {
    border: 0;
    float: none;
    width: auto;
    margin-top: 0;
    position: static;
    box-shadow: none;
    background-color: transparent; }
  .header-v6 .navbar-nav .open .dropdown-menu > li > a,
  .header-v6 .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px; }
  .header-v6 .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px; }
  .header-v6 .navbar-nav .open .dropdown-menu > li > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none; }
  .header-v6 .navbar-nav .open .dropdown-menu > li > a {
    color: #777; }
  .header-v6 .navbar-nav .open .dropdown-menu > li > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #72c02c;
    background-color: transparent; }
  .header-v6 .navbar-nav .open .dropdown-menu > .active > a,
  .header-v6 .navbar-nav .open .dropdown-menu > .active > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #72c02c;
    background-color: transparent; }
  .header-v6 .navbar-nav .open .dropdown-menu > .disabled > a,
  .header-v6 .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #72c02c;
    background-color: transparent; }
  .header-v6 .dropdown-menu.no-bottom-space {
    padding-bottom: 0; }
  /*Design for max-width: 991px*/
  .header-v6 .navbar-collapse,
  .header-v6 .navbar-collapse .container {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .header-v6 .navbar-nav > li > a {
    font-size: 14px;
    padding: 12px 5px; }
  .header-v6 .navbar-nav > li a {
    border-bottom: none;
    border-top: 1px solid #eee; }
  .header-v6 .navbar-nav > li:first-child > a {
    border-top: transparent; }
  .header-v6 .navbar-nav > li > a:hover {
    color: #72c02c; }
  .header-v6 .navbar-nav > .open > a,
  .header-v6 .navbar-nav > .open > a:hover,
  .header-v6 .navbar-nav > .open > a:focus {
    color: #555;
    background: inherit; }
  .header-v6 .navbar-nav > .active > a,
  .header-v6 .navbar-nav > .active > a:hover,
  .header-v6 .navbar-nav > .active > a:focus {
    background: inherit;
    color: #72c02c !important; }
  .header-v6 .dropdown .dropdown-submenu > a {
    font-size: 13px;
    color: #555 !important;
    text-transform: uppercase; } }

@media (min-width: 992px) {
  /*Navbar Collapse*/
  .header-v6 .navbar-collapse {
    padding: 0; }
  /*Navbar*/
  .header-v6 .navbar {
    min-height: 40px !important; }
  .header-v6 .container > .navbar-header,
  .header-v6 .container-fluid > .navbar-header,
  .header-v6 .container > .navbar-collapse,
  .header-v6 .container-fluid > .navbar-collapse {
    margin-bottom: -10px; }
  .header-v6 .navbar-nav {
    top: 2px;
    position: relative; }
  .header-v6 .navbar-nav > li > a {
    padding: 20px;
    line-height: 54px; }
  .header-v6 .navbar-nav > li > a,
  .header-v6 .navbar-nav > li > a:hover,
  .header-v6 .navbar-nav > li > a:focus,
  .header-v6 .navbar-nav > .active > a,
  .header-v6 .navbar-nav > .active > a:hover,
  .header-v6 .navbar-nav > .active > a:focus {
    color: #fff;
    font-weight: 200;
    background: none; }
  .header-v6 .navbar-nav > .open > a,
  .header-v6 .navbar-nav > .open > a:hover,
  .header-v6 .navbar-nav > .open > a:focus {
    color: #fff; }
  /*Dropdown Menu*/
  .header-v6 .dropdown-menu {
    border: none;
    padding: 15px 0;
    min-width: 230px;
    border-radius: 0;
    background: #fff;
    z-index: 9999 !important;
    border-top: solid 3px #72c02c; }
  .header-v6 .dropdown-menu li a {
    color: #777;
    font-size: 13px;
    font-weight: 400;
    padding: 6px 25px; }
  .header-v6 .dropdown-menu .active > a,
  .header-v6 .dropdown-menu li > a:hover {
    color: #72c02c;
    background: inherit;
    filter: none !important;
    transition: all .1s ease-in-out; }
  .header-v6 .dropdown-menu li > a:focus {
    background: none;
    filter: none !important; }
  .header-v6 .navbar-nav > li.dropdown:hover > .dropdown-menu {
    display: block; }
  .header-v6 .open > .dropdown-menu {
    display: none; } }

/*--------------------------------------------------
	[4. Header - Submenu]
----------------------------------------------------*/
/*Dropdown Submenu for BS3
------------------------------------*/
.header-v6 .dropdown-submenu {
  position: relative; }

.header-v6 .dropdown-submenu > a:after {
  top: 9px;
  right: 15px;
  font-size: 11px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome; }

@media (max-width: 991px) {
  .header-v6 .dropdown-submenu > a:after {
    content: " "; } }

.header-v6 .dropdown-submenu > .dropdown-menu {
  top: -20px;
  left: 100%;
  margin-left: 0px; }

/*Submenu comes from LEFT side*/
.header-v6 .dropdown-submenu > .dropdown-menu.submenu-left {
  left: -100%; }

.header-v6 .dropdown-submenu:hover > .dropdown-menu {
  display: block; }

@media (max-width: 991px) {
  .header-v6 .dropdown-submenu > .dropdown-menu {
    display: block;
    margin-left: 15px; } }

.header-v6 .dropdown-submenu.pull-left {
  float: none; }

.header-v6 .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px; }

.header-v6 .dropdown-menu li [class^="fa-"],
.header-v6 .dropdown-menu li [class*=" fa-"] {
  left: -3px;
  width: 1.25em;
  margin-right: 1px;
  position: relative;
  text-align: center;
  display: inline-block; }

.header-v6 .dropdown-menu li [class^="fa-"].fa-lg,
.header-v6 .dropdown-menu li [class*=" fa-"].fa-lg {
  /* increased font size for fa-lg */
  width: 1.5625em; }

/*--------------------------------------------------
	[5. Header - Mega Menu]
----------------------------------------------------*/
/*Mega Menu
------------------------------------*/
.header-v6 .mega-menu .nav,
.header-v6 .mega-menu .dropup,
.header-v6 .mega-menu .dropdown,
.header-v6 .mega-menu .collapse {
  position: static; }

.header-v6 .mega-menu .navbar-inner,
.header-v6 .mega-menu .container {
  position: relative; }

.header-v6 .mega-menu .dropdown-menu {
  left: auto; }

.header-v6 .mega-menu .dropdown-menu > li {
  display: block; }

.header-v6 .mega-menu .dropdown-submenu .dropdown-menu {
  left: 100%; }

.header-v6 .mega-menu .nav.pull-right .dropdown-menu {
  right: 0; }

.header-v6 .mega-menu .mega-menu-content {
  *zoom: 1;
  padding: 0; }

.header-v6 .mega-menu .mega-menu-content:before,
.header-v6 .mega-menu .mega-menu-content:after {
  content: "";
  display: table;
  line-height: 0; }

.header-v6 .mega-menu .mega-menu-content:after {
  clear: both; }

.header-v6 .mega-menu.navbar .nav > li > .dropdown-menu:after,
.header-v6 .mega-menu.navbar .nav > li > .dropdown-menu:before {
  display: none; }

.header-v6 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
  left: 0;
  right: 0;
  overflow: hidden; }

@media (min-width: 992px) {
  .header-v6 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
    margin-left: 10px;
    margin-right: 15px; } }

/*Equal Height Lists*/
@media (min-width: 992px) {
  .header-v6 .mega-menu .equal-height {
    display: flex;
    /*-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;*/ }
  .header-v6 .mega-menu .equal-height-in {
    display: flex; } }

/*Mega Menu Style Effect*/
.header-v6 .mega-menu .equal-height-list h3 {
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 0 10px 0 13px;
  text-transform: uppercase; }

/*Space for only Big Resolution*/
@media (min-width: 992px) {
  .header-v6 .mega-menu .big-screen-space {
    margin-bottom: 20px; } }

/*Mega Menu Content*/
@media (min-width: 992px) {
  .header-v6 .mega-menu .equal-height-in {
    padding: 20px 0;
    border-left: 1px solid #bbb; }
  .header-v6 .mega-menu .equal-height-in:first-child {
    border-left: none;
    margin-left: -1px; }
  .header-v6 .mega-menu .equal-height-list {
    width: 100%; }
  .header-v6 .mega-menu .equal-height-list li a {
    display: block;
    position: relative;
    border-bottom: none;
    padding: 5px 10px 5px 15px; }
  .header-v6 .mega-menu .equal-height-list a:hover {
    text-decoration: none; }
  /*Dropdown Arrow Icons*/
  .header-v6 .mega-menu .mega-menu-fullwidth li a {
    padding: 5px 10px 5px 30px; }
  .header-v6 .mega-menu .mega-menu-fullwidth li a:after {
    top: 7px;
    left: 15px;
    font-size: 11px;
    content: "\f105";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome; }
  /*Dropdown Arrow Icons Disable class*/
  .header-v6 .mega-menu .mega-menu-fullwidth .disable-icons li a {
    padding: 5px 10px 5px 15px; }
  .header-v6 .mega-menu .mega-menu-fullwidth .disable-icons li a:after {
    display: none; } }

/*Space Effect inside the container to avoid scroll bar*/
@media (min-width: 737px) and (max-width: 991px) {
  .header-v6 .mega-menu .mega-menu-content .container {
    width: 690px !important; }
  .header-v6 .mega-menu .equal-height {
    margin-right: 0; }
  .header-v6 .mega-menu .equal-height-in {
    padding-right: 0;
    margin-right: -15px; } }

@media (max-width: 991px) {
  .header-v6 .mega-menu .equal-height {
    margin-right: 0; }
  .header-v6 .mega-menu .equal-height-in {
    padding-right: 0;
    padding-left: 5px; } }

/*Equal Hight List Style*/
@media (max-width: 991px) {
  .header-v6 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
    width: auto; }
  .header-v6 .mega-menu .equal-height-in,
  .header-v6 .mega-menu .equal-height-list {
    display: block; }
  .header-v6 .mega-menu .mega-menu-fullwidth .dropdown-menu > li > ul {
    display: block; }
  /*Equal Height List Style*/
  .header-v6 .mega-menu .equal-height-list h3 {
    color: #555;
    margin: 0 0 5px;
    font-size: 13px;
    font-weight: 400;
    padding: 6px 15px 5px; }
  .header-v6 .mega-menu .equal-height-list li a {
    color: #777;
    display: block;
    font-size: 13px;
    font-weight: 400;
    margin-left: 15px;
    padding: 6px 25px; }
  .header-v6 .mega-menu .equal-height-list > h3.active,
  .header-v6 .mega-menu .equal-height-list > .active > a,
  .header-v6 .mega-menu .equal-height-list > .active > a:hover,
  .header-v6 .mega-menu .equal-height-list > .active > a:focus {
    color: #72c02c;
    background-color: transparent; }
  .header-v6 .mega-menu .equal-height-list li a:hover {
    color: #72c02c;
    text-decoration: none; } }

/*--------------------------------------------------
	[6. Header - Fixed]
----------------------------------------------------*/
/*Header Fixed
------------------------------------*/
/*Media Queries*/
@media (min-width: 992px) {
  /*Header Sticky*/
  .header-fixed .header-v6.header-sticky {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    position: fixed; }
  .header-fixed .header-v6.header-fixed-shrink {
    border-bottom: none;
    box-shadow: 0 0 3px #bbb; }
  .header-fixed .header-v6.header-fixed-shrink .navbar {
    background: #fff; }
  /*Navbar*/
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav > li > a {
    color: #555; }
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav li > a:hover {
    color: #72c02c; }
  /*Navbar Brand*/
  .header-fixed .header-v6.header-fixed-shrink .navbar-brand img.default-logo {
    display: none; }
  .header-fixed .header-v6.header-fixed-shrink .navbar-brand img.shrink-logo {
    display: inherit; }
  /*Menu Inner Right*/
  .header-fixed .header-v6.header-fixed-shrink li.menu-icons i.menu-icons-style {
    color: #555; }
  /*Transition*/
  .header-fixed .header-v6.header-fixed-shrink .navbar-brand,
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav > li > a,
  .header-fixed .header-v6.header-fixed-shrink li.menu-icons i.menu-icons-style,
  .header-fixed .header-v6.header-fixed-shrink li.menu-icons span.badge,
  .header-fixed .header-v6.header-fixed-shrink .shopping-cart .shopping-cart-open,
  .header-fixed .header-v6.header-fixed-shrink .search-open .form-control {
    transition: all 0.4s cubic-bezier(0.7, 1, 0.7, 1); }
  /*Navbar Brand*/
  .header-fixed .header-v6.header-fixed-shrink .navbar-brand {
    height: 70px;
    max-height: 70px;
    line-height: 39px; }
  /*Navbar Nav*/
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav > li > a {
    line-height: 30px; }
  /*Menu Icons*/
  .header-fixed .header-v6.header-fixed-shrink li.menu-icons i.menu-icons-style {
    line-height: 70px; }
  .header-fixed .header-v6.header-fixed-shrink li.menu-icons span.badge {
    top: 17px; }
  .header-fixed .header-v6.header-fixed-shrink .shopping-cart .shopping-cart-open {
    top: 70px; }
  /*Search*/
  .header-fixed .header-v6.header-fixed-shrink .search-open {
    border-bottom: 1px solid #eee; }
  .header-fixed .header-v6.header-fixed-shrink .search-open .form-control {
    height: 49px;
    font-size: 25px; } }

/*Media Queries*/
@media (max-width: 991px) {
  /*Header*/
  .header-fixed .header-v6,
  .header-fixed .header-v6.header-fixed-shrink {
    height: inherit;
    position: relative; }
  /*Header Inner Right*/
  .header-fixed .header-v6.header-fixed-shrink .header-inner-right,
  .header-fixed .header-v6.header-fixed-shrink .menu-icons-list {
    margin-left: 0;
    /*margin-right: 20px;*/ }
  /*Menu Icons*/
  .header-v6 li.menu-icons i.menu-icons-style {
    color: #555; } }

/*--------------------------------------------------
    [6.1. Header - Fixed Boxed]
----------------------------------------------------*/
.boxed-layout.header-fixed .header-v6.header-sticky {
  left: inherit;
  width: inherit; }

/*--------------------------------------------------
	[7. Header - Border Bottom Style Option]
----------------------------------------------------*/
/*Navbar*/
.header-v6.header-border-bottom .navbar {
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5); }

.header-fixed .header-v6.header-border-bottom.header-fixed-shrink .navbar {
  border-bottom: none; }

/*Media Queries*/
@media (min-width: 992px) {
  .header-v6.header-border-bottom .navbar {
    background: transparent; }
  .header-fixed .header-v6.header-border-bottom.header-fixed-shrink .navbar {
    background: #fff; } }

/*--------------------------------------------------
	[8. Header - White Transparent Background Option]
----------------------------------------------------*/
/*Navbar*/
.header-v6.header-white-transparent .navbar {
  background: rgba(255, 255, 255, 0.5); }

/*Media Queries*/
@media (min-width: 992px) {
  .header-fixed .header-v6.header-white-transparent.header-fixed-shrink .navbar {
    background: #fff; } }

/*--------------------------------------------------
	[9. Header - Dark Transparent Background Option]
----------------------------------------------------*/
/*Navbar*/
.header-v6.header-dark-transparent .navbar {
  background: rgba(0, 0, 0, 0.5); }

/*Media Queries*/
@media (min-width: 992px) {
  /*Header*/
  .header-fixed .header-v6.header-dark-transparent.header-fixed-shrink {
    box-shadow: none; }
  .header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar {
    background: #2a2b2b; }
  /*Navbar Brand*/
  .header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-brand img.default-logo {
    display: inherit; }
  .header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-brand img.shrink-logo {
    display: none; }
  /*Navbar*/
  .header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-nav > li > a {
    color: #fff; }
  .header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-dark-transparent.header-fixed-shrink .navbar-nav li > a:hover {
    color: #72c02c; }
  .header-fixed .header-v6.header-dark-transparent.header-fixed-shrink li.menu-icons i.menu-icons-style {
    color: #fff; } }

@media (max-width: 991px) {
  /*Header*/
  .header-fixed .header-v6.header-dark-transparent .navbar {
    background: #fff; } }

/*Search*/
.header-v6.header-dark-transparent li.menu-icons i.fa-times,
.header-fixed .header-v6.header-dark-transparent.header-fixed-shrink li.menu-icons i.fa-times {
  color: #555; }

/*--------------------------------------------------
	[10. Header - Dark On Scroll Option]
----------------------------------------------------*/
/*Media Queries*/
@media (min-width: 992px) {
  /*Header*/
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink {
    box-shadow: none;
    background: #2a2b2b; }
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar {
    background-color: inherit; }
  /*Navbar Brand*/
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-brand img.default-logo {
    display: inherit; }
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-brand img.shrink-logo {
    display: none; }
  /*Navbar*/
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav > li > a {
    color: #fff; }
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav li > a:hover {
    color: #72c02c; }
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink li.menu-icons i.menu-icons-style {
    color: #fff; } }

/*Search*/
.header-v6.header-dark-scroll li.menu-icons i.fa-times,
.header-fixed .header-v6.header-dark-scroll.header-fixed-shrink li.menu-icons i.fa-times {
  color: #555; }

/*--------------------------------------------------
	[11. Header - Dark Search Option]
----------------------------------------------------*/
/*Media Queries*/
@media (min-width: 992px) {
  /*Navbar*/
  .header-fixed .header-v6.header-dark-search.header-fixed-shrink {
    box-shadow: none; }
  .header-fixed .header-v6.header-dark-search.header-dark-scroll.header-fixed-shrink .navbar {
    background: inherit; }
  .header-fixed .header-v6.header-dark-search.header-classic-white.header-fixed-shrink {
    box-shadow: none; }
  /*Search*/
  .header-v6.header-dark-search .search-open {
    background: #2a2b2b;
    border-bottom: 1px solid #555; }
  .header-fixed .header-v6.header-dark-search.header-fixed-shrink .search-open {
    border-bottom-color: #555; }
  .header-v6.header-dark-search .search-open .form-control {
    color: #fff;
    background: #2a2b2b; }
  .header-v6.header-dark-search .search-open .form-control::-moz-placeholder {
    color: #fff; }
  .header-v6.header-dark-search .search-open .form-control:-ms-input-placeholder {
    color: #fff; }
  .header-v6.header-dark-search .search-open .form-control::-webkit-input-placeholder {
    color: #fff; }
  .header-v6.header-dark-search li.menu-icons i.fa-times,
  .header-fixed .header-v6.header-dark-search.header-fixed-shrink li.menu-icons i.fa-times {
    color: #fff; } }

/*--------------------------------------------------
	[12. Header - Dark Dropdown Option]
----------------------------------------------------*/
/*Shopping Cart*/
.header-v6.header-dark-dropdown .shopping-cart .shopping-cart-open {
  background: #2a2b2b;
  border-color: #555;
  border-top-color: #72c02c; }

.header-v6.header-dark-dropdown .shopping-cart-open span.shc-title {
  color: #fff;
  border-bottom-color: #555; }

.header-v6.header-dark-dropdown .shopping-cart-open span.shc-total {
  color: #fff; }

/*Media Queries*/
@media (min-width: 992px) {
  /*Dropdown*/
  .header-v6.header-dark-dropdown .dropdown-menu {
    background: #2a2b2b; }
  .header-v6.header-dark-dropdown .dropdown-menu li a {
    color: #ccc; }
  .header-v6.header-dark-dropdown .dropdown-menu .active > a,
  .header-v6.header-dark-dropdown .dropdown-menu li > a:hover {
    color: #72c02c; }
  /*Equal Height List Style*/
  .header-v6.header-dark-dropdown .mega-menu .equal-height-in {
    border-left-color: #777; }
  .header-v6.header-dark-dropdown .mega-menu .equal-height-list h3 {
    color: #fff; }
  .header-v6.header-dark-dropdown .mega-menu .equal-height-list li a {
    color: #ccc; }
  .header-v6.header-dark-dropdown .mega-menu .equal-height-list .active > a,
  .header-v6.header-dark-dropdown .mega-menu .equal-height-list li a:hover {
    color: #72c02c; } }

/*--------------------------------------------------
	[13. Header - Dark Responsive Navbar Option]
----------------------------------------------------*/
/*Media Queries*/
@media (max-width: 991px) {
  .header-v6.header-dark-res-nav {
    background: #2a2b2b; }
  /*Navbar Brand*/
  .header-fixed .header-v6.header-dark-res-nav .navbar-brand img.default-logo {
    display: inherit; }
  .header-fixed .header-v6.header-dark-res-nav .navbar-brand img.shrink-logo {
    display: none; }
  /*Toggle*/
  .header-v6.header-dark-res-nav .navbar-toggle .icon-bar {
    background: #fff; }
  /*Menu Inner Right*/
  .header-v6.header-dark-res-nav li.menu-icons i.menu-icons-style {
    color: #fff; }
  /*Search*/
  .header-v6.header-dark-res-nav .search-open {
    background: #2a2b2b;
    border-bottom: 1px solid #555; }
  .header-fixed .header-v6.header-dark-res-nav.header-fixed-shrink .search-open {
    border-bottom-color: #555; }
  .header-v6.header-dark-res-nav .search-open .form-control {
    color: #fff;
    background: #2a2b2b; }
  .header-v6.header-dark-res-nav .search-open .form-control::-moz-placeholder {
    color: #fff; }
  .header-v6.header-dark-res-nav .search-open .form-control:-ms-input-placeholder {
    color: #fff; }
  .header-v6.header-dark-res-nav .search-open .form-control::-webkit-input-placeholder {
    color: #fff; }
  .header-v6.header-dark-res-nav li.menu-icons i.fa-times,
  .header-fixed .header-v6.header-dark-res-nav.header-fixed-shrink li.menu-icons i.fa-times {
    color: #fff; }
  /*Navbar Collapse*/
  .header-v6.header-dark-res-nav .navbar-collapse {
    border-color: #555;
    border-bottom-color: #555; }
  /*Navbar Nav*/
  .header-v6.header-dark-res-nav .navbar-nav > li a {
    color: #ccc;
    border-top-color: #555; }
  .header-v6.header-dark-res-nav .navbar-nav > li a:hover {
    color: #72c02c; }
  .header-v6.header-dark-res-nav .navbar-nav > .open > a,
  .header-v6.header-dark-res-nav .navbar-nav > .open > a:hover,
  .header-v6.header-dark-res-nav .navbar-nav > .open > a:focus {
    color: #fff; }
  /*Dropdown*/
  .header-v6.header-dark-res-nav .navbar-nav .open .dropdown-menu > li > a {
    color: #ccc; }
  .header-v6.header-dark-res-nav .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #72c02c; }
  /*Submenu*/
  .header-v6.header-dark-res-nav .dropdown .dropdown-submenu > a {
    color: #fff !important; }
  /*Mega Menu*/
  .header-v6.header-dark-res-nav .mega-menu .equal-height-list h3 {
    color: #fff; } }

/*--------------------------------------------------
	[14. Header - Classic White Option]
----------------------------------------------------*/
/*Media Queries*/
@media (min-width: 992px) {
  /*Header Sticky*/
  .header-v6.header-classic-white .navbar {
    background: #fff; }
  /*Navbar*/
  .header-fixed .header-v6.header-classic-white .navbar-nav > li > a {
    color: #555;
    font-weight: 400; }
  .header-fixed .header-v6.header-classic-white .navbar-nav .active > a,
  .header-fixed .header-v6.header-classic-white .navbar-nav li > a:hover {
    color: #72c02c; }
  /*Navbar Brand*/
  .header-fixed .header-v6.header-classic-white .navbar-brand img.default-logo {
    display: none; }
  .header-fixed .header-v6.header-classic-white .navbar-brand img.shrink-logo {
    display: inherit; }
  /*Menu Inner Right*/
  .header-fixed .header-v6.header-classic-white li.menu-icons i.menu-icons-style {
    color: #555; }
  .header-fixed .header-v6.header-classic-white.header-dark-search li.menu-icons i.fa-times {
    color: #fff; } }

/*--------------------------------------------------
	[15. Header - Classic Dark Option]
----------------------------------------------------*/
/*Shopping Cart*/
.header-v6.header-classic-dark .shopping-cart .shopping-cart-open {
  background: #2a2b2b;
  border-color: #555;
  border-top-color: #72c02c; }

.header-v6.header-classic-dark .shopping-cart-open span.shc-title {
  color: #fff;
  border-bottom-color: #555; }

.header-v6.header-classic-dark .shopping-cart-open span.shc-total {
  color: #fff; }

/*Media Queries*/
@media (min-width: 992px) {
  /*Header*/
  .header-v6.header-classic-dark,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink {
    box-shadow: none;
    background: #2a2b2b; }
  .header-v6.header-classic-dark .navbar,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar {
    background-color: inherit; }
  /*Navbar Brand*/
  .header-v6.header-classic-dark .navbar-brand img.default-logo,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-brand img.default-logo {
    display: inherit; }
  .header-v6.header-classic-dark .navbar-brand img.shrink-logo,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-brand img.shrink-logo {
    display: none; }
  /*Navbar*/
  .header-v6.header-classic-dark .navbar-nav > li > a,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav > li > a {
    color: #fff; }
  .header-v6.header-classic-dark .navbar-nav .active > a,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav .active > a,
  .header-v6.header-classic-dark .navbar-nav li > a:hover,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav li > a:hover {
    color: #72c02c; }
  .header-v6.header-classic-dark li.menu-icons i.menu-icons-style,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink li.menu-icons i.menu-icons-style {
    color: #fff; }
  /*Search*/
  .header-v6.header-classic-dark .search-open {
    background: #2a2b2b; }
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .search-open {
    border-bottom: none; }
  .header-v6.header-classic-dark .search-open .form-control {
    color: #fff;
    background: #2a2b2b; }
  .header-v6.header-classic-dark .search-open .form-control::-moz-placeholder {
    color: #fff; }
  .header-v6.header-classic-dark .search-open .form-control:-ms-input-placeholder {
    color: #fff; }
  .header-v6.header-classic-dark .search-open .form-control::-webkit-input-placeholder {
    color: #fff; }
  .header-v6.header-classic-dark li.menu-icons i.fa-times,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink li.menu-icons i.fa-times {
    color: #fff; }
  /*Dropdown*/
  .header-v6.header-classic-dark .dropdown-menu {
    background: #2a2b2b; }
  .header-v6.header-classic-dark .dropdown-menu li a {
    color: #ccc; }
  .header-v6.header-classic-dark .dropdown-menu .active > a,
  .header-v6.header-classic-dark .dropdown-menu li > a:hover {
    color: #72c02c; }
  /*Equal Height List Style*/
  .header-v6.header-classic-dark .mega-menu .equal-height-in {
    border-left-color: #777; }
  .header-v6.header-classic-dark .mega-menu .equal-height-list h3 {
    color: #fff; }
  .header-v6.header-classic-dark .mega-menu .equal-height-list li a {
    color: #ccc; }
  .header-v6.header-classic-dark .mega-menu .equal-height-list .active > a,
  .header-v6.header-classic-dark .mega-menu .equal-height-list li a:hover {
    color: #72c02c; } }

/*--------------------------------------------------
	[16. Header - Hamburger]
----------------------------------------------------*/
.header-v6 .sliding-panel__btn {
  display: block; }

.header-v6 .sliding-panel__btn .icon-bar {
  background: #fff; }

.header-v6 .sliding-panel__btn--dark .icon-bar {
  background: #555; }

@media (min-width: 992px) {
  .header-fixed .header-v6.header-fixed-shrink .sliding-panel__btn {
    margin-top: 20px;
    margin-bottom: 0; }
  .header-fixed .header-v6.header-fixed-shrink .sliding-panel__btn .icon-bar {
    background: #555; }
  /*Transition*/
  .header-fixed .header-v6.header-fixed-shrink .sliding-panel__btn,
  .header-fixed .header-v6.header-fixed-shrink .sliding-panel__btn .icon-bar {
    transition: all 0.4s cubic-bezier(0.7, 1, 0.7, 1); } }

@media (max-width: 991px) {
  .header-v6 .sliding-panel__btn .icon-bar {
    background: #555; } }

/*Footer Default
------------------------------------*/
.footer-default .footer {
  color: #dadada;
  background: #585f69;
  padding: 20px 0 30px; }

.footer.margin-top-0 {
  margin-top: 0; }

.footer-default .footer p,
.footer-default .footer a {
  font-size: 14px; }

.footer-default .footer p {
  color: #dadada; }

.footer-default .footer a {
  color: #72c02c; }

.footer-default .footer a:hover {
  color: #5fb611;
  transition: all 0.4s ease-in-out; }

.footer-default .footer h2,
.footer-default .footer h3,
.footer-default .footer h4 {
  color: #e4e4e4;
  background: none;
  text-shadow: none; }

/*Footer Subsribe*/
.footer-default .footer .footer-subsribe {
  width: 85%; }

/*Footer Blog Posts*/
.footer-default .footer .blog-list {
  margin: 0;
  padding: 0;
  color: #dadada;
  list-style: none; }

.footer-default .footer .blog-list li {
  padding: 0 0;
  margin-bottom: 7px; }

.footer-default .footer .blog-list li span {
  color: #818488; }

.footer-default .footer .dl-horizontal,
.footer-default .footer .dl-horizontal:hover {
  border: none !important;
  background: none !important; }

.footer-default .footer .posts .dl-horizontal a {
  color: #dadada; }

.footer-default .footer .dl-horizontal a:hover {
  color: #5fb611 !important; }

.footer-default .footer .dl-horizontal img {
  border-color: #888 !important; }

/*Copyright*/
.footer-default .copyright {
  font-size: 12px;
  padding: 11px 0 7px;
  background: #3e4753; }

.footer-default .copyright p {
  color: #dadada;
  margin-top: 12px; }

.footer-default .copyright a {
  margin: 0 5px;
  color: #72c02c; }

.footer-default .copyright a:hover {
  color: #5fb611; }

.footer-default .copyright a img {
  margin-top: 5px; }

/*Footer-v2
------------------------------------*/
.footer-v2 .footer {
  padding: 40px 0;
  background: #f7f7f7;
  border-top: 3px solid #eee; }

.footer-v2 .footer p {
  color: #555; }

.footer-v2 .headline h2 {
  color: #585f69; }

/*Footer Subsribe*/
.footer-v2 .footer-subsribe {
  width: 100%; }

/*Link List*/
.footer-v2 .link-list li {
  border-top: solid 1px #eee; }

.footer-v2 .link-list a {
  color: #555; }

.footer-v2 address {
  color: #555;
  line-height: 23px; }

.footer-v2 address i {
  min-width: 20px;
  margin-right: 5px;
  text-align: center; }

/*Copyright*/
.footer-v2 .copyright {
  border-top: none;
  background: #eee;
  padding: 20px 0 15px; }

.footer-v2 .copyright p {
  color: #555;
  margin-bottom: 0; }

/*404 Error Page v1
------------------------------------*/
.error-v1 {
  padding-bottom: 30px;
  text-align: center; }

.error-v1 p {
  color: #555;
  font-size: 16px; }

.error-v1 span {
  color: #555;
  display: block;
  font-size: 35px;
  font-weight: 200; }

.error-v1 span.error-v1-title {
  color: #777;
  font-size: 180px;
  line-height: 200px;
  padding-bottom: 20px; }

/*For Mobile Devices*/
@media (max-width: 500px) {
  .error-v1 p {
    font-size: 12px; }
  .error-v1 span {
    font-size: 25px; }
  .error-v1 span.error-v1-title {
    font-size: 140px; } }

body {
  font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif; }

p,
li {
  font-size: 16px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", "Open Sans", Arial, sans-serif;
  font-weight: 200; }

h1 {
  font-size: 30px;
  line-height: 35px; }

h2 {
  font-size: 26px;
  line-height: 33px; }

h3 {
  font-size: 22px;
  line-height: 27px; }

*[ng-click] {
  cursor: pointer; }

.headline {
  display: block;
  margin: 10px 0 25px 0;
  border-bottom: 1px solid #aaa; }
  .headline h1 {
    margin: 0 0 -2px 0;
    padding-bottom: 5px;
    display: inline-block;
    border-bottom: 2px solid #60BCE0; }
    .headline h1.emergency-header {
      border-bottom: 2px solid #F0512A; }

pre {
  margin: 20px 0;
  padding: 15px;
  text-align: justify;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal; }

.color-red {
  color: #F0512A; }

.btn-white {
  color: #fff !important;
  border-color: #fff !important; }
  .btn-white:hover {
    background-color: #35aad8 !important; }

.btn-lg-block {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
  font-size: 3rem;
  width: 100%;
  display: block;
  text-align: center; }

.btn-u.btn-brd:focus {
  color: #60BCE0; }

.btn-u.btn-brd.btn-u-light:focus {
  color: #fff; }

button[disabled],
.disabled {
  cursor: not-allowed;
  opacity: .7; }
  button[disabled].btn-u:hover,
  .disabled.btn-u:hover {
    background: #60BCE0; }

.btn-u.btn-brd.btn-u-blue:hover {
  color: #35aad8;
  border-color: #35aad8; }

.blog-latest-posts h3 {
  cursor: pointer; }
  .blog-latest-posts h3 i {
    opacity: 0; }
    .blog-latest-posts h3 i.active {
      opacity: 1; }

.blog-latest-posts a {
  display: inline-block;
  width: 80%; }

.s-results .inner-results h3 a {
  color: #60BCE0 !important; }
  .s-results .inner-results h3 a:hover {
    color: #35aad8 !important; }

.s-results .inner-results ul li,
.s-results .related-search a {
  font-size: 13px; }

.s-results .related-search li {
  line-height: 20.8px; }

.s-results .related-search li a i.fa-check {
  position: absolute;
  margin-left: -1.7rem;
  margin-top: .5rem; }

.header-v6 li.menu-icons i.fa-times {
  color: #fff; }

.header-v6 .search-open {
  right: 0;
  top: 40px;
  display: none;
  padding: 14px;
  position: absolute;
  background: #fcfcfc;
  border-top: solid 2px #eee;
  box-shadow: 0 1px 3px #ddd; }

.header-v6 .search-open .form-control {
  height: 34px;
  border: 1px solid #cccccc !important;
  font-size: 14px;
  font-weight: 200;
  padding: 6px 12px;
  text-transform: none; }

@media (max-width: 991px) {
  .header-v6 .search-open {
    width: 100%;
    top: 5px;
    padding: 0;
    left: 35px;
    border-top: none;
    box-shadow: none;
    background: none;
    margin-right: 10px; }
  .header-v6 li.menu-icons {
    width: 93%; }
    .header-v6 li.menu-icons:hover, .header-v6 li.menu-icons:focus {
      background: none; }
    .header-v6 li.menu-icons i.menu-icons-style {
      color: #999;
      line-height: 50px; }
  .header-v6 > li > .search {
    color: #999;
    width: 35px;
    height: 34px;
    text-align: left;
    background: #eee;
    line-height: 12px;
    text-align: center;
    margin: 5px 10px 0;
    border-bottom: none; } }

@media (min-width: 992px) {
  .header-v6 .search-open {
    top: 75px;
    width: 330px; } }

.footer-logo {
  max-width: 250px; }

@media (min-width: 992px) {
  .link-list li i.wash-res-carat {
    margin-top: -20px; } }

@media (min-width: 1200px) {
  .link-list li i.wash-res-carat {
    margin-top: 10px; } }

.donate-footer {
  display: block;
  width: 100%;
  background: #F78D37;
  text-align: center;
  color: white !important; }
  .donate-footer .fa {
    float: none;
    color: white; }
  .donate-footer:hover {
    background: #f1710a;
    text-decoration: none; }

.margin-top-0 {
  margin-top: 0px; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.padding-top-0 {
  padding-top: 0px; }

.padding-bottom-0 {
  padding-bottom: 0px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.padding-top-10 {
  padding-top: 10px; }

.padding-bottom-10 {
  padding-bottom: 10px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.padding-top-20 {
  padding-top: 20px; }

.padding-bottom-20 {
  padding-bottom: 20px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-bottom-30 {
  margin-bottom: 30px; }

.padding-top-30 {
  padding-top: 30px; }

.padding-bottom-30 {
  padding-bottom: 30px; }

.margin-top-40 {
  margin-top: 40px; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.padding-top-40 {
  padding-top: 40px; }

.padding-bottom-40 {
  padding-bottom: 40px; }

.margin-top-50 {
  margin-top: 50px; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.padding-top-50 {
  padding-top: 50px; }

.padding-bottom-50 {
  padding-bottom: 50px; }

.margin-top-60 {
  margin-top: 60px; }

.margin-bottom-60 {
  margin-bottom: 60px; }

.padding-top-60 {
  padding-top: 60px; }

.padding-bottom-60 {
  padding-bottom: 60px; }

.margin-top-80 {
  margin-top: 80px; }

.margin-bottom-80 {
  margin-bottom: 80px; }

.padding-top-80 {
  padding-top: 80px; }

.padding-bottom-80 {
  padding-bottom: 80px; }

.margin-bottom-0 {
  margin-bottom: 0 !important; }

p.padding-bottom-30 {
  padding-bottom: 30px; }

.sm-margin-bottom-60 {
  margin-bottom: 60px; }
  @media (min-width: 768px) {
    .sm-margin-bottom-60 {
      margin-bottom: 0px; } }

.bottom-column {
  float: none;
  display: table-cell;
  vertical-align: bottom; }

.middle-column {
  float: none;
  display: table-cell;
  vertical-align: middle; }

@media (min-width: 992px) {
  .bottom-column-md {
    float: none;
    display: table-cell;
    vertical-align: bottom; } }

@media (min-width: 1200px) {
  .middle-column-lg {
    float: none;
    display: table-cell;
    vertical-align: middle; } }

@media (min-width: 992px) {
  .middle-column-md {
    float: none;
    display: table-cell;
    vertical-align: middle; } }

.align-middle-social {
  display: table; }
  .align-middle-social .align-middle-cell {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
    float: none; }

.text-italic {
  font-style: italic; }

.cawst-icon-31 {
  opacity: 0.7;
  position: relative;
  top: -4px; }

.cawst-icon-sm {
  width: 25px; }

.cawst-icon-event {
  height: auto !important;
  width: 45px !important; }

i.icon-color-blue {
  color: #60BCE0;
  border: none; }

i.numbers {
  margin-top: -4px; }

.cstm-center-block {
  display: block;
  margin: 0 auto; }

h2.title-v2:after {
  background: none; }

.team-v7-name small {
  color: #aaa;
  font-size: 65%;
  display: inline-block;
  padding-left: 0.5rem; }

.one-page .one-blue {
  background: #60BCE0 !important; }

.s-results .results-number {
  text-transform: none !important; }

.service-block h1 {
  color: white; }

.service-block a {
  color: white;
  text-decoration: underline; }
  .service-block a:hover, .service-block a:active {
    color: #aaa; }

.service-block a.btn-u {
  text-decoration: none; }
  .service-block a.btn-u:visited, .service-block a.btn-u:focus {
    color: inherit; }

.service-block-v8.less-space {
  padding: 0 25px; }

.service-block-v8 i.fa-asterisk {
  float: none;
  margin: 0;
  color: #F0512A; }

.service-block-v8 .service-block-desc h3:after {
  background: none !important; }

.full-width-video {
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid #ddd; }

.side-bar-right {
  border-left: 1px solid #eee; }

.funny-boxes-white {
  background-color: white; }

.service-block-news {
  text-align: left; }

.service-block-blue {
  background: #60BCE0; }

.remove-arrow-v4 .service-block-v4:after {
  display: none; }

.easy-block-v3.outline {
  min-height: 177px;
  background: none !important;
  border: 1px solid #aaa; }
  .easy-block-v3.outline h3, .easy-block-v3.outline p, .easy-block-v3.outline i {
    color: #333; }

.sorting-block li {
  margin-bottom: 40px; }

.sorting-block a {
  font-size: 2rem; }
  .sorting-block a img {
    border: 1px solid #aaa;
    opacity: 0.7;
    transition: opacity 0.3s linear; }
    .sorting-block a img:hover {
      opacity: 1; }
  .sorting-block a:hover, .sorting-block a:focus, .sorting-block a:active {
    text-decoration: none; }

a.counters-wrapper:hover {
  text-decoration: none; }
  a.counters-wrapper:hover .counters {
    border-bottom: solid 2px #60BCE0;
    transition: all 0.3s linear; }

.bg-color-blue {
  background-color: #60BCE0 !important; }

a {
  color: #60BCE0; }

a:focus,
a:hover,
a:active {
  color: #60BCE0; }

.color-green {
  color: #60BCE0; }

a.read-more:hover {
  color: #60BCE0; }

.linked:hover {
  color: #60BCE0; }

/* Headers Default
------------------------------------*/
.header .navbar-nav > .active > a {
  color: #60BCE0; }

.header .navbar-nav > .active > a {
  border-color: #60BCE0; }

.header .dropdown-menu {
  border-top: solid 2px #60BCE0; }

.header .navbar-nav > li:hover > a {
  color: #35aad8; }

.header .nav > li > .search:hover {
  border-bottom-color: #35aad8;
  color: #35aad8; }

.header .navbar-nav > li > a:hover,
.header .navbar-nav > .active > a {
  border-bottom-color: #60BCE0; }

.header .navbar-toggle {
  border-color: #35aad8; }

.header .navbar-toggle,
.header .navbar-toggle:hover,
.header .navbar-toggle:focus {
  background: #60BCE0; }

.header .navbar-toggle:hover {
  background: #35aad8 !important; }

.header .navbar-nav > .open > a,
.header .navbar-nav > .open > a:hover,
.header .navbar-nav > .open > a:focus {
  color: #60BCE0; }

/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header .navbar-nav > .active > a,
  .header .navbar-nav > .active > a:hover,
  .header .navbar-nav > .active > a:focus {
    background: #60BCE0;
    color: #fff !important; }
  .header .navbar-nav > .active > a {
    border-color: #eee; }
  .header .nav > li > .search:hover {
    background: #60BCE0; } }

/* Headers v1
------------------------------------*/
.header-v1 .navbar-default .navbar-nav > .active > a,
.header-v1 .navbar-default .navbar-nav > li > a:hover,
.header-v1 .navbar-default .navbar-nav > li > a:focus {
  color: #60BCE0; }

.header-v1 .dropdown-menu {
  border-color: #60BCE0; }

.header-v1 .navbar-default .navbar-nav > li:hover > a {
  color: #60BCE0; }

.header-v1 .navbar .nav > li > .search:hover {
  color: #60BCE0; }

.header-v1 .navbar .nav > li > .search:hover {
  color: #60BCE0; }

.header-v1 .navbar-default .navbar-toggle {
  border-color: #35aad8; }

.header-v1 .navbar-toggle,
.header-v1 .navbar-default .navbar-toggle:hover,
.header-v1 .navbar-default .navbar-toggle:focus {
  background: #60BCE0; }

.header-v1 .navbar-toggle:hover {
  background: #35aad8 !important; }

/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header-v1 .navbar-default .navbar-nav > li:hover > a {
    border-color: #eee; }
  .header-v1 .navbar-default .navbar-nav > .active > a,
  .header-v1 .navbar-default .navbar-nav > .active > a:hover,
  .header-v1 .navbar-default .navbar-nav > .active > a:focus {
    background-color: #60BCE0; }
  .header-v1 .navbar-default .navbar-nav > .active > a {
    border-color: #eee; }
  .header-v1 .navbar .nav > li > .search:hover {
    background-color: #60BCE0; } }

/* Headers v2
------------------------------------*/
.header-v2 .dropdown-menu {
  border-color: #60BCE0; }

.header-v2 .navbar-default .navbar-toggle {
  border-color: #35aad8; }

.header-v2 .navbar-toggle,
.header-v2 .navbar-default .navbar-toggle:hover,
.header-v2 .navbar-default .navbar-toggle:focus {
  background: #60BCE0; }

.header-v2 .navbar-toggle:hover {
  background: #35aad8 !important; }

/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header-v2 .navbar-default .navbar-nav > .active > a,
  .header-v2 .navbar-default .navbar-nav > .active > a:hover,
  .header-v2 .navbar-default .navbar-nav > .active > a:focus {
    background: #60BCE0;
    color: #fff !important; }
  .header-v2 .navbar-default .navbar-nav > li > a:hover {
    color: #60BCE0; } }

/* Headers v3
------------------------------------*/
.header-v3 .navbar-default .navbar-nav > .active > a {
  color: #60BCE0; }

.header-v3 .navbar-default .navbar-nav > li:hover > a {
  color: #35aad8; }

.header-v3 .dropdown-menu {
  border-color: #60BCE0; }

.header-v3 .navbar-default .navbar-toggle {
  border-color: #35aad8; }

.header-v3 .navbar-toggle,
.header-v3 .navbar-default .navbar-toggle:hover,
.header-v3 .navbar-default .navbar-toggle:focus {
  background: #60BCE0; }

.header-v3 .navbar-toggle:hover {
  background: #35aad8 !important; }

.header-v3 .navbar .nav > li > .search:hover {
  background: inherit;
  color: #60BCE0; }

/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header-v3 .navbar-default .navbar-nav > .active > a,
  .header-v3 .navbar-default .navbar-nav > .active > a:hover,
  .header-v3 .navbar-default .navbar-nav > .active > a:focus {
    background: #60BCE0;
    color: #fff !important; }
  .header-v3 .navbar-default .navbar-nav > .active > a {
    border-color: #eee; }
  .header-v3 .navbar .nav > li > .search:hover {
    background: #60BCE0; } }

/* Headers v4
------------------------------------*/
.header-v4 .navbar-default .navbar-nav > li > a:hover,
.header-v4 .navbar-default .navbar-nav > .active > a {
  border-color: #60BCE0; }

.header-v4 .navbar-default .navbar-nav > .active > a {
  color: #60BCE0; }

.header-v4 .navbar-default .navbar-nav > li:hover > a {
  border-color: #60BCE0;
  color: #35aad8; }

.header-v4 .navbar .nav > li > .search:hover {
  color: #60BCE0; }

.header-v4 .navbar-default .navbar-nav > .open > a,
.header-v4 .navbar-default .navbar-nav > .open > a:hover,
.header-v4 .navbar-default .navbar-nav > .open > a:focus {
  color: #60BCE0; }

/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header-v4 .navbar-default .navbar-nav > li:hover > a {
    border-color: #eee; }
  .header-v4 .navbar-default .navbar-nav > .active > a,
  .header-v4 .navbar-default .navbar-nav > .active > a:hover,
  .header-v4 .navbar-default .navbar-nav > .active > a:focus {
    color: #60BCE0 !important; }
  .header-v4 .navbar-default .navbar-nav > .active > a {
    border-color: #eee; }
  .header-v4 .navbar .nav > li > .search:hover {
    background: #60BCE0; } }

/* Headers v5
------------------------------------*/
.header-v5 .navbar-default .navbar-nav > li > a:hover,
.header-v5 .navbar-default .navbar-nav > .active > a {
  border-top: 2px solid #60BCE0; }

.header-v5 .navbar-default .navbar-nav > .active > a {
  color: #60BCE0; }

.header-v5 .navbar-default .navbar-nav > li:hover > a {
  color: #60BCE0; }

.header-v5 .navbar-default .navbar-nav > .open > a,
.header-v5 .navbar-default .navbar-nav > .open > a:hover,
.header-v5 .navbar-default .navbar-nav > .open > a:focus {
  color: #60BCE0; }

.header-v5 .dropdown-menu li > a:hover {
  background: #60BCE0; }

.header-v5 .dropdown-menu .active > a,
.header-v5 .dropdown-menu li > a:hover {
  background: #60BCE0; }

.header-v5 .dropdown-menu {
  border-color: #60BCE0; }

.header-v5 .dropdown-menu li.dropdown-submenu:hover > a {
  background: #60BCE0; }

.header-v5 .dropdown-menu .style-list li > a:hover {
  background: none; }

.header-v5 .style-list li a:hover {
  color: #60BCE0; }

/* Shopping cart
------------------------------------*/
.header-v5 .shop-badge.badge-icons i {
  color: #60BCE0; }

.header-v5 .shop-badge span.badge-sea {
  background: #60BCE0; }

.header-v5 .badge-open {
  border-top: 2px solid #60BCE0;
  box-shadow: 0 5px 5px 0 rgba(52, 152, 219, 0.075); }

/* Header v6
------------------------------------*/
/* Search */
.header-v6 .shopping-cart .shopping-cart-open {
  border-top-color: #60BCE0 !important; }

.header-v6 li.menu-icons span.badge {
  background: #60BCE0; }

/* Dropdown Menu */
.header-v6 .dropdown-menu {
  border-top-color: #60BCE0; }

/* Media Queries */
@media (max-width: 991px) {
  /* Navbar Nav */
  .header-v6 .navbar-nav > .active > a,
  .header-v6 .navbar-nav > .active > a:hover,
  .header-v6 .navbar-nav > .active > a:focus {
    color: #60BCE0 !important; }
  .header-v6 .nav .open > a,
  .header-v6 .nav .open > a:hover,
  .header-v6 .nav .open > a:focus {
    border-color: #eee; }
  .header-v6 .navbar-nav > li > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > li > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > li > a:focus,
  .header-v6 .navbar-nav .open .dropdown-menu > .active > a,
  .header-v6 .navbar-nav .open .dropdown-menu > .active > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #60BCE0 !important; }
  .header-v6 .mega-menu .equal-height-list li a:hover {
    color: #60BCE0 !important; }
  /* Classic Dark */
  .header-v6 .mega-menu .equal-height-list li a:hover {
    color: #60BCE0; }
  /* Dark Responsive Navbar */
  .header-v6.header-dark-res-nav .navbar-nav > li a:hover,
  .header-v6.header-dark-res-nav .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #60BCE0; }
  .header-v6.header-dark-res-nav .nav .open > a,
  .header-v6.header-dark-res-nav .nav .open > a:hover,
  .header-v6.header-dark-res-nav .nav .open > a:focus {
    border-color: #555; } }

@media (min-width: 992px) {
  /* Default Style */
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav li > a:hover {
    color: #60BCE0 !important; }
  .header-v6 .dropdown-menu .active > a,
  .header-v6 .dropdown-menu li > a:hover,
  .header-fixed .header-v6.header-fixed-shrink .dropdown-menu .active > a,
  .header-fixed .header-v6.header-fixed-shrink .dropdown-menu li > a:hover {
    color: #60BCE0 !important; }
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav li > a:hover {
    color: #60BCE0; }
  /* Classic White */
  .header-fixed .header-v6.header-classic-white .navbar-nav .active > a,
  .header-fixed .header-v6.header-classic-white .navbar-nav li > a:hover {
    color: #60BCE0; }
  /* Classic Dark */
  .header-v6.header-classic-dark .navbar-nav .active > a,
  .header-v6.header-classic-dark .navbar-nav li > a:hover,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav li > a:hover {
    color: #60BCE0; }
  .header-v6.header-classic-dark .dropdown-menu .active > a,
  .header-v6.header-classic-dark .dropdown-menu li > a:hover {
    color: #60BCE0 !important; }
  /* Dark Dropdown */
  .header-v6.header-dark-dropdown .dropdown-menu .active > a,
  .header-v6.header-dark-dropdown .dropdown-menu li > a:hover {
    color: #60BCE0; }
  /* Dark Scroll */
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav li > a:hover {
    color: #60BCE0; } }

/* Header v7
------------------------------------*/
.header-v7 .navbar-default .navbar-nav > li > a:hover,
.header-v7 .navbar-default .navbar-nav > li.active > a {
  color: #60BCE0 !important; }

.header-v7 .dropdown-menu .active > a,
.header-v7 .dropdown-menu li > a:focus,
.header-v7 .dropdown-menu li > a:hover {
  color: #60BCE0 !important; }

.header-v7 .navbar-default .navbar-nav > li > a:hover,
.header-v7 .navbar-default .navbar-nav > li > a:focus,
.header-v7 .navbar-default .navbar-nav > .active > a,
.header-v7 .navbar-default .navbar-nav > .active > a:hover,
.header-v7 .navbar-default .navbar-nav > .active > a:focus {
  color: #60BCE0; }

.header-socials li a:hover {
  color: #60BCE0; }

/* Sliders
------------------------------------*/
/* Main Parallax Sldier */
.da-slide h2 i {
  background-color: rgba(52, 152, 219, 0.8); }

/* Sequence Parallax Sldier */
.sequence-inner {
  background: linear-gradient(#fff, #deeffc); }

#sequence-theme h2 {
  background: rgba(52, 152, 219, 0.8); }

#sequence-theme .info p {
  background: rgba(52, 152, 219, 0.8); }

/* Buttons
------------------------------------*/
.btn-u {
  background: #60BCE0; }

.btn-u:hover,
.btn-u:focus,
.btn-u:active,
.btn-u.active,
.open .dropdown-toggle.btn-u {
  background: #35aad8;
  color: #fff; }

/* Buttons Color */
.btn-u-split.dropdown-toggle {
  border-left: solid 1px #35aad8; }

/* Bordered Buttons */
.btn-u.btn-brd,
.btn-u.btn-brd.btn-u-blue {
  border-color: #60BCE0; }

.btn-u.btn-brd:hover,
.btn-u.btn-brd.btn-u-blue:hover {
  color: #60BCE0;
  border-color: #60BCE0; }

.btn-u.btn-brd.btn-brd-hover:hover,
.btn-u.btn-brd.btn-u-blue.btn-brd-hover:hover {
  background: #60BCE0; }

/* Service
------------------------------------*/
.service .service-icon {
  color: #60BCE0; }

/* Service Blocks */
.service-alternative .service:hover {
  background: #60BCE0; }

/* Thumbnail (Recent Work)
------------------------------------*/
.thumbnail-style h3 a:hover {
  color: #60BCE0; }

.thumbnail-style a.btn-more {
  background: #60BCE0; }

.thumbnail-style a.btn-more:hover {
  box-shadow: 0 0 0 2px #35aad8; }

/* Typography
------------------------------------*/
/* Heading */
.headline h2,
.headline h3,
.headline h4 {
  border-bottom: 2px solid #60BCE0; }

/* Blockquote */
blockquote:hover {
  border-left-color: #60BCE0; }

.hero {
  border-left-color: #60BCE0; }

blockquote.hero.hero-default {
  background: #60BCE0; }

blockquote.hero.hero-default:hover {
  background: #35aad8; }

/* Carousel
------------------------------------*/
.carousel-arrow a.carousel-control:hover {
  color: #60BCE0; }

/* Footer
------------------------------------*/
.footer a,
.copyright a,
.footer a:hover,
.copyright a:hover {
  color: #60BCE0; }

/* Footer Blog */
.footer .dl-horizontal a:hover {
  color: #60BCE0 !important; }

/* Blog Posts
------------------------------------*/
.posts .dl-horizontal a:hover {
  color: #60BCE0; }

.posts .dl-horizontal:hover dt img,
.posts .dl-horizontal:hover dd a {
  color: #60BCE0;
  border-color: #60BCE0 !important; }

/* Post Comment */
.post-comment h3,
.blog-item .media h3,
.blog-item .media h4.media-heading span a {
  color: #60BCE0; }

/* Tabs
------------------------------------*/
/* Tabs v1 */
.tab-v1 .nav-tabs {
  border-bottom: solid 2px #60BCE0; }

.tab-v1 .nav-tabs > .active > a,
.tab-v1 .nav-tabs > .active > a:hover,
.tab-v1 .nav-tabs > .active > a:focus {
  background: #60BCE0; }

.tab-v1 .nav-tabs > li > a:hover {
  background: #60BCE0; }

/* Tabs v2 */
.tab-v2 .nav-tabs li.active a {
  border-top: solid 2px #60BCE0; }

/* Tabs v3 */
.tab-v3 .nav-pills li a:hover,
.tab-v3 .nav-pills li.active a {
  background: #60BCE0;
  border: solid 1px #35aad8; }

/* Accardion
------------------------------------*/
.acc-home a.active,
.acc-home a.accordion-toggle:hover {
  color: #60BCE0; }

.acc-home .collapse.in {
  border-bottom: solid 1px #60BCE0; }

/* Testimonials
------------------------------------*/
.testimonials .testimonial-info {
  color: #60BCE0; }

.testimonials .carousel-arrow i:hover {
  background: #60BCE0; }

/* Info Blocks
------------------------------------*/
.info-blocks:hover i.icon-info-blocks {
  color: #60BCE0; }

/* Breadcrumb
------------------------------------*/
.breadcrumb li.active,
.breadcrumb li a:hover {
  color: #60BCE0; }

/* About Page
------------------------------------*/
.team .thumbnail-style:hover h3 a {
  color: #60BCE0 !important; }

/* Social Icons */
.team ul.team-socail li i:hover {
  background: #60BCE0; }

/* Right Sidebar
------------------------------------*/
/* Right Sidebar */
.who li i,
.who li:hover i,
.who li:hover a {
  color: #60BCE0; }

/* Privacy Page
------------------------------------*/
.privacy a:hover {
  color: #60BCE0; }

/* Portfolio Page
------------------------------------*/
/* Portfolio v1 */
.view a.info:hover {
  background: #60BCE0; }

/* Portfolio v2 */
.sorting-block .sorting-nav li.active {
  color: #60BCE0;
  border-bottom: solid 1px #60BCE0; }

.sorting-block .sorting-grid li a:hover span.sorting-cover {
  background: #60BCE0; }

/* Blog Page
------------------------------------*/
.blog h3 {
  color: #60BCE0; }

.blog li a:hover {
  color: #60BCE0; }

/* Blog Tags */
ul.blog-tags a:hover {
  background: #60BCE0; }

.blog-post-tags ul.blog-tags a:hover {
  background: #60BCE0; }

/* Blog Photos */
.blog-photos li img:hover {
  box-shadow: 0 0 0 2px #60BCE0; }

/* Blog Latest Tweets */
.blog-twitter .blog-twitter-inner:hover {
  border-color: #60BCE0;
  border-top-color: #60BCE0; }

.blog-twitter .blog-twitter-inner:hover:after {
  border-top-color: #60BCE0; }

.blog-twitter .blog-twitter-inner a {
  color: #60BCE0; }

/* Blog Item Page
------------------------------------*/
.blog-item h4.media-heading span a {
  color: #60BCE0; }

/* Coming Soon Page
------------------------------------*/
.coming-soon-border {
  border-top: solid 3px #60BCE0; }

/* Search Page
------------------------------------*/
.booking-blocks p a {
  color: #60BCE0; }

/* Icons Page
------------------------------------*/
.icon-page li:hover {
  color: #60BCE0; }

/* Glyphicons */
.glyphicons-demo a:hover {
  color: #60BCE0;
  text-decoration: none; }

/* Social Icons
------------------------------------*/
.social-icons-v1 i:hover {
  color: #fff;
  background: #60BCE0; }

/* Magazine Page
------------------------------------*/
/* Magazine News */
.magazine-news .by-author strong {
  color: #60BCE0; }

.magazine-news a.read-more {
  color: #60BCE0; }

/* Magazine Mini News */
.magazine-mini-news .post-author strong {
  color: #60BCE0; }

.news-read-more i {
  background: #60BCE0; }

/* Sidebar Features */
.magazine-page h3 a:hover {
  color: #60BCE0; }

/* Page Features
------------------------------------*/
/* Tag Boxes v1 */
.tag-box-v1 {
  border-top: solid 2px #60BCE0; }

/* Tag Boxes v2 */
.tag-box-v2 {
  border-left: solid 2px #60BCE0; }

/* Tag Boxes v7 */
.tag-box-v7 {
  border-bottom: solid 2px #60BCE0; }

/* Font Awesome Icon Page Style */
.fa-icons li:hover {
  color: #60BCE0; }

.fa-icons li:hover i {
  background: #60BCE0; }

/* GLYPHICONS Icons Page Style */
.bs-glyphicons li:hover {
  color: #60BCE0; }

/* Navigation
------------------------------------*/
/* Pagination */
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #60BCE0;
  border-color: #60BCE0; }

.pagination li a:hover {
  background: #35aad8;
  border-color: #35aad8; }

/* Pager */
.pager li > a:hover,
.pager li > a:focus {
  background: #35aad8;
  border-color: #35aad8; }

.pager.pager-v2 li > a:hover,
.pager.pager-v2 li > a:focus,
.pager.pager-v3 li > a:hover,
.pager.pager-v3 li > a:focus {
  color: #fff;
  background: #60BCE0; }

/* Registration and Login Page v2
------------------------------------*/
.reg-block {
  border-top: solid 2px #60BCE0; }

/*Image Hover
------------------------------------*/
/* Image-hover */
#effect-2 figure .img-hover {
  background: #60BCE0; }

/* Blog Large Page
------------------------------------*/
.blog h2 a:hover {
  color: #60BCE0; }

/* Timeline v1 Page
------------------------------------*/
.timeline-v1 > li > .timeline-badge i:hover {
  color: #60BCE0; }

.timeline-v1 .timeline-footer .likes:hover i {
  color: #60BCE0; }

/* Timeline v2 Page
------------------------------------*/
/* The icons */
.timeline-v2 > li .cbp_tmicon {
  background: #60BCE0; }

/* Progress Bar
------------------------------------*/
.progress-bar-u {
  background: #60BCE0; }

/* Job Inner Page
------------------------------------*/
.job-description .save-job a:hover,
.block-description .save-job a:hover {
  color: #60BCE0; }

.job-description .p-chart .overflow-h li i,
.job-description .p-chart .overflow-h li a,
.block-description .p-chart .overflow-h li i,
.block-description .p-chart .overflow-h li a {
  color: #60BCE0; }

/* Colorful-ul */
.job-description .colorful-ul li a {
  color: #60BCE0; }

/* Search Inner Page
------------------------------------*/
.s-results .related-search a:hover {
  color: #60BCE0; }

.s-results .inner-results h3 a:hover {
  color: #60BCE0; }

.s-results .up-ul li a:hover {
  color: #60BCE0; }

.s-results .down-ul li a {
  color: #60BCE0; }

/* Funny Boxes
------------------------------------*/
.funny-boxes p a {
  color: #60BCE0; }

.funny-boxes .funny-boxes-img li i {
  color: #60BCE0; }

.funny-boxes-colored p, .funny-boxes-colored h2 a, .funny-boxes-colored .funny-boxes-img li, .funny-boxes-colored .funny-boxes-img li i {
  color: #fff; }

/* Sidebar Sub Navigation
------------------------------------*/
.sidebar-nav-v1 ul li:hover a,
.sidebar-nav-v1 ul li.active a {
  color: #60BCE0; }

/* Blockquote
------------------------------------*/
blockquote.bq-green {
  border-color: #60BCE0; }

blockquote:hover,
blockquote.text-right:hover {
  border-color: #60BCE0; }

.quote-v1 p::before {
  color: #60BCE0; }

/* Green Left Bordered Funny Box */
.funny-boxes-left-green {
  border-left: solid 2px #60BCE0; }

.funny-boxes-left-green:hover {
  border-left-color: #60BCE0; }

/* Testimonials Default
------------------------------------*/
/* Testimonials */
.testimonials .carousel-arrow i:hover {
  background: #60BCE0; }

/* Testimonials Default */
.testimonials-bg-default .item p {
  background: #60BCE0; }

.testimonials.testimonials-bg-default .item p:after,
.testimonials.testimonials-bg-default .item p:after {
  border-top-color: #60BCE0; }

.testimonials-bg-default .carousel-arrow i {
  background: #60BCE0; }

.testimonials.testimonials-bg-default .carousel-arrow i:hover {
  background: #35aad8; }

/* Promo Page
------------------------------------*/
/* Promo Box */
.promo-box:hover strong,
.promo-box:hover strong a {
  color: #60BCE0; }

/* Typography
------------------------------------*/
.dropcap {
  color: #60BCE0; }

.dropcap-bg {
  color: #fff;
  background: #60BCE0; }

/* Breadcrumbs
------------------------------------*/
span.label-u,
span.badge-u {
  background: #60BCE0; }

/* Icons
------------------------------------*/
/* Icon Link*/
.link-icon:hover i {
  color: #60BCE0;
  border: solid 1px #60BCE0; }

.link-bg-icon:hover i {
  color: #60BCE0;
  background: #60BCE0 !important;
  border-color: #60BCE0; }

/* Icons Backgroun Color
------------------------------------*/
i.icon-color-u {
  color: #60BCE0;
  border: solid 1px #60BCE0; }

i.icon-bg-u {
  background: #60BCE0; }

/* Line Icon Page
------------------------------------*/
.line-icon-page .item:hover {
  color: #60BCE0; }

/* Colored Content Boxes
------------------------------------*/
.service-block-u {
  background: #60BCE0; }

/* Panels (Portlets)
------------------------------------*/
.panel-u {
  border-color: #60BCE0; }

.panel-u > .panel-heading {
  background: #60BCE0; }

/* Owl Carousel
------------------------------------*/
.owl-btn:hover {
  background: #60BCE0; }

/* Counter
------------------------------------*/
.counters span.counter-icon i {
  background: #60BCE0; }

.counters span.counter-icon i:after {
  border-top: 7px solid #60BCE0; }

/* SKy-Forms
------------------------------------*/
/* Buttons */
.sky-form .button {
  background: #60BCE0; }

/* Rating */
.sky-form .rating input:checked ~ label {
  color: #60BCE0; }

/* Message */
.sky-form .message {
  color: #60BCE0; }

.sky-form .message i {
  border-color: #60BCE0; }

/* Profile
------------------------------------*/
.profile .profile-post:hover span.profile-post-numb {
  color: #60BCE0; }

.profile .date-formats {
  background: #60BCE0; }

.profile .name-location span i,
.profile .name-location span a:hover {
  color: #60BCE0; }

.share-list li i {
  color: #60BCE0; }

.profile .comment-list-v2 li:hover i,
.profile .comment-list li:hover i {
  color: #60BCE0; }

.profile .profile-post.color-one {
  border-color: #60BCE0; }

/* Pricing Page
------------------------------------*/
/* Pricing Head */
.pricing:hover h4 {
  color: #60BCE0; }

.pricing-head h3 {
  background: #60BCE0;
  text-shadow: 0 1px 0 #35aad8; }

.pricing-head h4 {
  color: #999;
  background: #fcfcfc;
  border-bottom: solid 1px #deeffc; }

/* Pricing Content */
.pricing-content li {
  border-bottom: solid 1px #deeffc; }

.pricing-content li i {
  color: #60BCE0; }

/* Pricing Extra */
.sticker-left {
  background: #60BCE0; }

/* Pricing Footer */
.pricing-footer a:hover,
.pricing-footer button:hover {
  background: #35aad8; }

/* Pricing Active */
.price-active h4 {
  color: #60BCE0; }

.no-space-pricing .price-active .pricing-head h4,
.no-space-pricing .pricing:hover .pricing-head h4 {
  color: #60BCE0; }

/* Mega Pricing Tables
------------------------------------*/
.pricing-mega-v1 .pricing-head h3,
.pricing-mega-v2 .pricing-head h3,
.pricing-mega-v3 .pricing-head h3 {
  text-shadow: 0 1px 0 #35aad8; }

/* Pricing Table Mega v1 Version
------------------------------------*/
.pricing-mega-v1 .pricing:hover h4 i {
  color: #60BCE0; }

.pricing-mega-v1 .pricing-content li i {
  color: #60BCE0; }

/* Pricing Table Colored Background Version
------------------------------------*/
.pricing-bg-colored .pricing:hover {
  background: #60BCE0; }

.pricing-bg-colored .pricing-head i {
  color: #60BCE0; }

.pricing-bg-colored .pricing-footer .btn-u {
  border: 1px solid #fff; }

.pricing-bg-colored .pricing-head p {
  border-bottom: 1px solid #deeffc; }

/* Pricing Table Mega v2
------------------------------------*/
.pricing-mega-v2 .block:hover .bg-color {
  background: #60BCE0; }

.pricing-mega-v2 .block:hover h3,
.pricing-mega-v2 .block:hover h4,
.pricing-mega-v2 .block:hover li,
.pricing-mega-v2 .block:hover li i,
.pricing-mega-v2 .block:hover h4 i {
  background: #60BCE0; }

/* Pricing Table Mega v3
------------------------------------*/
.pricing-mega-v1 .btn-group .dropdown-menu,
.pricing-mega-v3 .btn-group .dropdown-menu {
  background: #60BCE0 !important; }

.pricing-mega-v1 .btn-group .dropdown-menu li a:hover,
.pricing-mega-v3 .btn-group .dropdown-menu li a:hover {
  background: #35aad8; }

/* Grid Block v2
------------------------------------*/
.grid-block-v2 li:hover .grid-block-v2-info {
  border-color: #60BCE0; }

/* Testimonials v3 Title
------------------------------------*/
.testimonials-v3 .testimonials-v3-title p {
  color: #60BCE0; }

.testimonials-v3 .owl-buttons .owl-prev:hover,
.testimonials-v3 .owl-buttons .owl-next:hover {
  background: #60BCE0; }

/* Content Boxes v4
------------------------------------*/
.content-boxes-v4 i {
  color: #60BCE0; }

/* Thumbnails v1
------------------------------------*/
.thumbnails-v1 .read-more {
  color: #60BCE0; }

/* Thumbnails v6
------------------------------------*/
.testimonials-v6 .testimonials-info:hover {
  border-color: #60BCE0; }

/* Team v1
------------------------------------*/
.team-v1 li:hover > p:before {
  background: #60BCE0; }

/* Team v4
------------------------------------*/
.team-v4 .team-social-v4 a:hover {
  color: #60BCE0; }

/* Team v5 & v6 & v7
------------------------------------*/
.team-v5 small,
.team-v6 small,
.team-v7 .team-v7-position {
  color: #60BCE0; }

/* Headliner Center
------------------------------------*/
.headline-center h2:after {
  background: #60BCE0; }

/* Headliner Left
------------------------------------*/
.headline-left .headline-brd:after {
  background: #60BCE0; }

/* Portfolio Box
------------------------------------*/
.portfolio-box .portfolio-box-in i {
  background: #60BCE0; }

/* Flat Background Block v1
------------------------------------*/
.flat-bg-block-v1 .checked-list i {
  color: #60BCE0; }

/* Owl Carousel v5
------------------------------------*/
.owl-carousel-v5 .owl-controls .owl-page.active span,
.owl-carousel-v5 .owl-controls.clickable .owl-page:hover span {
  background: #60BCE0; }

/* Content Boxes v5
------------------------------------*/
.content-boxes-v5:hover i {
  background: #eee;
  color: #999; }

/* Block Grid v1
------------------------------------*/
.block-grid-v1:hover {
  border-color: #60BCE0; }

/* Block Grid v2
------------------------------------*/
.block-grid-v2 li:hover .block-grid-v2-info {
  border-color: #60BCE0; }

/* Content Boxes v6
------------------------------------*/
.content-boxes-v6:hover i:after {
  border-color: #60BCE0; }

.content-boxes-v6:hover i {
  background: #60BCE0; }

/* Portfolio Box-v2
------------------------------------*/
.portfolio-box-v2 .portfolio-box-v2-in i {
  background: rgba(52, 152, 219, 0.8); }

.portfolio-box-v2 .portfolio-box-v2-in i:hover {
  background: #60BCE0; }

/* Service Block v1
------------------------------------*/
.service-block-v1 i {
  background: #60BCE0; }

/* Service Block v4
------------------------------------*/
.service-block-v4 .service-desc i {
  color: #60BCE0; }

/* Service Block v7
------------------------------------*/
.service-block-v7 i {
  background: #60BCE0; }

/* Service Block v8
------------------------------------*/
.service-block-v8 .service-block-desc h3::after {
  background: #60BCE0; }

/* Testimonials bs
------------------------------------*/
.testimonials-bs .carousel-control-v2 i:hover {
  border-color: #60BCE0;
  color: #60BCE0; }

/* Fusion Portfolio
------------------------------------*/
.fusion-portfolio #filters-container .cbp-filter-item-active {
  background: #60BCE0;
  border-color: #60BCE0; }

.fusion-portfolio #filters-container .cbp-filter-item:hover {
  color: #60BCE0; }

.blog_masonry_3col h3 a:hover {
  color: #60BCE0; }

/* Cube Portfolio
------------------------------------*/
.cube-portfolio .cbp-l-filters-text .cbp-filter-item.cbp-filter-item-active, .cube-portfolio .cbp-l-filters-text .cbp-filter-item:hover {
  color: #60BCE0; }

.cube-portfolio .link-captions li i:hover {
  color: #fff;
  background: #60BCE0; }

.cube-portfolio .cbp-caption-activeWrap.default-transparent-hover {
  background: rgba(52, 152, 219, 0.9) !important; }

/* Recent Works
------------------------------------*/
.owl-work-v1 .item a:hover span {
  border-bottom-color: #60BCE0; }

/* Footer Default
------------------------------------*/
.footer-default .footer .dl-horizontal a:hover {
  color: #60BCE0 !important; }

.footer-default .footer a {
  color: #60BCE0; }

.footer-default .footer a:hover {
  color: #35aad8; }

.footer-default .copyright a {
  color: #60BCE0; }

.footer-default .copyright a:hover {
  color: #35aad8; }

/* Footer v4
------------------------------------*/
.footer-v4 .copyright a {
  color: #60BCE0; }

/* Title v1
------------------------------------*/
.title-v1 h1:after, .title-v1 h2:after {
  background-color: #60BCE0; }

/* Copyright Section
------------------------------------*/
.copyright-section i.back-to-top:hover {
  color: #60BCE0; }

/* Top Control
------------------------------------*/
#topcontrol:hover {
  background-color: #60BCE0; }

/* News Info
------------------------------------*/
.news-v1 .news-v1-info li a:hover {
  color: #60BCE0; }

.news-v1 h3 a:hover {
  color: #60BCE0; }

.news-v2 .news-v2-desc h3 a:hover {
  color: #60BCE0 !important; }

.news-v3 .post-shares li span {
  background: #60BCE0; }

.news-v3 .posted-info li a:hover {
  color: #60BCE0 !important; }

.news-v3 h2 a:hover {
  color: #60BCE0 !important; }

/* Blog Trending
------------------------------------*/
.blog-trending small a:hover {
  color: #60BCE0; }

/* Blog Masonry
------------------------------------*/
.blog_masonry_3col ul.grid-boxes-news li a:hover {
  color: #60BCE0; }

/* List v1
------------------------------------*/
.lists-v1 i {
  background: #60BCE0; }

/* List v2
------------------------------------*/
.lists-v2 i {
  color: #60BCE0; }

/* Process v1
------------------------------------*/
.process-v1 .process-in > li i {
  background: #60BCE0; }

/* Featured Blog
------------------------------------*/
.featured-blog h2::after {
  background: #60BCE0; }

.featured-blog .featured-img i:hover {
  color: #fff;
  background: #60BCE0; }

.rgba-default {
  background-color: #27d7e7; }

/* Blog Latest Posts
------------------------------------*/
.blog-latest-posts h3 a:hover {
  color: #60BCE0 !important; }

/* Blog Trending
------------------------------------*/
.blog-trending h3 a:hover {
  color: #60BCE0 !important; }

.cstm-main-nav-width {
  display: inline-block;
  max-width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top; }

@media (min-width: 992px) {
  .anchor-link {
    margin-top: -75px !important;
    padding-top: 150px; } }

.hide-nav-logo {
  visibility: hidden; }

@media (min-width: 992px) and (max-width: 1199px) {
  img.navbar-brand-small {
    width: 110px !important; } }

.cstm-nav-lang-flag {
  margin-right: 1.5rem;
  width: 50px; }

img.cstm-nav-icon {
  margin-right: 1.5rem;
  height: 30px; }

.cstm-nav-lang-check {
  margin-left: 1.5rem; }

.header-v6 .navbar-nav > li > a,
.header-v6 .navbar-nav > li > a:hover,
.header-v6 .navbar-nav > li > a:focus,
.header-v6 .navbar-nav > .active > a,
.header-v6 .navbar-nav > .active > a:hover,
.header-v6 .navbar-nav > .active > a:focus {
  font-weight: 400; }

.breadcrumb li.active {
  color: #777;
  font-weight: 600; }

.cstm-breadcrumbs {
  padding: 0 !important;
  background: none; }
  .cstm-breadcrumbs .breadcrumb {
    padding: 0 15px !important; }
  .cstm-breadcrumbs h1 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }

.navbar-background {
  display: none; }
  @media (min-width: 992px) {
    .navbar-background {
      display: block;
      padding: 48px;
      background: #284D63; } }

.navbar-background-gradient {
  display: none; }
  @media (min-width: 992px) {
    .navbar-background-gradient {
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      height: 100px;
      width: 100%;
      background: linear-gradient(#284D63, rgba(0, 0, 0, 0));
      z-index: 1; } }

@media (min-width: 992px) {
  .navbar-nav-left {
    float: left !important; }
    .navbar-nav-left li > a {
      color: orange; } }

.navbar-nav li:hover {
  background: none; }

.navbar-nav li:hover.no-hover {
  background: none; }

.navbar-nav li ul li:hover {
  background: none; }

.navbar-nav li ul li:hover.no-hover {
  background: none; }

@media (min-width: 992px) {
  .header-v6 .navbar-nav > li > a.navbar-nav-cta {
    padding: 10px;
    margin: 10px;
    border: 1px solid white;
    text-align: center;
    width: 110px;
    background-color: rgba(150, 150, 150, 0.5);
    font-weight: 400; }
    .header-v6 .navbar-nav > li > a.navbar-nav-cta:hover {
      background-color: #fff;
      color: #333; } }

.header-v6 .mega-menu .dropdown-submenu .dropdown-menu.dropdown-menu-right {
  left: -100%;
  right: 100%; }

.navbar-nav > li > .dropdown-menu {
  margin-top: -2px; }

.header-v6 li.menu-icons i.fa-times {
  color: #fff; }

.header-v6 .search-open {
  right: 0;
  top: 40px;
  display: none;
  padding: 14px;
  position: absolute;
  background: #fcfcfc;
  border-top: solid 2px #eee;
  box-shadow: 0 1px 3px #ddd; }

.header-v6 .search-open .form-control {
  height: 34px;
  border: 1px solid #cccccc !important;
  font-size: 14px;
  font-weight: 200;
  padding: 6px 12px;
  text-transform: none; }

@media (max-width: 991px) {
  .header-v6 .search-open {
    width: 100%;
    top: 5px;
    padding: 0;
    left: 35px;
    border-top: none;
    box-shadow: none;
    background: none;
    margin-right: 10px; }
  .header-v6 li.menu-icons {
    width: 93%; }
    .header-v6 li.menu-icons:hover, .header-v6 li.menu-icons:focus {
      background: none; }
    .header-v6 li.menu-icons i.menu-icons-style {
      color: #999;
      line-height: 50px; }
  .header-v6 > li > .search {
    color: #999;
    width: 35px;
    height: 34px;
    text-align: left;
    background: #eee;
    line-height: 12px;
    text-align: center;
    margin: 5px 10px 0;
    border-bottom: none; } }

@media (min-width: 992px) {
  .header-v6 .search-open {
    top: 75px;
    width: 330px; } }

.grid-container {
  margin: 0 auto;
  max-width: 1200px; }

.grid-square {
  float: left;
  position: relative;
  margin: 1% .1%;
  overflow: hidden;
  color: #fff;
  text-align: center;
  font-size: 2rem;
  font-weight: 200;
  width: 100%;
  padding-bottom: 100%; }
  @media (min-width: 350px) {
    .grid-square {
      margin: .1%;
      width: 49%;
      padding-bottom: 49%; } }
  @media (min-width: 450px) {
    .grid-square {
      width: 32%;
      padding-bottom: 32%; } }
  @media (min-width: 650px) {
    .grid-square {
      width: 24.8%;
      padding-bottom: 24.8%; } }
  @media (min-width: 768px) {
    .grid-square {
      width: 19.8%;
      padding-bottom: 19.8%; } }
  @media (min-width: 1200px) {
    .grid-square {
      width: 14.08%;
      padding-bottom: 14.08%; } }
  .grid-square.grid-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }

.grid-container-front-page .grid-square {
  display: none; }
  .grid-container-front-page .grid-square.block-cta {
    display: block !important;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
    color: #60BCE0; }
    .grid-container-front-page .grid-square.block-cta:hover {
      border-color: #aaa;
      color: #248db6; }
  @media (min-width: 350px) {
    .grid-container-front-page .grid-square {
      display: block; } }
  @media (min-width: 1200px) {
    .grid-container-front-page .grid-square {
      font-size: 1.5rem;
      width: 24.8%;
      padding-bottom: 24.8%; } }

.block-default {
  background: #aaaaaa;
  transition: all 0.3s ease; }
  .block-default:hover {
    background: #919191;
    transition: all 0.3s ease; }
  .block-default .block-default-overlay {
    background: rgba(170, 170, 170, 0.7); }

.block-default-nohover {
  background: #aaaaaa; }

@media print {
  .block-default {
    background: #aaaaaa !important; }
  .block-default-nohover {
    background: #aaaaaa !important; } }

.block-blue {
  background: #60bce0;
  transition: all 0.3s ease; }
  .block-blue:hover {
    background: #35aad8;
    transition: all 0.3s ease; }
  .block-blue .block-blue-overlay {
    background: rgba(96, 188, 224, 0.7); }

.block-blue-nohover {
  background: #60bce0; }

@media print {
  .block-blue {
    background: #60bce0 !important; }
  .block-blue-nohover {
    background: #60bce0 !important; } }

.block-green {
  background: #9db258;
  transition: all 0.3s ease; }
  .block-green:hover {
    background: #819344;
    transition: all 0.3s ease; }
  .block-green .block-green-overlay {
    background: rgba(157, 178, 88, 0.7); }

.block-green-nohover {
  background: #9db258; }

@media print {
  .block-green {
    background: #9db258 !important; }
  .block-green-nohover {
    background: #9db258 !important; } }

.block-yellow {
  background: #fec441;
  transition: all 0.3s ease; }
  .block-yellow:hover {
    background: #feb40e;
    transition: all 0.3s ease; }
  .block-yellow .block-yellow-overlay {
    background: rgba(254, 196, 65, 0.7); }

.block-yellow-nohover {
  background: #fec441; }

@media print {
  .block-yellow {
    background: #fec441 !important; }
  .block-yellow-nohover {
    background: #fec441 !important; } }

.block-orange {
  background: #f68d36;
  transition: all 0.3s ease; }
  .block-orange:hover {
    background: #ee720b;
    transition: all 0.3s ease; }
  .block-orange .block-orange-overlay {
    background: rgba(246, 141, 54, 0.7); }

.block-orange-nohover {
  background: #f68d36; }

@media print {
  .block-orange {
    background: #f68d36 !important; }
  .block-orange-nohover {
    background: #f68d36 !important; } }

.block-red {
  background: #ec563c;
  transition: all 0.3s ease; }
  .block-red:hover {
    background: #df3416;
    transition: all 0.3s ease; }
  .block-red .block-red-overlay {
    background: rgba(236, 86, 60, 0.7); }

.block-red-nohover {
  background: #ec563c; }

@media print {
  .block-red {
    background: #ec563c !important; }
  .block-red-nohover {
    background: #ec563c !important; } }

.block-purple {
  background: #5c68b0;
  transition: all 0.3s ease; }
  .block-purple:hover {
    background: #475292;
    transition: all 0.3s ease; }
  .block-purple .block-purple-overlay {
    background: rgba(92, 104, 176, 0.7); }

.block-purple-nohover {
  background: #5c68b0; }

@media print {
  .block-purple {
    background: #5c68b0 !important; }
  .block-purple-nohover {
    background: #5c68b0 !important; } }

.grid-content {
  position: absolute;
  height: 100%;
  width: 100%; }
  .grid-content .grid-img-rs {
    width: auto;
    height: auto;
    max-height: 90%;
    max-width: 100%; }
  .grid-content.grid-bg-icon {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
    .grid-content.grid-bg-icon::after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: .2;
      content: ""; }

.grid-table {
  display: table;
  width: 100%;
  height: 100%; }
  .grid-table.grid-bg-overlay {
    background: rgba(50, 50, 50, 0.5);
    transition: all 0.3s ease; }
    .grid-table.grid-bg-overlay:hover {
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease; }

.grid-table-cell {
  display: table-cell;
  padding: 5%;
  vertical-align: middle; }

.grid-flag-square {
  float: left;
  position: relative;
  margin-top: 0;
  margin-left: .3%;
  margin-right: .3%;
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%; }
  @media (min-width: 380px) {
    .grid-flag-square {
      margin-top: 2rem;
      width: 49.4%;
      padding-bottom: 49.4%; } }
  @media (min-width: 500px) {
    .grid-flag-square {
      width: 32.7%;
      padding-bottom: 32.7%; } }
  @media (min-width: 650px) {
    .grid-flag-square {
      width: 24.4%;
      padding-bottom: 24.4%; } }
  @media (min-width: 1000px) {
    .grid-flag-square {
      width: 19.4%;
      padding-bottom: 19.4%; } }
  @media (min-width: 1200px) {
    .grid-flag-square {
      width: 13.6%;
      padding-bottom: 13.6%; } }

.grid-flag-bg-overlay {
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s ease; }
  .grid-flag-bg-overlay:hover {
    background: rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease; }

.grid-flag-bg {
  position: absolute;
  margin: 0;
  border: 1px solid #aaa;
  overflow: hidden;
  width: 100%;
  padding-bottom: calc(100% * .6);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #aaa; }

.grid-flag-content {
  display: block;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #aaa;
  padding: .7rem 0;
  height: 40%;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  color: #333;
  text-align: center;
  font-weight: 200;
  font-size: 2rem;
  line-height: 2rem; }
  .grid-flag-content.grid-flag-content-sm {
    font-size: 1.5rem;
    line-height: 1.7rem; }

.progression-bar {
  display: inline-block;
  margin: 20px auto;
  width: 430px;
  text-align: center;
  cursor: pointer; }

.progression-bar .bar,
.progression-bar .circle {
  display: inline-block;
  border: 1px solid #d5d5da;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  background: #ffffff; }
  .progression-bar .bar.done,
  .progression-bar .circle.done {
    background: #eeeeee; }

.progression-bar .bar {
  position: relative;
  top: -32px;
  margin-left: -5px;
  margin-right: -5px;
  border-left: none;
  border-right: none;
  border-radius: 0;
  width: 80px;
  height: 6px; }

.progression-bar .circle .label-progression {
  display: inline-block;
  margin-top: 3px;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  font-size: 17px;
  line-height: 28px; }
  .progression-bar .circle .label-progression img {
    opacity: 0.3;
    width: 20px;
    height: 20px; }

.progression-bar .circle .title {
  color: #b5b5ba;
  font-size: 13px;
  line-height: 30px; }
  .progression-bar .circle .title.emerging {
    margin-left: -8px; }
  .progression-bar .circle .title.tested {
    margin-left: -1px; }
  .progression-bar .circle .title.adopted {
    margin-left: -5px; }
  .progression-bar .circle .title.established {
    margin-left: -13px; }

.progression-bar .circle.done .label-progression {
  background: #8bc435; }
  .progression-bar .circle.done .label-progression img {
    opacity: 1; }

.progression-bar .circle.done .title {
  color: #444444; }

.prog-modal img {
  width: 20px;
  height: 20px;
  vertical-align: bottom; }

@media print {
  .progression-bar .bar,
  .progression-bar .circle {
    background: #ffffff !important; }
    .progression-bar .bar.done,
    .progression-bar .circle.done {
      background: #eeeeee !important; }
  .progression-bar .circle .title {
    color: #b5b5ba !important; }
  .progression-bar .circle.done .label-progression {
    background: #8bc435 !important; }
  .progression-bar .circle.done .title {
    color: #000000 !important; } }

form.cstm-form-inline-headline {
  margin-top: 15px;
  margin-bottom: 25px;
  border: 0; }

tags-input .tags {
  border-color: #999;
  border-width: 1px;
  box-shadow: none; }
  tags-input .tags .tag-item {
    border: 1px solid #35aad8;
    background: #60BCE0;
    color: white; }

tags-input.tags-disabled .tags {
  box-shadow: none;
  background: #eee !important; }
  tags-input.tags-disabled .tags .input {
    background: #eee !important; }

tags-input.ng-invalid .tags {
  box-shadow: none;
  background: #fff0f0; }
  tags-input.ng-invalid .tags .input {
    background: #fff0f0; }

div.cstm-uib-collapse-fieldset {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

a.alert-link-action {
  display: block;
  margin-left: 1rem; }
  @media (min-width: 768px) {
    a.alert-link-action {
      margin-left: 4rem; } }

div.cstm-load-more-container {
  position: relative;
  margin-top: 32.5px;
  border-top: 1px solid #ccc;
  text-align: center; }
  div.cstm-load-more-container .cstm-load-more-block {
    display: inline-block;
    position: relative;
    top: -32.5px;
    margin-bottom: -32.5px;
    padding: 10px;
    border: 0;
    background: #fff; }

img.country-flag-header {
  padding-right: 1rem;
  max-height: 50px; }

.position-relative {
  position: relative; }

.display-block {
  display: block; }

i.search-item-icon {
  position: absolute;
  left: 0;
  margin-left: -2.5rem;
  margin-top: 0.4rem; }

.cstm-header-hr {
  margin: 1rem 0;
  overflow: hidden;
  text-align: left; }
  .cstm-header-hr h3 {
    padding: 0 12px 0 0;
    position: relative;
    display: inline-block;
    line-height: 34px !important; }
  .cstm-header-hr h3::after {
    content: ' ';
    position: absolute;
    top: 17px;
    left: 100%;
    display: block;
    width: 100%;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #bbb; }

/* -------------------------------------------------------------------------- */
.search-item-prodtech {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: 15px;
  border: 1px solid #eee;
  padding: 1rem;
  min-height: 9rem;
  box-shadow: 0 13px 9px -9px #ccc; }
  .search-item-prodtech h4 {
    margin-top: 0;
    margin-bottom: 0; }
  .search-item-prodtech p {
    margin-top: 0.7rem;
    margin-bottom: 0;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #777; }

.search-item-prodtech-img {
  position: absolute;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  width: 7rem;
  height: 7rem; }

.search-item-prodtech-content {
  display: block;
  margin-left: 8rem; }

@media (max-width: 768px) {
  .search-item-prodtech {
    min-height: 0; }
  .search-item-prodtech-img {
    display: none; }
  .search-item-prodtech-content {
    margin-left: 0; } }

a.disabled {
  color: #aaa;
  pointer-events: none; }

.cstm-color-done {
  color: #54B469; }

.cstm-btn-multiline {
  white-space: normal; }

.cstm-margin-left-25 {
  margin-left: 25px !important; }

.padding-bottom-0 {
  padding-bottom: 0 !important; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-60 {
  margin-top: 60px; }

.margin-top-80 {
  margin-top: 80px; }

.padding-top-80 {
  padding-top: 80px; }

.header-v6 .navbar-nav > li > a,
.header-v6 .navbar-nav > li > a:hover,
.header-v6 .navbar-nav > li > a:focus,
.header-v6 .navbar-nav > .active > a,
.header-v6 .navbar-nav > .active > a:hover,
.header-v6 .navbar-nav > .active > a:focus {
  font-weight: 400; }

@media (min-width: 992px) {
  .header-v6.header-fixed-shrink .navbar-nav .hwts-nav__prodtech a {
    line-height: 18px !important;
    padding: 17px 10px; } }

@media (min-width: 1200px) {
  .header-v6.header-fixed-shrink .navbar-nav .hwts-nav__prodtech a {
    line-height: 36px !important; } }

@media (min-width: 992px) {
  .header-v6 .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; } }

.header-v6 .navbar-nav li:hover {
  background-color: rgba(153, 153, 153, 0.29); }

.header-v6 .navbar-nav li:hover.no-hover {
  background: none; }

@media (min-width: 992px) {
  .header-v6 .navbar-nav li.hwts-nav__prodtech a {
    max-width: 145px;
    line-height: 18px;
    text-align: center;
    padding: 29px 10px; } }

@media (min-width: 1200px) {
  .header-v6 .navbar-nav li.hwts-nav__prodtech a {
    max-width: none;
    line-height: 54px;
    padding: 20px 10px; } }

.header-v6 .navbar-nav li ul li:hover {
  background-color: rgba(153, 153, 153, 0.29); }

.header-v6 .navbar-nav li ul li:hover.no-hover {
  background: none; }

@media (min-width: 992px) {
  .header-v6 .navbar-nav > li > a {
    font-size: 12px;
    /* 12/12/22 - New nav button added, this shrinks buttons to fit width */
    padding-right: 10px;
    padding-left: 10px; } }

@media (min-width: 992px) {
  .navbar--tinted {
    background-color: rgba(0, 0, 0, 0.25); } }

/* ================================================================= */
/* ================================================================= */
.menu-container > ul.navbar-nav-left {
  align-items: flex-start;
  display: flex;
  justify-content: space-evenly;
  max-width: 85%;
  min-width: 800px;
  text-align: center; }
  .menu-container > ul.navbar-nav-left li:nth-of-type(n+2) {
    padding-left: 15px; }
  .menu-container > ul.navbar-nav-left li a {
    line-height: unset !important;
    padding-left: inherit !important;
    padding-right: inherit !important; }

.header-fixed-shrink .menu-container > ul:last-of-type > li > a {
  line-height: 100%; }
  @media (max-width: 768px) {
    .header-fixed-shrink .menu-container > ul:last-of-type > li > a {
      padding: 15px 10px !important; } }

.u-flex {
  display: flex !important; }

.u-flex-align-bottom {
  align-items: baseline !important; }

.u-margin-right-small {
  margin-right: .8rem !important; }

.breadcrumbs-v3 {
  background-size: cover; }
  .breadcrumbs-v3.img-home {
    padding-top: 150px;
    background-repeat: no-repeat;
    background-image: url(/d79c19636afd6350f5cc01296ac5d4ff.jpg); }
    .breadcrumbs-v3.img-home h1,
    .breadcrumbs-v3.img-home h2,
    .breadcrumbs-v3.img-home h3,
    .breadcrumbs-v3.img-home h4 {
      color: #fff; }
      .breadcrumbs-v3.img-home h1 small,
      .breadcrumbs-v3.img-home h2 small,
      .breadcrumbs-v3.img-home h3 small,
      .breadcrumbs-v3.img-home h4 small {
        color: #fff; }
    .breadcrumbs-v3.img-home .container {
      padding-right: 30%; }
    @media (min-width: 1500px) {
      .breadcrumbs-v3.img-home {
        padding-top: 200px !important;
        padding-bottom: 150px; }
        .breadcrumbs-v3.img-home h2 {
          font-size: 3.5rem; }
        .breadcrumbs-v3.img-home h4 {
          font-size: 2.5rem; } }
  .breadcrumbs-v3.img-promo {
    background-image: url("https://s3-us-west-2.amazonaws.com/cawst-hwts-dev/files/promotional/tandas_header.jpg"); }

.content-boxes-v7 i.fa {
  margin-right: 20px;
  margin-left: 10px; }

.cstm-front-page-search {
  border-bottom: 1px solid #eee;
  padding: 1.5rem 0;
  background-color: #fcfcfc; }

.cstm-section-header {
  position: relative;
  margin-top: 3rem;
  border-top: 0.2rem solid #aaa;
  padding: 1rem 2rem; }

.cstm-content-section {
  position: relative;
  margin-top: 3rem;
  border-top: 0.2rem solid #aaa;
  padding: 1rem 2rem; }
  .cstm-content-section h4 {
    margin-bottom: 0; }

.cstm-content-section-subtitle {
  margin-top: -5px;
  color: #777;
  font-size: 1.4rem; }

p.cstm-content-section-resultscount {
  display: block;
  margin-top: -10px;
  margin-bottom: -10px;
  color: #999;
  font-size: 1.5rem;
  font-weight: 200; }

a.cstm-sub-header-url {
  display: flex;
  align-items: baseline;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  color: #777;
  line-height: 1.5rem;
  font-size: 1.4rem; }
  a.cstm-sub-header-url i {
    margin-right: .4rem; }
  a.cstm-sub-header-url:hover, a.cstm-sub-header-url:focus, a.cstm-sub-header-url:active {
    color: #60BCE0; }
    a.cstm-sub-header-url:hover i, a.cstm-sub-header-url:focus i, a.cstm-sub-header-url:active i {
      color: #777;
      text-decoration: none; }

a.cstm-tag-label {
  margin-right: 1rem; }

.cstm-side-nav-affix {
  position: fixed;
  top: 150px; }

.cstm-certifications-text {
  margin-top: 20px; }
  .cstm-certifications-text p, .cstm-certifications-text span {
    color: #333;
    font-size: 1.2rem; }

.cstm-width-sensitive-link {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.emergency-icon {
  display: inline-block;
  margin-right: .5rem;
  background: url("https://s3-us-west-2.amazonaws.com/cawst-hwts-prod/images/icon_emergency_context@50px.png") no-repeat center bottom;
  background-image: url("https://s3-us-west-2.amazonaws.com/cawst-hwts-prod/images/icon_emergency_context.svg");
  background-size: contain;
  width: 2.2rem;
  height: 2.2rem; }
  .emergency-icon.fa {
    width: 4.2rem;
    height: 4.2rem; }
  .emergency-icon.small {
    margin: 0;
    width: 1.4rem;
    height: 1.4rem; }

.emergency-label {
  color: #aaa;
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase; }
  .emergency-label small {
    display: block;
    margin-left: 3rem;
    color: #333;
    font-weight: 400;
    text-transform: none; }

.emergency-label-header::after {
  content: "";
  display: block;
  margin: 1rem 0 1.7rem;
  border: 2px solid #dddddd;
  width: 4rem; }

.emergency-label-right {
  float: none;
  text-align: left; }

@media (min-width: 768px) {
  .emergency-label-right {
    float: right;
    text-align: right; }
  .emergency-label-header.emergency-label-right::after {
    float: right; } }

.quote-v1.cstm-quote-experience {
  color: #555555;
  text-align: left; }
  .quote-v1.cstm-quote-experience p {
    padding-right: 50px;
    padding-left: 50px;
    color: #555555; }
  .quote-v1.cstm-quote-experience p:before {
    color: #BDBABB;
    font-size: 80px; }
  .quote-v1.cstm-quote-experience p small {
    display: block;
    margin-top: 15px;
    font-size: 70%; }
  .quote-v1.cstm-quote-experience span {
    display: block;
    padding-right: 50px;
    padding-left: 50px;
    color: #555555;
    font-style: italic;
    text-align: center; }
  .quote-v1.cstm-quote-experience span:before {
    content: '-- '; }

.experience-heading {
  margin-bottom: 4rem;
  margin-left: 5rem; }
  .experience-heading::before {
    content: '\201C';
    position: absolute;
    margin: 3rem 0 0 -6rem;
    color: #BDBABB;
    font-family: Georgia, serif;
    font-size: 12rem; }

.experience-summary {
  margin-bottom: 4rem; }
  .experience-summary span {
    display: block;
    margin-top: 1.2rem;
    padding-left: 5rem;
    font-size: 1.7rem;
    font-weight: 600;
    font-style: italic; }
    .experience-summary span::before {
      content: '-- '; }

.loading-bar {
  color: #aaa;
  font-size: 1rem; }

.loading-bar-lg {
  font-size: 24px; }

.loading-bar-md {
  font-size: 16px; }

.loading-bar-sm {
  font-size: 10px; }

.angular-google-map-container {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.cstm-google-map {
  position: relative;
  overflow: hidden;
  height: 0;
  margin-bottom: 0.3%;
  padding-bottom: 75%; }
  @media (max-width: 1200px) {
    .cstm-google-map {
      padding-bottom: 50%; } }
  @media (max-width: 768px) {
    .cstm-google-map {
      padding-bottom: 75%; } }

.cstm-google-map-wide {
  position: relative;
  overflow: hidden;
  height: 0;
  margin-bottom: 0.3%;
  padding-bottom: 30%; }
  @media (max-width: 768px) {
    .cstm-google-map-wide {
      padding-bottom: 75%; } }

.cstm-google-map-full {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 93px); }
  @media (min-width: 1024px) {
    .cstm-google-map-full {
      width: 75%;
      height: calc(100% - 150px);
      margin-left: 25%; } }

.cstm-search-projects-map {
  position: absolute;
  display: none;
  width: 0;
  padding: 2rem; }
  .cstm-search-projects-map input {
    width: 100%;
    margin-bottom: 2rem; }
  @media (min-width: 1024px) {
    .cstm-search-projects-map {
      display: inline-block;
      overflow: scroll;
      width: 25%;
      height: calc(100% - 150px); } }

.cstm-search-projects-results {
  margin-bottom: 2rem; }
  .cstm-search-projects-results h3 {
    margin-bottom: 0;
    line-height: 27px;
    font-size: 1.7rem; }
  .cstm-search-projects-results p {
    margin-bottom: 0;
    font-size: 1.2rem; }

.cstm-search-projects-results > ul {
  padding-left: 0;
  list-style-type: none; }
  .cstm-search-projects-results > ul > li {
    display: inline;
    color: #aaa;
    font-size: 1.2rem; }
  .cstm-search-projects-results > ul > li::after {
    margin-left: -2px; }
  .cstm-search-projects-results > ul > li:last-child::after {
    content: ''; }

ul.cstm-tag-list {
  display: inline;
  padding-left: 0;
  list-style-type: none; }
  ul.cstm-tag-list::before {
    content: '\f02c';
    color: #aaa;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  ul.cstm-tag-list li {
    display: inline;
    color: inherit;
    font-size: inherit; }
  ul.cstm-tag-list li::after {
    content: ', '; }
  ul.cstm-tag-list li:last-child::after {
    content: ''; }

.mba-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; }
  .mba-container .mba-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid #cccccc;
    padding: 1.5rem 1rem;
    background: #ffffff; }
    .mba-container .mba-box .mba-content {
      color: #666666;
      font-size: 1.4rem;
      font-weight: 200;
      text-align: center; }

/* Active Class */
.mba-container .mba-box.mba-box-active {
  background: #eeeeee; }
  .mba-container .mba-box.mba-box-active .mba-content {
    color: #333333;
    font-weight: 600; }

/* Responsive Styles */
@media (min-width: 400px) {
  .mba-container .mba-box {
    width: 49%;
    margin-bottom: 2%; } }

@media (min-width: 600px) {
  .mba-container .mba-box {
    width: 31.3333333333%; } }

@media (min-width: 700px) {
  .mba-container .mba-box {
    width: 15.6666666666%;
    margin-bottom: 0; } }

/* Print Styles */
@media print {
  .mba-container .mba-box {
    width: 15.6666666666%;
    margin-bottom: 0; }
  .mba-container .mba-box.mba-box-active {
    border: 2px solid #cccccc;
    background: #eeeeee !important; } }

.prodtech-info-content h1 {
  margin-bottom: 30px; }

.prodtech-info-content h2 {
  margin-top: 4rem;
  font-size: 1.8rem; }

.prodtech-info-content h3 {
  margin-top: 2.5rem;
  margin-bottom: 0.1rem;
  font-size: 1.7rem;
  font-weight: 200; }

.detail-param-section p em,
.detail-param-section p span {
  display: block; }

img.main-image-techinfo {
  float: left;
  margin-right: 3rem;
  margin-bottom: 2rem;
  max-width: 20rem; }

.cstm-has-tooltip {
  color: #60BCE0; }

.prodtech-btn-factsheet {
  float: none;
  display: block;
  margin-bottom: 60px; }

@media (min-width: 768px) {
  .prodtech-btn-factsheet {
    float: right;
    display: block; } }

.effectiveness-table {
  margin-bottom: 4rem;
  border: 1px solid #ddd; }
  .effectiveness-table td,
  .effectiveness-table th {
    vertical-align: middle; }
  .effectiveness-table tfoot {
    font-size: 1.1rem; }
    .effectiveness-table tfoot ol,
    .effectiveness-table tfoot p,
    .effectiveness-table tfoot ul {
      margin-bottom: 0; }
    .effectiveness-table tfoot p.lead {
      margin-bottom: 2rem;
      font-size: 1.3rem;
      font-weight: 400;
      font-style: italic; }
      .effectiveness-table tfoot p.lead::before {
        content: '* '; }

.table > tbody > tr > td.very-effective,
.table > tbody > tr > th.very-effective,
.table > tbody > tr.very-effective > td,
.table > tbody > tr.very-effective > th,
.table > tfoot > tr > td.very-effective,
.table > tfoot > tr > th.very-effective,
.table > tfoot > tr.very-effective > td,
.table > tfoot > tr.very-effective > th,
.table > thead > tr > td.very-effective,
.table > thead > tr > th.very-effective,
.table > thead > tr.very-effective > td,
.table > thead > tr.very-effective > th {
  color: #419453; }

.table > tbody > tr > td.effective,
.table > tbody > tr > th.effective,
.table > tbody > tr.effective > td,
.table > tbody > tr.effective > th,
.table > tfoot > tr > td.effective,
.table > tfoot > tr > th.effective,
.table > tfoot > tr.effective > td,
.table > tfoot > tr.effective > th,
.table > thead > tr > td.effective,
.table > thead > tr > th.effective,
.table > thead > tr.effective > td,
.table > thead > tr.effective > th {
  color: #f3c100; }

.table > tbody > tr > td.somewhat-effective,
.table > tbody > tr > th.somewhat-effective,
.table > tbody > tr.somewhat-effective > td,
.table > tbody > tr.somewhat-effective > th,
.table > tfoot > tr > td.somewhat-effective,
.table > tfoot > tr > th.somewhat-effective,
.table > tfoot > tr.somewhat-effective > td,
.table > tfoot > tr.somewhat-effective > th,
.table > thead > tr > td.somewhat-effective,
.table > thead > tr > th.somewhat-effective,
.table > thead > tr.somewhat-effective > td,
.table > thead > tr.somewhat-effective > th {
  color: #f1710a; }

.table > tbody > tr > td.not-effective,
.table > tbody > tr > th.not-effective,
.table > tbody > tr.not-effective > td,
.table > tbody > tr.not-effective > th,
.table > tfoot > tr > td.not-effective,
.table > tfoot > tr > th.not-effective,
.table > tfoot > tr.not-effective > td,
.table > tfoot > tr.not-effective > th,
.table > thead > tr > td.not-effective,
.table > thead > tr > th.not-effective,
.table > thead > tr.not-effective > td,
.table > thead > tr.not-effective > th {
  color: #d8370f; }

@media print {
  img.main-image-techinfo {
    width: 200px; }
  .table > tbody > tr > td.very-effective,
  .table > tbody > tr > th.very-effective,
  .table > tbody > tr.very-effective > td,
  .table > tbody > tr.very-effective > th,
  .table > tfoot > tr > td.very-effective,
  .table > tfoot > tr > th.very-effective,
  .table > tfoot > tr.very-effective > td,
  .table > tfoot > tr.very-effective > th,
  .table > thead > tr > td.very-effective,
  .table > thead > tr > th.very-effective,
  .table > thead > tr.very-effective > td,
  .table > thead > tr.very-effective > th {
    color: #31713f !important;
    font-weight: 600; }
  .table > tbody > tr > td.effective,
  .table > tbody > tr > th.effective,
  .table > tbody > tr.effective > td,
  .table > tbody > tr.effective > th,
  .table > tfoot > tr > td.effective,
  .table > tfoot > tr > th.effective,
  .table > tfoot > tr.effective > td,
  .table > tfoot > tr.effective > th,
  .table > thead > tr > td.effective,
  .table > thead > tr > th.effective,
  .table > thead > tr.effective > td,
  .table > thead > tr.effective > th {
    color: #fdc900 !important;
    font-weight: 600; }
  .table > tbody > tr > td.somewhat-effective,
  .table > tbody > tr > th.somewhat-effective,
  .table > tbody > tr.somewhat-effective > td,
  .table > tbody > tr.somewhat-effective > th,
  .table > tfoot > tr > td.somewhat-effective,
  .table > tfoot > tr > th.somewhat-effective,
  .table > tfoot > tr.somewhat-effective > td,
  .table > tfoot > tr.somewhat-effective > th,
  .table > thead > tr > td.somewhat-effective,
  .table > thead > tr > th.somewhat-effective,
  .table > thead > tr.somewhat-effective > td,
  .table > thead > tr.somewhat-effective > th {
    color: #c05a08 !important;
    font-weight: 600; }
  .table > tbody > tr > td.not-effective,
  .table > tbody > tr > th.not-effective,
  .table > tbody > tr.not-effective > td,
  .table > tbody > tr.not-effective > th,
  .table > tfoot > tr > td.not-effective,
  .table > tfoot > tr > th.not-effective,
  .table > tfoot > tr.not-effective > td,
  .table > tfoot > tr.not-effective > th,
  .table > thead > tr > td.not-effective,
  .table > thead > tr > th.not-effective,
  .table > thead > tr.not-effective > td,
  .table > thead > tr.not-effective > th {
    color: #a82b0c !important;
    font-weight: 600; } }

.position-relative.cstm-width {
  max-width: 75%; }

.learn-tabset {
  margin-top: 4rem; }
  .learn-tabset a {
    color: #60bce0; }
  .learn-tabset ul > li {
    vertical-align: bottom; }
  .learn-tabset .tab-content {
    padding: 3rem;
    border: 1px solid #ddd;
    background: #fff; }
  @media (min-width: 768px) {
    .learn-tabset .tab-content {
      border-top: 1px solid #fff; } }

h3 {
  font-weight: bold; }

.network li > a {
  color: #38C6F4; }

.network address .fa {
  min-width: 20px;
  margin-right: 5px;
  text-align: center; }

.network .iframe-container {
  overflow: hidden;
  padding-top: 80%;
  position: relative; }
  .network .iframe-container iframe {
    border: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%; }

.network .hero--align-top {
  background-position: top; }

.network .list-unstyled li {
  font-size: 14px; }

.network #about-page h3 {
  margin-top: 25px; }
  .network #about-page h3:nth-of-type(1) {
    margin-top: 0; }

.header-v6.header-fixed-shrink .collapse.navbar-collapse.navbar-responsive-collapse {
  padding-top: 5px; }

.header-v6.header-fixed-shrink .navbar-nav > li#language-selector > a {
  padding: 25px 10px; }
  @media (max-width: 768px) {
    .header-v6.header-fixed-shrink .navbar-nav > li#language-selector > a {
      padding: 10px 5px; } }

.header-v6 .collapse.navbar-collapse.navbar-responsive-collapse {
  padding-top: 14px; }

.header-v6 .navbar-nav > li#language-selector > a {
  line-height: inherit;
  padding: 36px 10px; }
  @media (max-width: 768px) {
    .header-v6 .navbar-nav > li#language-selector > a {
      padding: 10px 5px; } }
