a[data-v-94a91ae0]:hover {
  cursor: pointer;
}
.lesson-view-pagination[data-v-94a91ae0] {
  font-size: 2rem;
  text-align: center;
}
.lesson-view-pagination__link[data-v-94a91ae0] {
    color: #555;
}
.lesson-view-pagination__link--disabled[data-v-94a91ae0] {
      color: #d5d5d5;
}
.lesson-view-pagination__link--disabled[data-v-94a91ae0]:hover {
        text-decoration: none;
        cursor: not-allowed;
}
