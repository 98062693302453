.learn-home-heading {
  background-color: #005478;
  padding-top: 47px;
  padding-bottom: 47px;
}
@media (min-width: 768px) {
.learn-home-heading {
      padding-top: 94px;
      padding-bottom: 94px;
}
}
.learn-home-heading__subtitle {
    font-size: 22px;
    font-weight: 200;
}
.learn-home-heading__title, .learn-home-heading__subtitle, .learn-home-heading__tagline {
    color: white;
}
.learn-home-heading__title {
    margin-bottom: 20px;
}
.learn-home-heading__tagline {
    margin-bottom: 0;
}
@media (min-width: 992px) {
.learn-home-heading__spacer {
      height: 47px;
}
}
