.pt-sidebar-select__title {
  padding-top: 6px;
  border-bottom: 1px solid #aaa;
}
.pt-sidebar-select__link {
  font-weight: normal;
}
.pt-sidebar-select__link.active {
    font-weight: bold;
}
.pt-sidebar-select__list {
  list-style: none;
  padding-left: 0;
}
