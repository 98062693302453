a:hover {
  cursor: pointer;
}
.no-lessons {
  padding: 60px 0;
}
.no-lessons__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}
@media (min-width: 650px) {
.no-lessons__body {
        padding: 0;
}
}
.no-lessons__title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
}
.no-lessons__message, .no-lessons__link {
    font-size: 2rem;
    text-align: center;
}
