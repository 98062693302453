.cawst-hero-banner {
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.cawst-hero-banner__body {
    z-index: 0;
}
.hero-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
