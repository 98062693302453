@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact; } }

@media print {
  .header-v6,
  .cstm-breadcrumbs,
  .footer,
  .print-page-hide {
    display: none;
    height: 0; }
  body {
    overflow: visible;
    margin: 0;
    padding: 0;
    background: #ffffff;
    counter-reset: pageNumber; }
  .content-md {
    padding: 0; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    -moz-column-break-after: avoid;
         break-after: avoid-page;
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
         break-inside: avoid; }
  img,
  figure,
  .print-avoid-break-inside {
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
         break-inside: avoid; }
  img {
    max-width: 100%; }
  p.lead {
    font-size: 14pt; }
  a::after {
    content: " (" attr(href) ") ";
    font-size: 8pt;
    font-weight: normal; }
  a.print-local-link::after {
    content: " (https://www.hwts.info" attr(href) ") "; }
  @page {
    margin: 2cm 2.5cm 3.1cm;
    size: 8.5in 11in; }
  .print-page-counter::after {
    content: "Page | " counter(pageNumber);
    height: 0;
    counter-increment: pageNumber; } }

table.print-container {
  width: 100%;
  border: 0;
  border-collapse: collapse;
  padding: 0;
  background-color: #ffffff; }
  table.print-container tr,
  table.print-container th,
  table.print-container td {
    border: 0;
    padding: 0; }

thead.print-container-header,
tfoot.print-container-footer {
  display: none; }

.print-logo {
  display: none; }

.print-image {
  display: none; }

.print-tooltip {
  display: none; }

@media print {
  thead.print-container-header {
    display: table-header-group; }
  tfoot.print-container-footer {
    display: table-footer-group; }
  thead.print-container-header th {
    padding-bottom: .7cm;
    font-size: 12pt;
    font-weight: 400;
    text-align: right; }
    thead.print-container-header th span {
      display: block;
      margin-top: -5pt;
      font-size: 8pt;
      font-weight: 200; }
  tfoot.print-container-footer td {
    border-top: 1px solid #EDE7E4;
    padding-top: .5cm;
    vertical-align: bottom; }
  tbody.print-container-content {
    padding-bottom: .7cm; }
  .print-logo {
    display: block;
    width: 80px; }
  .print-image {
    display: block; }
  a.print-ref-link::after {
    display: block;
    margin-top: -3px;
    margin-bottom: 10px;
    margin-left: 15px; }
  .print-tooltip {
    display: inline-block;
    font-size: 8pt; }
  .effectiveness-table {
    width: 845px;
    table-layout: fixed; }
  .effectiveness-table thead th:nth-of-type(1) {
    width: 120px; }
  .effectiveness-table thead th:nth-of-type(2) {
    width: 190px; }
  .effectiveness-table thead th:nth-of-type(3),
  .effectiveness-table thead th:nth-of-type(4) {
    width: calc((845-120-190)/2); } }
