a[data-v-19dec5fc]:hover {
  cursor: pointer;
}
a[data-v-19dec5fc] {
  color: white !important;
}
.lesson-list-item[data-v-19dec5fc] {
  width: 100%;
  min-height: 30rem;
  background-color: gray;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
@media (min-width: 450px) {
.lesson-list-item[data-v-19dec5fc] {
      min-height: 24rem;
}
}
.lesson-list-item__link[data-v-19dec5fc] {
    font-size: 2rem;
}
.lesson-list-item__locales[data-v-19dec5fc] {
    margin-bottom: auto;
    text-align: right;
}
