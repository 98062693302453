a[data-v-66f11a62]:hover {
  cursor: pointer;
}
.lessons__grid[data-v-66f11a62] {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
}
.lessons__grid-item[data-v-66f11a62] {
  grid-column: span 12;
}
@media (min-width: 650px) {
.lessons__grid-item[data-v-66f11a62] {
      grid-column: span 4;
}
}
@media (min-width: 1200px) {
.lessons__grid-item[data-v-66f11a62] {
      grid-column: span 3;
}
}
