a[data-v-633b49f3]:hover {
  cursor: pointer;
}
.temp-content[data-v-633b49f3] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.temp-content__body[data-v-633b49f3] {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.temp-content__body--main[data-v-633b49f3] {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 30px;
}
.temp-content__body--sub[data-v-633b49f3] {
      font-size: 2rem;
}
.lesson-iframe[data-v-633b49f3] {
  min-width: 100%;
}
.temp-content[data-v-633b49f3],
.video-iframe[data-v-633b49f3] {
  width: 100%;
  height: 250px;
}
@media (min-width: 650px) {
.temp-content[data-v-633b49f3],
    .video-iframe[data-v-633b49f3] {
      height: 600px;
}
}
