.prodtech-image {
  background-color: white;
  position: relative;
}
.prodtech-image__overlay {
    background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: background-color 0.3s ease;
}
.prodtech-image__overlay:hover {
      background-color: #33333318;
}
