a[data-v-213b0022]:hover {
  cursor: pointer;
}
.banner__body[data-v-213b0022] {
  padding: 20px 0;
}
.banner__body--label[data-v-213b0022] {
    color: white;
}
.banner__body--link[data-v-213b0022] {
    color: #a0d7ec;
}
.banner__body--link[data-v-213b0022], .banner__body--label[data-v-213b0022] {
    font-size: 2rem;
}
