.prodtech-results-list-item-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.prodtech-results-list-item-actions--details {
    margin-bottom: 20px;
}
@media (min-width: 650px) {
.prodtech-results-list-item-actions {
      flex-direction: row;
}
.prodtech-results-list-item-actions--details {
        margin-bottom: 0;
        margin-right: 20px;
        flex: 1;
}
.prodtech-results-list-item-actions--technical {
        flex: 1;
}
}
