a[data-v-12e0987b]:hover {
  cursor: pointer;
}
.learn-lesson-view__message[data-v-12e0987b] {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #005478;
  margin-bottom: 40px;
  color: white;
}
.learn-lesson-view__message--title[data-v-12e0987b] {
    font-size: 3rem;
    margin-bottom: 30px;
}
.learn-lesson-view__message--link[data-v-12e0987b] {
    font-size: 2rem;
    color: white;
}
@media (min-width: 992px) {
.learn-lesson-view__spacer[data-v-12e0987b] {
    background-color: #005478;
    height: 94px;
}
}
